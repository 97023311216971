import { apiPost, apiGet } from "../utils";
import { apiKey } from "../constants";


export function loginAPI(data) {

  let newdata = JSON.stringify(data);
  return apiPost(`/api/login/check?appKey=${apiKey}`, newdata);
}

export function signupAPI(data) {
  let newdata = JSON.stringify(data);
  return apiPost(`/api/register?appKey=${apiKey}`, newdata);
}

export function checkEmailAddressExistAPI(data) {
  let newdata = JSON.stringify(data);
  return apiPost(`/api/login/checkemail?appKey=${apiKey}`, newdata);
}

export function forgotpasswordAPI(data) {
  let newData = JSON.stringify(data);
  return apiPost(`/api/forgotpassword?appKey=${apiKey}`, newData);
}

export function emailVerifyAPI(payload) {
  return apiGet(`/api/verify?code=${payload.code}&k=${payload.k}`);
}

export function resetPasswordAPI(payload) {
  return apiPost(`/api/resetpassword?m=${payload.email}==&k=${payload.k}`, {
    password: payload.password,
    email: payload.email
  });
}

export function socialLoginAPI(payload) {
  let data = JSON.stringify(payload)
  return apiPost(`/api/social?appKey=${apiKey}`, data)
}
