import types from "../types";

const initialState = {
  fetching: false,
  bestInsuranceData: {},
  fetchingBooking: false,
  fetchingPolicyGenerate: false,
  generatePolicy: {},
  responseStatus:false

};

export default (state = initialState, action) => {
  switch (action.type) {
    //types used to fetch best insurance api 
    case types.GET_BEST_INSURANCE:
      return { ...state, fetching: true };
    case types.GET_BEST_INSURANCE_SUCCESS:
      return { ...state, fetching: false, bestInsuranceData: { ...action.payload.info } , responseStatus:true };
    case types.GET_BEST_INSURANCE_FAILED:
      return { ...state, fetching: false, bestInsuranceData: {} , responseStatus:false };

    default:
      return state;
  }
};
