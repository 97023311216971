import { apiPost, apiGet } from "../utils";
import { apiKey } from "../constants";

export function getStaticDataInfo() {
  return apiGet(`/api/alldata?appKey=${apiKey}`);
}

export function subscribeEmailAPI(data) {
  let updatedData = JSON.stringify({ EMAIL: data.email });
  return apiPost(`/api/subscribe?appKey=${apiKey}`, updatedData);
}


export function getCardPercentageAPI() {
  return apiGet(`/api/gateways?appKey=${apiKey}`);
}

export function unSubscribeEmailAPI(data) {
  let updatedData = JSON.stringify(data);
  return apiPost(`/api/unsubscribe?appKey=${apiKey}`, updatedData);
}

export function getCountriesListAPI(payload) {
  const updatedData = JSON.stringify({
    ...payload,
  })
  return apiPost(`/api/countryList?appKey=${apiKey}`, updatedData)
}