import React from "react";
import { withRouter } from 'react-router-dom';

import {
    Button
} from 'react-bootstrap';

class NoFlightFound extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="no_flights box-shadow-add p-5 text-center">

                    <img src={require("../../assets/no-flight.png")} alt="not found" />

                    <h2 className="text-color">Oooops!</h2>

                    <p>No Flights Available</p>

                    <Button onClick={() => this.props.history.push("/")} className="btn-green borderadius round-border"> GO BACK </Button>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(NoFlightFound);