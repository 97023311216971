import types from "../types";
import { getFlightResultsAPI, getFlightFareRuleAPI } from "../api/getFlightsResults";

export const getFlightsResult = payload => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.GET_FLIGHT_SEARCH_RESULT
    });
    getFlightResultsAPI(payload)
      .then(res => {
        dispatch({
          type: types.GET_FLIGHT_SEARCH_RESULT_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.GET_FLIGHT_SEARCH_RESULT_FAILED
        });
        return reject(err);
      });
  });

export const autofillSearchParameters = data => dispatch => {
  dispatch({
    type: types.GET_SEARCH_OBJECT,
    payload: data
  });
};


export const getFlightFareRule = payload => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.GET_FLIGHT_FARE_RULE
    });
    getFlightFareRuleAPI(payload)
      .then(res => {
        dispatch({
          type: types.GET_FLIGHT_FARE_RULE_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.GET_FLIGHT_FARE_RULE_FAILED
        });
        return reject(err);
      });
  });

export const setCompleteState = payload => {  
  return ({
    type: types.SET_COMPLETE_STATE,
    payload
  });
};