//File Name: Featuredhotels.js
//Path: src/app/coomponents/homepage
//Description: This file is used for FeaturedHotels searches.

import React, { Component } from "react";
import Slider from "react-slick";
import { Card } from "react-bootstrap";
import ReactStars from "react-stars";
import { ContentLoaderCards } from "../";
import { currencySign } from "../../utils";
import { Link } from "react-router-dom";

class FeaturedHotels extends Component {
  state = {};
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      cssEase: "linear",
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    const { featuredhotels, fetching } = this.props;

    return (
      <div className="featuredhotels">
        <h2 align="center" className="sub-title">
          FEATURED <span className="custom-label"> HOTELS</span>
        </h2>
        {fetching ? (
          <ContentLoaderCards hotels="hotels" />
        ) : (
            <div>
              <Slider {...settings}>
                {featuredhotels.map((data, key) => (
                  <Card key={key}>
                    <Link to="/query/hotel-booking">
                      <Card.Img variant="top" src={data.hotel_logo} />
                      <Card.Body>
                        <ReactStars
                          edit={false}
                          count={5}
                          value={parseInt(data.hotel_rating)}
                        />
                        <div className="sub-view">
                          <div className="name-view">
                            <h6>{data.hotel_name}</h6>
                            <p>{data.hotel_location}</p>
                          </div>
                          {
                            data.show_price == 1 ?
                              <div className="form-view">
                                <h5> Starts From </h5>
                                <p>
                                  {currencySign()} {data.hotel_price}
                                </p>
                              </div> : null
                          }
                        </div>
                      </Card.Body>
                    </Link>
                  </Card>
                ))}
              </Slider>
            </div>
          )}
      </div>
    );
  }
}

export default FeaturedHotels;
