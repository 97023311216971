import types from "../types";

const initialState = {
  fetching: false,
  fetchingMarkUpTicket:false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_MARKUP_PRICE:
      return { ...state, fetching: true };
    case types.ADD_MARKUP_PRICE_SUCCESS:
      return { ...state, fetching: false };
    case types.ADD_MARKUP_PRICE_FAILED:
      return { ...state, fetching: false };

    case types.SEND_MARKUP_TICKET:
      return { ...state, fetchingMarkUpTicket : true };
    case types.SEND_MARKUP_TICKET_SUCCESS:
      return { ...state, fetchingMarkUpTicket : false };
    case types.SEND_MARKUP_TICKET_FAILED:
      return { ...state, fetchingMarkUpTicket : false };

    default:
      return state;
  }
};
