import types from "../types";

const initialState = {
    fetching: false
};

export default (state = initialState, action) => {
    switch (action.type) {

        case types.QUERY_FORM:
            return { ...state, fetching: true };
        case types.QUERY_FORM_SUCCESS:
            return { ...state, fetching: false };
        case types.QUERY_FORM_FAILED:
            return { ...state, fetching: false };

        default:
            return state;
    }
};
