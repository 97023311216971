import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

class SelectDropDown extends Component {
    state = {}
    render() {
        const {
            className = "",
            value,
            onChange,
            options,
            customInput = false,
            type,
            placeholder = null,
            name,
            label
        } = this.props;

        return (
            <React.Fragment>
                <Form.Control
                    as="select"
                    className={className}
                    value={value}
                    onChange={onChange}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    label={label}
                >
                    {
                        customInput ? <React.Fragment>{this.props.children}</React.Fragment> :
                            <React.Fragment>
                                {options && options.map((val, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <option value={val.value}>{val.label}</option>
                                        </React.Fragment>
                                    )
                                })}
                            </React.Fragment>
                    }


                </Form.Control>

            </React.Fragment>
        );
    }
}

export default SelectDropDown;