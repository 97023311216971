import React, { Component } from "react";
class SortingWebFilters extends Component {
    render() {
        const { onChangeDirection, filterObject = {}, name = "" } = this.props;
        return (
            <ul>
                <li
                    onClick={() =>
                        onChangeDirection(filterObject.sortOrder, "duration", name)
                    }
                    className={filterObject.sortBy === "duration" ? "active" : ""}
                >
                    Duration{" "}
                    {filterObject.sortBy !== "duration" ?
                        <i className="fas fa-sort" />
                        : (
                            <i
                                className={
                                    filterObject.sortOrder === "asc"
                                        ? "fas fa-chevron-up"
                                        : "fas fa-chevron-down"
                                }
                            />
                        )}
                </li>
                <li
                    onClick={() =>
                        onChangeDirection(filterObject.sortOrder, "depTime", name)
                    }
                    className={filterObject.sortBy === "depTime" ? "active" : ""}
                >
                    Depart{" "}
                    {filterObject.sortBy !== "depTime" ?
                        <i className="fas fa-sort" />
                        : (
                            <i
                                className={
                                    filterObject.sortOrder === "asc"
                                        ? "fas fa-chevron-up"
                                        : "fas fa-chevron-down"
                                }
                            />
                        )}
                </li>
                <li
                    onClick={() =>
                        onChangeDirection(filterObject.sortOrder, "arrTime", name)
                    }
                    className={filterObject.sortBy === "arrTime" ? "active" : ""}
                >
                    Arrival{" "}
                    {filterObject.sortBy !== "arrTime" ?
                        <i className="fas fa-sort" />
                        : (
                            <i
                                className={
                                    filterObject.sortOrder === "asc"
                                        ? "fas fa-chevron-up"
                                        : "fas fa-chevron-down"
                                }
                            />
                        )}
                </li>
                <li
                    onClick={() =>
                        onChangeDirection(filterObject.sortOrder, "totalPrice", name)
                    }
                    className={filterObject.sortBy === "totalPrice" ? "active" : ""}
                >
                    Price{" "}
                    {filterObject && filterObject.sortBy !== "totalPrice" ?
                        <i className="fas fa-sort" />
                        : (
                            <i
                                className={
                                    filterObject && filterObject.sortOrder === "asc"
                                        ? "fas fa-chevron-up"
                                        : "fas fa-chevron-down"
                                }
                            />
                        )}
                </li>
            </ul>
        );
    }
}

export default SortingWebFilters;