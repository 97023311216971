import React from 'react';
import {
    Row,
    Col,
    Button,
    Form
} from 'react-bootstrap';
import { stopsFilter } from '../../staticData';
import { convertToOptionsArray , isAgent } from '../../utils';
class StopsFilterList extends React.Component {

    render() {
        const { filterType = "", onChangeStops, filtersObject , handleHideOfferedPrices } = this.props;
        return (
            <React.Fragment>
                <ul className='dis-flex'>
                {/* {isAgent() ? <li className='cursor-pointer' onClick={() => handleHideOfferedPrices("onward")}>
                        <span className='offeredprices-hide-section'>Offer Price<i className={filtersObject && filtersObject.hideOfferedPrices && filtersObject.hideOfferedPrices[`hideonwardOfferedPrices`] ? "fas fa-eye" : "fas fa-eye-slash"}></i></span>
                    </li> : null} */}
                    {
                        stopsFilter && stopsFilter.length ? stopsFilter.map((item, index) => {
                            let active = filtersObject[`${filterType}Stops`].find(val => (val == index)) || 3;
                            return (
                                <li key={index}
                                    onClick={() => onChangeStops(item.value, filterType + "Stops", filterType)}
                                    className={active < 3 ? "active" : ""}>
                                    {item.label}
                                </li>
                            );
                        })
                            : null}
                </ul>
            </React.Fragment>
        );
    }
}
//className={filtersObject[`${filterType}Stops`][index] == index ? "green" : null}
export default StopsFilterList;