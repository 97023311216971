import types from "../types";

const initialState = {
    fetching: false,
    specialFares: [],
    fetchingListing: false,
    editFetching: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.POST_SPECIAL_FARES:
            return { ...state, fetching: true };
        case types.POST_SPECIAL_FARES_SUCCESS:
            return { ...state, fetching: false };
        case types.POST_SPECIAL_FARES_FAILED:
            return { ...state, fetching: false };


        case types.GET_SPECIAL_FARES_LISTING:
            return { ...state, fetchingListing: true };
        case types.GET_SPECIAL_FARES_LISTING_SUCCESS:
            return { ...state, fetchingListing: false, specialFares: action.payload.info };
        case types.GET_SPECIAL_FARES_LISTING_FAILED:
            return { ...state, fetchingListing: false };


        case types.EDIT_SPECIAL_FARES:
            return { ...state, editFetching: true };
        case types.EDIT_SPECIAL_FARES_SUCCESS:
            return { ...state, editFetching: false };
        case types.EDIT_SPECIAL_FARES_FAILED:
            return { ...state, editFetching: false };

        default:
            return state;
    }
};
