import types from "../types";
import { saveUser } from "../utils";

const initialState = {
  isAuthenticated: false,
  currentUser: {},
  fetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_LOGIN:
      return { ...state, fetching: true };
    case types.AUTH_SUCCESS:
      saveUser(action.payload);
      return { ...state, currentUser: action.payload, fetching: false };
    case types.AUTH_FAILED:
      return { ...state, fetching: false };

    case types.SIGNUP_REQUEST:
      return { ...state, fetching: true };
    case types.SIGNUP_REQUEST_SUCCESS:
      return { ...state, fetching: false };
    case types.SIGNUP_REQUEST_FAILED:
      return { ...state, fetching: false };

    case types.FORGOT_PASSWORD:
      return { ...state, fetching: true };
    case types.FORGOT_PASSWORD_SUCCESS:
      return { ...state, fetching: false };
    case types.FORGOT_PASSWORD_FAILED:
      return { ...state, fetching: false };
    //--------------------------------------------
    case types.AUTHENTICATE:
      return {
        ...state,
        isAuthenticated: action.authenticated
      };

    case types.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.user
      };

    default:
      return state;
  }
};
