import React, { Component } from 'react'
import {
  Row,
  Col,
  Container,
  Button,
  Form,
  FormControl,
  Overlay,
  Tooltip,
  Popover,
  OverlayTrigger,
  Badge,
} from 'react-bootstrap'
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeControllery,
} from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import Select, { Async } from 'react-select'
import { Multicity, Counter, ContentLoaderFare } from '../../'
import {
  passengersOption,
  classOption,
  statuses,
  passengerTypes,
  convertStaticData,
} from '../../../staticData'
import moment from 'moment'
import { classnames } from 'classnames'
import PassengerTypes from '../../flights/PassengerType'
import { currencySign, calenderPriceShow } from '../../../utils'
import { ThemeColor } from '../../../constants'

class FlightsForm extends Component {
  state = {}

  render() {
    const {
      handleChange,
      onRedirectToSearchPage,
      onChangeDate,
      onChangeRadioButton,
      onChangeRadioButtonPassenger,
      loadOptions,
      show,
      addcities,
      delcities,
      blockedDates,
      status,
      passengertype,
      focusedInput,
      onFocusChange,
      multicitydates,
      handleChangePersons,
      handleShowPassenger,
      passengerShow,
      handleFarePrices,
      clearReturnDate,
      onchangeBlur,
      onchangeFocus,
      errors,
      handleSingleDatePicker,
      handleFocusSingleDatePicker,
      focusedSinglePicker,
      handleFocusArrivalPicker,
      flightsObj,
      handleChangeClass,
      responsive,
      minimunFareMonth,
      fetching,
    } = this.props

    const { iternary } = flightsObj

    let totalPassenger =
      flightsObj.adults + flightsObj.children + flightsObj.infants > 9
        ? true
        : false

    let from = iternary[0] && iternary[0].from.hasOwnProperty('iata')
    let to = iternary[0] && iternary[0].to.hasOwnProperty('iata')
    let errorToFrom = true
    let Type = null
    let validateFilledDetails = false

    if (status == 'Multi city') {
      Type = passengerTypes.slice(0, 4)

      validateFilledDetails = false
      validateFilledDetails = iternary.some((item, index) => {
        if (item.from == null || item.to == null || totalPassenger) {
          return true
        }

        if (item.from.iata == item.to.iata || totalPassenger) {
          return true
        }

        return false
      })
    } else if (
      from &&
      to &&
      ((iternary[0] &&
        iternary[0].from &&
        iternary[0].from.country != 'India') ||
        (iternary[0] && iternary[0].to && iternary[0].to.country != 'India'))
    ) {
      Type = [
        passengerTypes[0],
        passengerTypes[2],
        passengerTypes[4],
        passengerTypes[5],
      ]
    } else {
      Type = passengerTypes
      if (from && to) {
        errorToFrom =
          (iternary[0] && iternary[0].from.iata) ==
          (iternary[0] && iternary[0].to.iata)
      }
      validateFilledDetails = show || totalPassenger || errorToFrom
    }

    let width = window.innerWidth

    return (
      <div>
        <Col xs={12} sm={12} md={12} className="form-booking">
          <Row>
            <Col
              xs={12}
              md={12}
              md={12}
              className="btn-booking-way custom-rd-btn"
            >
              <Form>
                {statuses.map((statusLabel, index) => {
                  return (
                    <React.Fragment key={index}>
                      <label
                        data-toggle="tooltip"
                        data-placement="right"
                        data-original-title="tooltip here"
                        className={
                          statusLabel === status
                            ? 'checkbox-custom active-radio-btn'
                            : 'checkbox-custom radio-btn'
                        }
                      >
                        <Form.Check
                          id={statusLabel}
                          type="radio"
                          name="status"
                          checked={statusLabel === status}
                          onChange={() => onChangeRadioButton(statusLabel)}
                          inline
                        />
                        {statusLabel}
                      </label>
                    </React.Fragment>
                  )
                })}
              </Form>
            </Col>
            {status == 'Multi city' ? (
              <React.Fragment>
                <Multicity
                  multicity={iternary || []}
                  multicitydates={multicitydates}
                  loadOptions={loadOptions}
                  delcities={delcities}
                  blockedDates={blockedDates}
                  addcities={addcities}
                  width={width}
                />
                <Col xs={12} sm={6} md={6} className="from-group-custom">
                  <label>Passenger(s)</label>
                  <div className="no-passengers" onClick={handleShowPassenger}>
                    {flightsObj.adults +
                      flightsObj.children +
                      flightsObj.infants}
                  </div>
                  {passengerShow ? (
                    <React.Fragment>
                      <Popover placement="bottom" id="popover-passenger">
                        <Row className="counter-container">
                          <ul className="passengers-list clearfix list-unstyled">
                            {totalPassenger ? (
                              <li className="mb-1">
                                <Badge variant="danger">
                                  Maximum of 9 travellers allowed
                                </Badge>
                              </li>
                            ) : null}
                            <Counter
                              persons={flightsObj}
                              maxValue={9}
                              name="adults"
                              onChange={handleChangePersons}
                              title="Adults"
                              age=">12 years"
                            />
                            <Counter
                              persons={flightsObj}
                              maxValue={
                                flightsObj &&
                                flightsObj.adults &&
                                flightsObj.adults >= 5
                                  ? 9 - flightsObj.adults
                                  : 4
                              }
                              name="children"
                              age="2 to 12 years"
                              onChange={handleChangePersons}
                              title="Children"
                            />
                            <Counter
                              persons={flightsObj}
                              maxValue={
                                flightsObj.adults > 4 ? 4 : flightsObj.adults
                              }
                              name="infants"
                              onChange={handleChangePersons}
                              title="Infants"
                              age="7 days to 2 years"
                            />
                          </ul>
                        </Row>
                      </Popover>
                      <div
                        onClick={handleShowPassenger}
                        className="popover-overlay"
                      />
                    </React.Fragment>
                  ) : null}
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  className="from-group-custom custom-home-economy-section-multicity"
                >
                  <label> Class</label>
                  <Select
                    isSearchable={false}
                    defaultValue={classOption[0]}
                    value={classOption.find((i) => i.value === iternary.class)}
                    onChange={(e) => handleChangeClass(e.value)}
                    options={classOption}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary: ThemeColor.primary,
                      },
                    })}
                  />
                </Col>
              </React.Fragment>
            ) : (
              //One Way and Round Trip-------------------------
              <React.Fragment>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  className="from-group-custom react-select"
                >
                  <label> From</label>
                  <Async
                    value={(iternary.length && iternary[0].from) || null}
                    onMenuClose={(e) => onchangeBlur(e, 'from')}
                    onMenuOpen={(e) => onchangeFocus(e, 'from')}
                    placeholder={''}
                    onChange={(e) => handleChange(e, 'from')}
                    loadOptions={loadOptions}
                    defaultOptions={convertStaticData(passengersOption)}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary: ThemeColor.primary,
                      },
                    })}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  className="from-group-custom react-select "
                >
                  <label>To </label>
                  <Async
                    value={(iternary.length && iternary[0].to) || null}
                    onMenuClose={(e) => onchangeBlur(e, 'to')}
                    onMenuOpen={(e) => onchangeFocus(e, 'to')}
                    placeholder={''}
                    onChange={(e) => handleChange(e, 'to')}
                    loadOptions={loadOptions}
                    defaultOptions={convertStaticData(passengersOption)}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary: ThemeColor.primary,
                      },
                    })}
                  />
                  {show ? (
                    <Tooltip
                      placement="bottom"
                      className="tooltip_error"
                      id="tooltip-right"
                    >
                      <span> From & To cannot be same</span>
                    </Tooltip>
                  ) : null}
                </Col>

                {status == 'Round trip' ? (
                  <Col sm={12} md={12} className="from-group-custom datepicker">
                    <label>
                      Depart <span className="return-flight"> Return</span>
                    </label>
                    <i className="fas fa-times" onClick={clearReturnDate} />
                    <DateRangePicker
                      hideKeyboardShortcutsPanel={true}
                      startDate={
                        (iternary &&
                          iternary.length &&
                          iternary[0] &&
                          moment(iternary[0].date)) ||
                        moment()
                      }
                      minimumNights={0}
                      numberOfMonths={width < 991 ? 1 : 2}
                      displayFormat="DD/MM/YYYY"
                      startDatePlaceholderText="Pick a Date"
                      endDatePlaceholderText="Pick a Date"
                      startDateId=""
                      orientation={width < 999 ? 'vertical' : 'horizontal'}
                      verticalHeight={355}
                      daySize={width < 999 ? 30 : 45}
                      renderDayContents={(date, index) => {
                        let {
                          fare = null,
                          lowFare = false,
                          Currency_code,
                        } = handleFarePrices(date)
                        if (fetching) {
                          return (
                            <React.Fragment>
                              {date.format('DD')}
                              <ContentLoaderFare />
                            </React.Fragment>
                          )
                        }
                        return (
                          <React.Fragment>
                            {date.format('DD')}
                            {fare && (
                              <span
                                className={
                                  lowFare ? 'pricetag green' : 'pricetag'
                                }
                              >
                                {currencySign(Currency_code)}
                                {calenderPriceShow(fare)}
                              </span>
                            )}
                          </React.Fragment>
                        )
                      }}
                      endDate={
                        (iternary &&
                          iternary.length &&
                          iternary[1] &&
                          moment(iternary[1].date)) ||
                        null
                      }
                      endDateId=""
                      onDatesChange={({ startDate, endDate }) =>
                        onChangeDate(startDate, endDate)
                      }
                      focusedInput={focusedInput}
                      onFocusChange={(e) => onFocusChange(e)}
                      onNextMonthClick={(event) => minimunFareMonth(event)}
                      onPrevMonthClick={(event) => {
                        minimunFareMonth(event)
                      }}
                      readOnly
                    />
                  </Col>
                ) : (
                  <React.Fragment>
                    <Col
                      sm={12}
                      md={6}
                      className="from-group-custom datepicker "
                    >
                      <label> Depart </label>
                      <SingleDatePicker
                        date={
                          (iternary &&
                            iternary.length &&
                            iternary[0] &&
                            moment(iternary[0].date)) ||
                          moment()
                        } // momentPropTypes.momentObj or null
                        onDateChange={(date) => handleSingleDatePicker(date)} // PropTypes.func.isRequired
                        focused={focusedSinglePicker}
                        displayFormat="DD/MM/YYYY"
                        daySize={width < 999 ? 30 : 45}
                        numberOfMonths={width < 991 ? 1 : 2}
                        orientation={width < 999 ? 'vertical' : 'horizontal'}
                        verticalHeight={355}
                        onFocusChange={({ focused }) =>
                          handleFocusSingleDatePicker({ focused })
                        } // PropTypes.func.isRequired
                        id="your_unique_id" // PropTypes.string.isRequired,
                        renderDayContents={(date, index) => {
                          let {
                            fare = null,
                            lowFare = false,
                            Currency_code,
                          } = handleFarePrices(date)
                          if (fetching) {
                            return (
                              <React.Fragment>
                                {date.format('DD')}
                                <ContentLoaderFare />
                              </React.Fragment>
                            )
                          }
                          return (
                            <React.Fragment>
                              {date.format('DD')}
                              {fare && (
                                <span
                                  className={
                                    lowFare ? 'pricetag green' : 'pricetag'
                                  }
                                >
                                  {currencySign(Currency_code)}{' '}
                                  {calenderPriceShow(fare)}
                                </span>
                              )}
                            </React.Fragment>
                          )
                        }}
                        onNextMonthClick={(event) => minimunFareMonth(event)}
                        onPrevMonthClick={(event) => {
                          minimunFareMonth(event)
                        }}
                        readOnly
                      />
                    </Col>
                    <Col
                      sm={12}
                      md={6}
                      className="from-group-custom datepicker"
                    >
                      <label>Return</label>
                      <SingleDatePicker
                        placeholder="Pick a Date"
                        date={
                          (iternary &&
                            iternary.length &&
                            iternary[1] &&
                            moment(iternary[1].date)) ||
                          null
                        } // momentPropTypes.momentObj or null
                        onDateChange={(date) => {}} // PropTypes.func.isRequired
                        focused={false}
                        displayFormat="DD/MM/YYYY"
                        onFocusChange={({ focused }) =>
                          handleFocusArrivalPicker({ focused })
                        } // PropTypes.func.isRequired
                        id="your" // PropTypes.string.isRequired,
                      />
                    </Col>
                  </React.Fragment>
                )}
                <Col xs={12} sm={6} md={6} className="from-group-custom">
                  <label>Traveller(s)</label>
                  <div className="no-passengers" onClick={handleShowPassenger}>
                    {flightsObj.adults +
                      flightsObj.children +
                      flightsObj.infants >
                    9
                      ? '9'
                      : flightsObj.adults +
                        flightsObj.children +
                        flightsObj.infants}
                  </div>
                  {passengerShow ? (
                    <React.Fragment>
                      <Popover placement="bottom" id="popover-passenger">
                        <Row className="counter-container">
                          <ul className="passengers-list clearfix list-unstyled">
                            {totalPassenger ? (
                              <li className="mb-1">
                                <Badge variant="danger">
                                  Maximum of 9 travellers allowed
                                </Badge>
                              </li>
                            ) : null}
                            <Counter
                              persons={flightsObj}
                              maxValue={9}
                              name="adults"
                              onChange={handleChangePersons}
                              title="Adults"
                              age="12+ years"
                            />
                            <Counter
                              persons={flightsObj}
                              maxValue={
                                flightsObj &&
                                flightsObj.adults &&
                                flightsObj.adults >= 5
                                  ? 9 - flightsObj.adults
                                  : 4
                              }
                              name="children"
                              age="2 to 12 years"
                              onChange={handleChangePersons}
                              title="Children"
                            />
                            <Counter
                              persons={flightsObj}
                              maxValue={
                                flightsObj.adults > 4 ? 4 : flightsObj.adults
                              }
                              name="infants"
                              onChange={handleChangePersons}
                              title="Infants"
                              age="7 days to 2 years"
                            />
                          </ul>
                        </Row>
                      </Popover>
                      <div
                        onClick={handleShowPassenger}
                        className="popover-overlay"
                      />
                    </React.Fragment>
                  ) : null}
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  className="from-group-custom custom-home-economy-section-mobile"
                >
                  <label> Class</label>
                {/*}   defaultMenuIsOpen */}
                    <Select
                 
                      isSearchable={false}
                      defaultValue={classOption[0]}
                      value={classOption.find(
                        i => i.value === iternary.class
                      )}
                      onChange={e => handleChangeClass(e.value)}
                      options={classOption}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary: ThemeColor.primary,
                        }
                      })}
                    />
                    

               {/*}   <FormControl
                    as="select"
                    value={classOption.find((i) => i.value === iternary.class)}
                    name="phoneCode"
                    onChange={(e) => handleChangeClass(e.value)}
                  >
                    {classOption && classOption.length
                      ? classOption.map((item, index) => {
                          return (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          )
                        })
                      : null}
                  </FormControl>
                      */}
                </Col>
              </React.Fragment>
            )}

            <Col
              xs={12}
              sm={12}
              md={12}
              className="btn-booking-way custom-home-passenger-section-mobile"
            >
              <Form>
                <ul className="custom-type">
                  <PassengerTypes
                    Type={Type}
                    onChangeRadioButtonPassenger={onChangeRadioButtonPassenger}
                    responsive={responsive}
                    passengertype={passengertype}
                  />
                </ul>
              </Form>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              className="btn-booking-custom text-center"
            >
              <Button
                disabled={validateFilledDetails}
                onClick={onRedirectToSearchPage}
                className="login-btn"
              >
                SEARCH
              </Button>
            </Col>
          </Row>
        </Col>
      </div>
    )
  }
}

export default FlightsForm
