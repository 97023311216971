import { apiPost, apiGet } from "../utils";
import { apiKey } from "../constants";

export function registerBusinessAPI(data) {
    let newdata = new FormData();
    Object.keys(data).forEach((item) => {
        newdata.append(item, data[item])
    })
    return apiPost(`/api/registerbusiness?appKey=${apiKey}`, newdata);
}

export function getStatesandCitiesAPI(type, id) {
    return apiGet(`/api/countrystatecity?appKey=${apiKey}&type=${type}&id=${id}`);
}

export function validateEmailMobileAPI(data) {
    return apiPost(`/api/validate?appKey=${apiKey}`, data);
}