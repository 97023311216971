import React, { useEffect } from 'react';
import moment from 'moment';

const FlightSearchLoader = props => {

    const {
        autoFillObjectState = {}
    } = props;
    const { iternary = [] } = autoFillObjectState;
    const iternaryLength = iternary && iternary.length
    const isOneWay = iternary && iternary.length === 1 ? true : false
    const { adults = 0, infants = 0, children = 0 } = autoFillObjectState;
    const totalPassengers = adults + infants + children
    const isMulticity = autoFillObjectState && autoFillObjectState.trip && autoFillObjectState.trip.label === "Multi city" ? true : false
    const iconType = autoFillObjectState && autoFillObjectState.trip && autoFillObjectState.trip.label === "One way" ? "fas fa-long-arrow-alt-right" : "fas fa-exchange-alt"
    
    useEffect(() => {
        document.body.classList.add("flights-loading");
        return () => {
          document.body.classList.remove("flights-loading");
        };
    }, []);

    return (
        <div className='full-loader perfect-departure-animation'>
         
            <div className="perfect-departure-animation__cloud--back">   <img src={require("../../assets/Loader/cloud.svg").default} alt='not available' /> </div>
            <div className="perfect-departure-animation__cloud--middle">   <img src={require("../../assets/Loader/cloud1.svg").default} alt='not available' /> </div>
            <div className="perfect-departure-animation__plane">   <img src={require("../../assets/Loader/plane.svg").default} alt='not available' /> </div>
            <div className='flight-details'>
                <h5 className='text-color'>Please wait</h5>
                <p className="after-border">Loading the best fares for you</p>
                {!isMulticity ?
                    <React.Fragment>
                        <h6 className='text-color'>{iternary.length && iternary[0].from && iternary[0].from.city} <i className={iconType}></i> {iternary.length && iternary[isMulticity ? iternaryLength - 1 : 0].to && iternary[isMulticity ? iternaryLength - 1 : 0].to.city}</h6>
                        <p>{`${moment(iternary.length && iternary[0].date).format("DD/MM/YYYY")} ${!isOneWay ? `- ${moment(iternary.length && iternary[iternaryLength - 1].date).format("DD/MM/YYYY")}` : ""} (${totalPassengers} ${totalPassengers === 1 ? "Passenger" : "Passengers"})`}</p>
                    </React.Fragment>
                    : <React.Fragment>
                        {
                            iternary && iternary.length ? iternary.map((item, index) => {
                                return <React.Fragment key={index}>
                                    <div className='multicity-loader'>
                                        <h6 className='text-color'>{(item && item.from && item.from.city) || ""} <i className="fas fa-exchange-alt"></i> {(item && item.to && item.to.city) || ""}</h6>
                                        <p>{moment(item.date).format("DD/MM/YYYY")}</p>
                                    </div>
                                </React.Fragment>
                            }) : null
                        }
                        <p>{`(${totalPassengers} ${totalPassengers === 1 ? "Passenger" : "Passengers"})`}</p>
                    </React.Fragment>
                }</div>
            <div className="footer-animation">
            <div className="perfect-departure-animation__pyramid"><img src={require("../../assets/Loader/pyramid.png")} alt='not available' /> </div>
            <div className="perfect-departure-animation__statue-of-liberty"><img src={require("../../assets/Loader/statue-of-liberty.png")} alt='not available' /> </div>
            <div className="perfect-departure-animation__sydney-opera-house"><img src={require("../../assets/Loader/sydney-opera-house.png")} alt='not available' /> </div>
            <div className="perfect-departure-animation__golden-gate"><img src={require("../../assets/Loader/golden-gate.png")} alt='not available' /> </div>
            <div className="perfect-departure-animation__eiffel-tower"><img src={require("../../assets/Loader/eiffel-tower.png")} alt='not available' /> </div>
            <div className="perfect-departure-animation__burj"><img src={require("../../assets/Loader/burj-al-arab.png")} alt='not available' /> </div>
            <div className="perfect-departure-animation__berlin"><img src={require("../../assets/Loader/berlin.png")} alt='not available' /> </div>
            <div className="perfect-departure-animation__big-ben"><img src={require("../../assets/Loader/big-ben.png")} alt='not available' /> </div> 
            <div className="perfect-departure-animation__leaning-tower-of-pisa"><img src={require("../../assets/Loader/leaning-tower-of-pisa.png")} alt='not available' /> </div>
               
               
                
            </div>
        </div>
    )

};

export default FlightSearchLoader;
