import { apiPost, getObject, getUser, apiGet } from "../utils";
import { apiKey, TokenId } from "../constants";


//-----------------------------flight fare-------------------
export function getFaresInfoAPI(value) {
  const data = JSON.stringify({
    "JourneyType": "1",
    "PreferredAirlines": null,
    "Segments": [value],
    "Sources": null,
    "TokenId": TokenId,
    "EndUserIp": getObject('ipaddress')
  })

  return apiPost(`/api/minimumfare?&appKey=${apiKey}`, data);
}

export function setFareQuotaAPI(data) {
  return apiPost(`/api/farequota?appKey=${apiKey}`,
    {
      ...data,
      "TokenId": TokenId,
      "EndUserIp": getObject('ipaddress'),
      "token": getUser() && getUser().token ? getUser().token : ""
    })
}

export function setFlightServicesAPI(data) {
  return apiPost(`/api/flightservices?appKey=${apiKey}`,
    {
      ...data, "TokenId": TokenId, "EndUserIp": getObject('ipaddress')
    })
}


export function flightBookingAPI(data) {
  return apiPost(`/api/flightbooking?appKey=${apiKey}`,
    {
      ...data, "TokenId": TokenId, "EndUserIp": getObject('ipaddress')
    })
}

export function flightTicketsBookingAPI(data) {
  return apiPost(`/api/flightticket?appKey=${apiKey}`,
    {
      ...data, "TokenId": TokenId, "EndUserIp": getObject('ipaddress')
    })
}

export function flightPaymentAPI(data) {
  return apiPost(`/api/payment?appKey=${apiKey}`, data)
}

export function flightBookAfterPaymentAPI(data) {
  let bookingKey = localStorage.getItem(`bookingkey`)
  return apiPost(`/api/flightbook?appKey=${apiKey}`, {
    ...data, "TokenId": TokenId, "EndUserIp": getObject('ipaddress'), "bookingKey": bookingKey
  })
}

export function paymentSuccessOrderGenerationAPI(data) {
  return apiPost(`/api/paymentsuccess?appKey=${apiKey}`, {
    "order_id": data.order_id,
    "txn_id": data.txn_id,
    "txn_uuid": data.txn_uuid,
    "status": data.status,
    "token": data.token
  })
}

//----------------------------------------------------------


//-----------------TBO Balance Fare------------------------
export function checkTBOBalanceApi(value) {

  const data = JSON.stringify({
    ...value,
    "TokenId": TokenId,
    "EndUserIp": getObject('ipaddress')
  })
  return apiPost(`/api/checktbobalance?appKey=${apiKey}`, data)

}

export function inSufficientFundsApi(value) {
  const data = JSON.stringify(value)
  return apiPost(`/api/sendflightrequest?appKey=${apiKey}`, data)

}

//----------------------------------------------------------


//-----------------offline payment request---------------------
export function getOfflinePaymentAmountAPI(value) {
  return apiGet(`/api/requestPayment?appKey=${apiKey}&id=${value}`)
}

export function getOfflinePaymentPortalAPI(value) {
  let data = JSON.stringify(value)
  return apiPost(`/api/requestPayment?appKey=${apiKey}`, data)
}

export function requestPaymentSuccessAPI(value) {
  let data = JSON.stringify(value)
  return apiPost(`/api/payment/requestPaymentResponse?appKey=${apiKey}`, data)
}
//-------------------------------------------------------------