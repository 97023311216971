import types from "../types";
import { registerBusinessAPI, getStatesandCitiesAPI, validateEmailMobileAPI } from "../api/registerBusiness";

export const registerBusiness = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.REGISTER_BUSINESS
        });
        registerBusinessAPI(data)
            .then(res => {
                dispatch({
                    type: types.REGISTER_BUSINESS_SUCCESS,
                });
                return resolve(res);
            })
            .catch(err => {

                dispatch({
                    type: types.REGISTER_BUSINESS_FAILED
                });
                return reject(err);
            });
    }
    );

export const validateEmailMobile = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.VALIDATE_EMAIL_MOBILE
        });
        validateEmailMobileAPI(data)
            .then(res => {
                dispatch({
                    type: types.VALIDATE_EMAIL_MOBILE_SUCCESS,
                });
                return resolve(res);
            })
            .catch(err => {

                dispatch({
                    type: types.VALIDATE_EMAIL_MOBILE_FAILED
                });
                return reject(err);
            });
    }
    );

export const getStatesAndCities = (type = '', id = '') => dispatch =>
    new Promise((resolve, reject) => {
        getStatesandCitiesAPI(type, id)
            .then(res => {
                dispatch(setCountryStateCity(type, res));
                return resolve(res);
            })
            .catch(err => {
                return reject(err);
            });
    }
    );


export const setCountryStateCity = (type, payload) => {
    let actionType = type ? type.toUpperCase() : "COUNTRIES";

    return ({
        type: types[`GET_${actionType}`],
        payload
    })
}

export const clearStates = () => dispatch =>
    new Promise((resolve, reject) =>
        dispatch({
            type: types.CLEAR_STATES,
            payload: []
        })
    );

export const clearCities = () => dispatch =>
    new Promise((resolve, reject) =>
        dispatch({
            type: types.CLEAR_CITIES,
            payload: []
        })
    );