import { combineReducers } from "redux";
import auth from "./auth";
import profile from "./profile";
import getFaresInfo from "./getFaresInfo";
import getSearchResult from "./getSearchResult";
import featuredSection from "./featuredSection";
import getStaticInfo from "./getStaticInfo";
import registerBusiness from "./registerBusiness"
import insurance from './insurance';
import manageBookings from './manageBookings';
import wallet from "./wallet";
import specialFares from './specialFares';
import agentMarkup from './agentMarkup';
import profileSettings from './profileSettings';
import queryForm from "./queryForm";
import hotels from "./hotels";
import coupons from "./coupons";

export default combineReducers({
  auth,
  profile,
  getFaresInfo,
  getSearchResult,
  featuredSection,
  getStaticInfo,
  registerBusiness,
  insurance,
  manageBookings,
  wallet,
  specialFares,
  agentMarkup,
  profileSettings,
  queryForm,
  hotels,
  coupons
});
