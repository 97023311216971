import React, { Component } from 'react';

class Buses extends Component {
    state = {}
    render() {
        return (<div>Buses Data
            </div>);
    }
}

export default Buses;