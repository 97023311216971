import React from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import AuthenticatedRoute from "../components/authenticated-route";
import UnauthenticatedRoute from "../components/unauthenticated-route";
import PublicRoute from "../components/public-route";
import Loadable from "react-loadable";

import NotFound from "./not-found";

const Homepage = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./homepage"),
  loading: () => null,
  modules: ["homepage"]
});

// const Loader = () => {
//   return (
//     <Container>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "calc(100vh - 80px)"
//         }}
//       >
//         <img
//           style={{ height: "70px", width: "70px" }}
//           src={require("../assets/loader.gif")}
//         />
//       </div>
//     </Container>
//   );
// };

// const Homepage = Loadable({
//   loader: () => {
//     return new Promise(resolve => setTimeout(resolve, 500)).then(
//       () => import("./homepage"))
//   },
//   loading: Loader,
//   modules: ["homepage"]
// });

const FlightSearch = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./flightsearch"),
  loading: () => null,
  modules: ["flightsearch"]
});

const HotelSearch = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./hotelsearch"),
  loading: () => null,
  modules: ["hotelsearch"]
});

const EmailVerificationDoneScreen = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ "./verificationdone"),
  loading: () => null,
  modules: ["verificationdone"]
});

const About = Loadable({
  loader: () => import(/* webpackChunkName: "about" */ "./about"),
  loading: () => null,
  modules: ["about"]
});


const Login = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./login"),
  loading: () => null,
  modules: ["login"]
});

const Signup = Loadable({
  loader: () => import(/* webpackChunkName: "signup" */ "./signup"),
  loading: () => null,
  modules: ["signup"]
});

const ForgotPassword = Loadable({
  loader: () =>
    import(/* webpackChunkName: "forgotpassword" */ "./forgotpassword"),
  loading: () => null,
  modules: ["forgotpassword"]
});

const ResetPassword = Loadable({
  loader: () =>
    import(/* webpackChunkName: "resetpassword" */ "./resetpassword"),
  loading: () => null,
  modules: ["resetpassword"]
});

// const Profile = Loadable({
//   loader: () => import(/* webpackChunkName: "profile" */ "./profile"),
//   loading: () => null,
//   modules: ["profile"]
// });
 
const verifyEmail = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./verifyemail"),
  loading: () => null,
  modules: ["verifyemail"]
});

const FlightReview = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./flightReview"),
  loading: () => null,
  modules: ["flightReview"]
});

const RegisterBusiness = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./registerBusiness"),
  loading: () => null,
  modules: ["registerBusiness"]
});

const TermsCondition = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./termsCondition"),
  loading: () => null,
  modules: ["termsCondition"]
});

const PaymentStatus = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./paymentstatus"),
  loading: () => null,
  modules: ["paymentstatus"]
});

const OfflinePaymentStatus = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./paymentstatus/offlinepaymentstatus"),
  loading: () => null,
  modules: ["offlinepaymentstatus"]
});

const Thankyou = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./paymentstatus/offlinepaymentstatus/Thankyou"),
  loading: () => null,
  modules: ["Thankyou"]
});


const ManageBookings = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./managebookings"),
  loading: () => null,
  modules: ["managebookings"]
});

const BookingDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "profile" */ "./managebookings/bookingdetails/BookingDetails"
    ),
  loading: () => null,
  modules: ["bookingdetail"]
});

const MyWallet = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./mywallet"),
  loading: () => null,
  modules: ["mywallet"]
});

const SpecialFares = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./specialfares"),
  loading: () => null,
  modules: ["specialfares"]
});

const MyProfile = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./myprofile"),
  loading: () => null,
  modules: ["myprofile"]
});

const Support = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./support"),
  loading: () => null,
  modules: ["support"]
});

const QueryForm = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./query"),
  loading: () => null,
  modules: ["query"]
})

const Contact = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./contact"),
  loading: () => null,
  modules: ["contact"]
});

const PrivacyPolicy = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./privacyPolicy"),
  loading: () => null,
  modules: ["privacyPolicy"]
});

const HotelDetail = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./hotelDetails"),
  loading: () => null,
  modules: ["hotelDetails"]
});

const HotelReview = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "profile" */ "./hotelDetails/steps/hotelReview"),
  loading: () => null,
  modules: ["hotelReview"]
});

const Unsubscribe = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./unSubscribe"),
  loading: () => null,
  modules: ["unSubscribe"]
})

const GuestManageBookings = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./managebookings/GuestManageBookings"),
  loading: () => null,
  modules: ["GuestManageBookings"]
})

export default () => {
  
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <div>
        <Switch>
          <UnauthenticatedRoute exact path="/" component={Homepage} showFooter={false} />
          <UnauthenticatedRoute path="/about" component={About} showFooter={false} />
          <UnauthenticatedRoute path="/contact" component={Contact} showFooter={false} />
          <UnauthenticatedRoute path="/privacy-policy" component={PrivacyPolicy} showFooter={false} />
          <UnauthenticatedRoute path="/terms-conditions" component={TermsCondition} />
          <UnauthenticatedRoute path="/support" component={Support} />

          <UnauthenticatedRoute path="/Query/:id" component={QueryForm} />
          <UnauthenticatedRoute path="/un-subscribe" component={Unsubscribe} />
          <UnauthenticatedRoute path="/manage/bookings" component={GuestManageBookings} />

          {/* <UnauthenticatedRoute path="/hotelsearch" component={HotelSearch} /> */}
          {/* <UnauthenticatedRoute path="/profile/:id" component={Profile} /> */}
          {/* <AuthenticatedRoute path="/dashboard" component={Dashboard} /> */}
          <UnauthenticatedRoute path="/signup" component={Signup} />
          <UnauthenticatedRoute path="/verify" component={EmailVerificationDoneScreen} />
          <UnauthenticatedRoute path="/verify-email" component={verifyEmail} />
          <UnauthenticatedRoute path="/forgot-password" component={ForgotPassword} />
          <UnauthenticatedRoute path="/reset-password" component={ResetPassword} />
          <UnauthenticatedRoute exact path="/register-business" component={RegisterBusiness} />
          <UnauthenticatedRoute path="/login" component={Login} />
          <UnauthenticatedRoute path="/verify-email" component={verifyEmail} />
          
          <UnauthenticatedRoute path="/payment-status" component={PaymentStatus} />
          <UnauthenticatedRoute path="/requestpayment" component={OfflinePaymentStatus} />
          <UnauthenticatedRoute path="/thank-you" component={Thankyou} />

          <UnauthenticatedRoute path="/flights/booking/:activeTab" component={FlightReview} />
          <UnauthenticatedRoute path="/flights/search/" component={FlightSearch} />

          <UnauthenticatedRoute path="/hotel/search/" component={HotelSearch} />
          <UnauthenticatedRoute path="/hotel/booking/review" component={HotelDetail} />
          <UnauthenticatedRoute path="/hotel/booking/detail" component={HotelReview}/>

          <AuthenticatedRoute path="/manage-bookings" component={ManageBookings} />
          <UnauthenticatedRoute path="/booking-detail/" component={BookingDetails} />
          <AuthenticatedRoute path="/profile-settings/" component={MyProfile} />
          <AuthenticatedRoute path="/my-wallet" component={MyWallet} />
          <AuthenticatedRoute path="/special-fares" component={SpecialFares} />
          
          <UnauthenticatedRoute component={NotFound} />
        </Switch>
      </div>
    </BrowserRouter>
  );
};
