import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from "../../utils";
import { Header, TopNotificationBar, Sidebar } from "../../components";
import { Container, Col, Row } from "react-bootstrap";
import { FooterFlightSearch } from '../../components';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {

  const { showStickyHeader = false } = rest;

  return (
    <Route
      {...rest}
      render={props => {
        setTimeout(() => {
          window.scrollTo(0, 0)
        }, 250);
        return (
          isLoggedIn() ? (
            <React.Fragment>
              <section className="top-header-fixed" id={showStickyHeader ? "sticky-header" : ""}>
                <Container>
                  <Row>
                    <Sidebar
                      {...props}
                      pageWrapId={"homepage"}
                      outerContainerId={"app"}
                    />
                    <Col>
                      <Header
                        isShowingMenu={true}
                        {...props}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
              <div className='min-height-loader-content'>
                <Component {...props} />
              </div>
              <FooterFlightSearch />
            </React.Fragment>
          ) : (
              <Redirect to={`/login?redirect=${props.location.pathname}`} />
            )
        )
      }
      }
    />
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  null
)(AuthenticatedRoute);
