//File Name: HomepageSearch.js
//Path: src/app/coomponents/homepage
//Description: This file is used for Searching.
import React, { Component } from "react";
import { Tabs, Tab, Row, Col, Container, Button } from "react-bootstrap";
import Flights from "./bookingOptions/Flights";
import Hotels from "./bookingOptions/Hotels/Hotels";
import Cabs from "./bookingOptions/Cabs";
import Buses from "./bookingOptions/Buses";

class HomepageSearch extends Component {
  state = {};

  updateRadioButton = (value, name) => {
    this.flights.wrappedInstance.onChangeRadioButtonPassenger(value, name)
  }

  render() {
    const { onChangeBanner } = this.props;
    return (
      <div className="homepagesearch">

        <Flights {...this.props} />

        {/* // Remove Other tabs for the time being */}
        {/* <Tabs defaultActiveKey="flights" id="" onSelect={(e) => onChangeBanner(e)}>
          <li eventKey="flights" title={<React.Fragment><img src={require("../../assets/homepage/search-menu/flights-01.svg")} />  Flights</React.Fragment>} >
            <Flights {...this.props} ref={c => this.flights = c} />
          </li>
          <li eventKey="hotels" title={<React.Fragment><img src={require("../../assets/homepage/search-menu/hotels-01.svg")} />  Hotels </React.Fragment>} >
            <Hotels {...this.props} />
          </li>
          <li eventKey="cabs" title={<React.Fragment><img src={require("../../assets/homepage/search-menu/cabs-01.svg")} />  Cabs </React.Fragment>} >
            <h5 style={{ padding: 40 }}> Coming soon</h5>
          </li>
          <li eventKey="buses" title={<React.Fragment><img src={require("../../assets/homepage/search-menu/buses-01.svg")} />  Buses </React.Fragment>} >
            <h5 style={{ padding: 40 }}> Coming soon</h5>
          </li>
          <li eventKey="travel" title={<React.Fragment><img src={require("../../assets/homepage/search-menu/travel-insurance-01.svg")} />  Travel Insurance </React.Fragment>} >
            <h5 style={{ padding: 40 }}> Coming soon</h5>
          </li>
        </Tabs> */}

      </div>
    );
  }
}

export default HomepageSearch;
