import types from "../types";
import {
    addWalletMoneyAPI,
    addedWalletMoneyAPI,
    getWalletDetailsAPI,
    getFilteredWalletDetailsAPI,
    getBankDetailsAPI,
    offlinePaymentAPI
} from "../api/wallet";

export const addWalletMoney = (payload) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.ADD_WALLET_MONEY
        });
        addWalletMoneyAPI(payload)
            .then(res => {
                dispatch({
                    type: types.ADD_WALLET_MONEY_SUCCESS,
                    payload: res
                });
                return resolve(res);
            })
            .catch(err => {
                dispatch({
                    type: types.ADD_WALLET_MONEY_FAILED
                });
                return reject(err);
            });
    }
    );

export const added_Wallet_Money = (payload) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.ADDED_WALLET_MONEY
        });
        addedWalletMoneyAPI(payload)
            .then(res => {
                dispatch({
                    type: types.ADDED_WALLET_MONEY_SUCCESS,
                    payload: res
                });
                return resolve(res);
            })
            .catch(err => {
                dispatch({
                    type: types.ADDED_WALLET_MONEY_FAILED
                });
                return reject(err);
            });
    }
    );


export const getWalletDetails = () => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.GET_WALLET_DETAILS
        });
        getWalletDetailsAPI()
            .then(res => {
                dispatch({
                    type: types.GET_WALLET_DETAILS_SUCCESS,
                    payload: res.info
                });
                return resolve(res);
            })
            .catch(err => {
                dispatch({
                    type: types.GET_WALLET_DETAILS_FAILED
                });
                return reject(err);
            });
    }
    );

export const getFilteredWalletDetails = (payload) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.GET_WALLET_DETAILS
        });
        getFilteredWalletDetailsAPI(payload)
            .then(res => {
                dispatch({
                    type: types.GET_WALLET_DETAILS_SUCCESS,
                    payload: res.info
                });
                return resolve(res);
            })
            .catch(err => {
                dispatch({
                    type: types.GET_WALLET_DETAILS_FAILED
                });
                return reject(err);
            });
    }
    );

export const getBankDetails = (payload) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.GET_PAYMENTMODE_BANKDETAILS
        });
        getBankDetailsAPI(payload)
            .then(res => {
                dispatch({
                    type: types.GET_PAYMENTMODE_BANKDETAILS_SUCCESS,
                    payload: res.info
                });
                return resolve(res);
            })
            .catch(err => {
                dispatch({
                    type: types.GET_PAYMENTMODE_BANKDETAILS_FAILED
                });
                return reject(err);
            });
    }
    );

export const OfflinePayment = (payload) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.GET_PAYMENTMODE_OFFLINE_PAYMENT
        });
        offlinePaymentAPI(payload)
            .then(res => {
                dispatch({
                    type: types.GET_PAYMENTMODE_OFFLINE_PAYMENT_SUCCESS,
                    payload: res.info
                });
                return resolve(res);
            })
            .catch(err => {
                dispatch({
                    type: types.GET_PAYMENTMODE_OFFLINE_PAYMENT_FAILED
                });
                return reject(err);
            });
    }
    );
