import types from "../types";
import { queryFormAPI } from "../api/queryForm";

export const sendQuery = (payload) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.QUERY_FORM
        });
        queryFormAPI(payload)
            .then(res => {
                dispatch({
                    type: types.QUERY_FORM_SUCCESS,
                    payload: res
                });
                return resolve(res);
            })
            .catch(err => {
                dispatch({
                    type: types.QUERY_FORM_FAILED
                });
                return reject(err);
            });
    }
    );
