export const GET_STATIC_DATA = "GET_STATIC_DATA";
export const GET_STATIC_DATA_SUCCESS = "GET_STATIC_DATA_SUCCESS";
export const GET_STATIC_DATA_FAILED = "GET_STATIC_DATA_FAILED";

export const SUBSCRIBE_EMAIL = "SUBSCRIBE_EMAIL";
export const SUBSCRIBE_EMAIL_SUCCESS = "SUBSCRIBE_EMAIL_SUCCESS";
export const SUBSCRIBE_EMAIL_FAILED = "SUBSCRIBE_EMAIL_FAILED";

export const GET_CARD_PERCENTAGE = "GET_CARD_PERCENTAGE";
export const GET_CARD_PERCENTAGE_SUCCESS = "GET_CARD_PERCENTAGE_SUCCESS";
export const GET_CARD_PERCENTAGE_FAILED = "GET_CARD_PERCENTAGE_FAILED";

export const UN_SUBSCRIBE_EMAIL = "UN_SUBSCRIBE_EMAIL";
export const UN_SUBSCRIBE_EMAIL_SUCCESS = "UN_SUBSCRIBE_EMAIL_SUCCESS";
export const UN_SUBSCRIBE_EMAIL_FAILED = "UN_SUBSCRIBE_EMAIL_FAILED";

export const GET_COUNTRIES_LIST_RESULT = "GET_COUNTRIES_LIST_RESULT"
export const GET_COUNTRIES_LIST_RESULT_SUCCESS = "GET_COUNTRIES_LIST_RESULT_SUCCESS"
export const GET_COUNTRIES_LIST_RESULT_FAILED = "GET_COUNTRIES_LIST_RESULT_FAILED"