//File Name: inputField.js
//Path: src/app/coomponents/common
//Description: This file is used for Inputs.
import React from "react";
import { FormControl, FormGroup, Image } from "react-bootstrap";
import classnames from "classnames";

const InputField = props => {
  let {
    type,
    placeholder,
    name,
    value,
    error,
    onChange,
    readOnly,
    label,
    className,
    id,
    required,
    defaultValue,
    icon,
    rows,
    onFocus,
    helpText,
    onBlur,
    onKeyPress = () => { },
    disabled
  } = props;
  return (
    <FormGroup className={classnames("", { "has-error": error })}>
      {label ? (
        <span>
          <label htmlFor={id}>{label} {required ? <sup>*</sup> : null}</label>
        </span>
      ) : null}
      {type !== "textarea" ? (
        <FormControl
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          className={className}
          defaultValue={defaultValue}
          id={id}
          readOnly={readOnly}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          disabled={disabled}
        />
      ) : (
          <FormControl
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            className={className}
            defaultValue={defaultValue}
            id={id}
            rows={rows}
            componentclass="textarea"
            onFocus={onFocus}
          />
        )}
      {helpText ? <small>{helpText}</small> : null}
      {icon ? <Image src={icon} /> : null}
      {error && <span className="help-block error text-danger">{error}</span>}
    </FormGroup>
  );
};

export default InputField;
