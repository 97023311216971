import { apiPost } from "../utils";
import { apiKey } from "../constants";

export function addMarkupPriceAPI(data) {
  let newdata = JSON.stringify(data);
  return apiPost(`/api/addmarkup?appKey=${apiKey}`, newdata);
}


export function sendAddedMarkupTicketAPI(data, isEmail) {
  let newdata = JSON.stringify(data);
  if(!isEmail){
    return apiPost(`/api/sendSMS?appKey=${apiKey}`, newdata);  
  }
  return apiPost(`/api/sendmarkup?appKey=${apiKey}`, newdata);
}
