import types from "../types";

const initialState = {
    fetching: false,
    featuredflights: [],
    featuredhotels: [],
    featuredoffers: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_FEATURED_FLIGHTS:
            return { ...state, fetching: true };
        case types.GET_FEATURED_FLIGHTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                featuredflights: action.payload.info
            };
        case types.GET_FEATURED_FLIGHTS_FAILED:
            return { ...state, fetching: false };

        case types.GET_FEATURED_HOTELS:
            return { ...state, fetching: true };
        case types.GET_FEATURED_HOTELS_SUCCESS:
            return {
                ...state,
                fetching: false,
                featuredhotels: action.payload.info
            };
            case types.GET_FEATURED_OFFERS:
                return { ...state, fetching: true };
            case types.GET_FEATURED_OFFERS_SUCCESS:
                return {
                    ...state,
                    fetching: false,
                    featuredoffers: action.payload.data
                };
        case types.GET_FEATURED_HOTELS_FAILED:
            return { ...state, fetching: false };


        default:
            return state;
    }
};
