export const GET_FARES_DATEWISE = "GET_FARES_DATEWISE";
export const GET_FARES_DATEWISE_SUCCESS = "GET_FARES_DATEWISE_SUCCESS";
export const GET_FARES_DATEWISE_FAILED = "GET_FARES_DATEWISE_FAILED";

//types used to determine whether price of flight has changed  or not
export const SET_FARE_QUOTA = "SET_FARE_QUOTA";
export const SET_FARE_QUOTA_SUCCESS = "SET_FARE_QUOTA_SUCCESS";
export const SET_FARE_QUOTA_FAILED = "SET_FARE_QUOTA_FAILED";

//this type is used for fetching SSR details of flight like meal , seats
export const SET_FLIGHT_SERVICE = "SET_FLIGHT_SERVICE";
export const SET_FLIGHT_SERVICE_SUCCESS = "SET_FLIGHT_SERVICE_SUCCESS";
export const SET_FLIGHT_SERVICE_FAILED = "SET_FLIGHT_SERVICE_FAILED";


//this type is used for flight Ticket Booking
export const SET_TICKET_DETAILS = "SET_TICKET_DETAILS";
export const SET_TICKET_DETAILS_SUCCESS = "SET_TICKET_DETAILS_SUCCESS";
export const SET_TICKET_DETAILS_FAILED = "SET_TICKET_DETAILS_FAILED";

//this type is used for flight Booking
export const SET_BOOKING = "SET_BOOKING";
export const SET_BOOKING_SUCCESS = "SET_BOOKING_SUCCESS";
export const SET_BOOKING_FAILED = "SET_BOOKING_FAILED";

//types used for ticket booking transaction 
export const MAKE_PAYMENT = 'MAKE_PAYMENT';
export const MAKE_PAYMENT_SUCCESS = 'MAKE_PAYMENT_SUCCESS'
export const MAKE_PAYMENT_FAILED = 'MAKE_PAYMENT_FAILED'

export const PAYMENT_SUCCESS_ORDER = 'PAYMENT_SUCCESS_ORDER'
export const PAYMENT_SUCCESS_ORDER_SUCCESS = 'PAYMENT_SUCCESS_ORDER_SUCCESS'
export const PAYMENT_SUCCESS_ORDER_FAILED = 'PAYMENT_SUCCESS_ORDER_FAILED'



//types used after payment successfull for new ticket 
export const TICKET_BOOKING_AFTER_PAYMENT = 'TICKET_BOOKING_AFTER_PAYMENT';
export const TICKET_BOOKING_AFTER_PAYMENT_SUCCESS = 'TICKET_BOOKING_AFTER_PAYMENT_SUCCESS'
export const TICKET_BOOKING_AFTER_PAYMENT_FAILED = 'TICKET_BOOKING_AFTER_PAYMENT_FAILED'

//types used to check TBO Balance
export const CHECK_TBO_BALANCE = 'CHECK_TBO_BALANCE';
export const CHECK_TBO_BALANCE_SUCCESS = 'CHECK_TBO_BALANCE_SUCCESS'
export const CHECK_TBO_BALANCE_FAILED = 'CHECK_TBO_BALANCE_FAILED'

//types used to submit flight details if TBO insuffient funds
export const INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS';
export const INSUFFICIENT_FUNDS_SUCCESS = 'INSUFFICIENT_FUNDS_SUCCESS'
export const INSUFFICIENT_FUNDS_FAILED = 'INSUFFICIENT_FUNDS_FAILED'

//--------------------------- offline payment request-----------------------
export const GET_OFFLINE_PAYMENT_AMOUNT = 'GET_OFFLINE_PAYMENT_AMOUNT';
export const GET_OFFLINE_PAYMENT_AMOUNT_SUCCESS = 'GET_OFFLINE_PAYMENT_AMOUNT_SUCCESS'
export const GET_OFFLINE_PAYMENT_AMOUNT_FAILED = 'GET_OFFLINE_PAYMENT_AMOUNT_FAILED'

export const GET_OFFLINE_PAYMENT_PORTAL = 'GET_OFFLINE_PAYMENT_PORTAL';
export const GET_OFFLINE_PAYMENT_PORTAL_SUCCESS = 'GET_OFFLINE_PAYMENT_PORTAL_SUCCESS'
export const GET_OFFLINE_PAYMENT_PORTAL_FAILED = 'GET_OFFLINE_PAYMENT_PORTAL_FAILED'

export const REQUEST_OFFLINE_PAYMENT = 'REQUEST_OFFLINE_PAYMENT';
export const REQUEST_OFFLINE_PAYMENT_SUCCESS = 'REQUEST_OFFLINE_PAYMENT_SUCCESS'
export const REQUEST_OFFLINE_PAYMENT_FAILED = 'REQUEST_OFFLINE_PAYMENT_FAILED'

//--------------------------------------------------------------------------