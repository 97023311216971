import React from "react";
import moment from "moment";

export const passengersOption = [
  {
    altitude: 777,
    city: "New Delhi",
    country: "India",
    iata: "DEL",
    icao: "VIDP",
    name: "Indira Gandhi International",
    source: "OurAirports",
    timezone: 5.5,
    value: "DEL"
  },
  {
    label: (
      <React.Fragment>
        <div>
          {"Bangalore"}, {"India"}{" "}
          <span style={{ float: "right" }}>{"BLR"}</span>
        </div>
        <span style={{ fontSize: 11 }}>
          {"Kempegowda International"}
        </span>
      </React.Fragment>
    ),
    altitude: 777,
    city: "Bangalore",
    country: "India",
    iata: "BLR",
    icao: "VOBL",
    name: "Kempegowda International",
    source: "OurAirports",
    timezone: 5.5,
    value: "BLR"
  },
  {
    altitude: 777,
    city: "Mumbai",
    country: "India",
    iata: "BOM",
    icao: "VABB",
    name: "Chhatrapati Shivaji International",
    source: "OurAirports",
    timezone: 5.5,
    value: "BOM"
  },
  {
    altitude: 777,
    city: "Kolkata",
    country: "India",
    iata: "CCU",
    icao: "VECC",
    name: "Netaji Subhash Chandra Bose",
    source: "OurAirports",
    timezone: 5.5,
    value: "CCU"
  }
];

export const passengersOptionDefault = [
  {
    label: (
      <React.Fragment>
        <strong className="city-name">{"Delhi"} </strong>
        <span className="des"> ({"DEL"}) </span>
      </React.Fragment>
    ),
    altitude: 777,
    city: "Delhi",
    country: "India",
    iata: "DEL",
    icao: "VIDP",
    name: "Indira Gandhi International Airport",
    source: "OurAirports",
    timezone: 5.5,
    value: "DEL"
  },
  {
    label: (
      <React.Fragment>
        <strong className="city-name">{"Bangalore"} </strong>
        <span className="des"> ({"BLR"}) </span>
      </React.Fragment>
    ),
    altitude: 777,
    city: "Bangalore",
    country: "India",
    iata: "BLR",
    icao: "VOBL",
    name: "Kempegowda International Airport",
    source: "OurAirports",
    timezone: 5.5,
    value: "BLR"
  }
];
export const classOption = [
  { value: "1", label: "Economy" },
  { value: "3", label: "PremiumEconomy" },
  { value: "4", label: "Business" },
  { value: "6", label: "FirstClass" },

];

export const statuses = ["One way", "Round trip", "Multi city"];
export const passengerTypes = [
  { id: 0, value: "Normal", label: "Normal", tooltipContent: "",ResultFareType:2 },
  {
    id: 1,
    value: "Armed Forces",
    label: "Armed Forces",
    tooltipContent:
      "Applicable if you are a war widow or serving or retired personnel or the spouse/ dependent child of the Armed Forces or Paramilitary Forces of India. Valid photo ID and relevant military ID is mandatory at the time of check-in. Passengers failing to provide relevant ID will be denied boarding."
  },
  {
    id: 2,
    value: "Student",
    label: "Student",
    ResultFareType:3,
    tooltipContent:
      "Applicable for students, above the age of 12 years. A valid student ID is mandatory at the time of check-in for verification. This discount entitles you to 6% off on base fare (for booking 7days in advance) and an extra baggage allowance of 10 kgs. Failure in producing relevant ID will result in charging prevailing fares."
  },
  {
    id: 3,
    value: "Senior Citizen",
    label: "Senior Citizen",
    ResultFareType:2,
    tooltipContent:
      "Applicable for senior citizens above the age of 60 years. Entitled for discount of 6% on base fare. Proof of Date of Birth is mandatory and needs to be presented at the time of check-in. Failure in producing proof will result in charging prevailing fares."
  },
  {
    id: 4,
    value: "Family & Friends",
    label: "Family & Friends",
    ResultFareType:2,
    tooltipContent:
      "Avail savings on Family Fare when booking for 4 to 9 passengers on the same PNR. Valid on select sectors. T&C apply. Not valid on some sectors."
  },
  {
    id: 5,
    value: "Minor",
    label: "Minor",
    ResultFareType:2,
    tooltipContent:
      "Applicable for children travelling alone between the age of 5 to 12 years. Bookings are subject to a fee of ₹1500/ ₹3000 (Domestic/International) per sector per child. Four copies of the pre-filled Unaccompanied Minor form, available on our website, is mandatory at the time of check-in."
  }
];

export const Months = [
  { value: "", label: "Months" },
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" }
];

export const cities = [
  { value: "Delhi", label: "Delhi" },
  { value: "Noida", label: "Noida" },
  { value: "Mumbai", label: "Mumbai" },
  { value: "Chandigarh", label: "Chandigarh" },
  { value: "Mohali", label: "Mohali" }
];

export const adults = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" }
];

export const childs = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" }
];


export const makeValidYearsList = () => {
  let startDate = moment().subtract(1, "year").year();
  let data = [{ value: "", label: "Years" }];
  for (let i = 1; i <= 24; i++) {
    data.push({ label: startDate + i, value: startDate + i });
  }
  return data;
};

export const convertStaticData = airports => {
  return airports.map((airport, i) => ({
    ...airport,
    label: (
      <div key={i} >
        <div style={{ lineHeight: "1rem" }} >
          <span style={{ fontSize: 14 }}>
            {" "}
            {airport.city}, {airport.country}
          </span>
          <span style={{ float: "right" }}>{airport.iata}</span>
        </div>
        <span style={{ fontSize: 12 }}>{airport.name}</span>
      </div>
    )
  }));
};

export const convertAirportOption = (airport) => {
  return {
    ...airport,
    label: (
      <React.Fragment>
        <strong className="city-name">{airport.value.city} </strong>
        <span className="des"> ({airport.value.iata}) </span>
      </React.Fragment>
    )
  };
};


export const facilitiesFilter = [
  {
    // activeImg: require("../../src/app/assets/homepage/resources/wifiselected.png"),
    // inactiveImg: require("../../src/app/assets/homepage/resources/wifiunselected.png"),
    label: "Wifi",
    value: "wifi"
  },
  {
    // activeImg: require("../../src/app/assets/homepage/resources/swimmingselected.png"),
    // inactiveImg: require("../../src/app/assets/homepage/resources/swimmingunselected.png"),
    label: "Pool",
    value: "pool"
  },
  {
    // activeImg: require("../../src/app/assets/homepage/resources/parkingselected.png"),
    // inactiveImg: require("../../src/app/assets/homepage/resources/parkingunselected.png"),
    label: "Parking",
    value: "parking"
  },
  {
    // activeImg: require("../../src/app/assets/homepage/resources/gymselected.png"),
    // inactiveImg: require("../../src/app/assets/homepage/resources/gymunselected.png"),
    label: "Gym",
    value: "gym"
  }
];

export const accomodationTypes = [
  {
    // activeImg: require("../../src/app/assets/homepage/resources/hotelsselected.png"),
    // inactiveImg: require("../../src/app/assets/homepage/resources/hotelsunselected.png"),
    label: "Hotels",
    value: "hotels"
  },
  {
    // activeImg: require("../../src/app/assets/homepage/resources/villaselected.png"),
    // inactiveImg: require("../../src/app/assets/homepage/resources/villaunselected.png"),
    label: "Villa",
    value: "villa"
  },
  {
    // activeImg: require("../../src/app/assets/homepage/resources/suiteselected.png"),
    // inactiveImg: require("../../src/app/assets/homepage/resources/suiteunselected.png"),
    label: "Suite",
    value: "suite"
  },
  {
    // activeImg: require("../../src/app/assets/homepage/resources/resortselected.png"),
    // inactiveImg: require("../../src/app/assets/homepage/resources/resortunselected.png"),
    label: "Resorts",
    value: "resorts"
  }
];



export const stopsFilter = [
  { index: 0, label: "0", value: "0" },
  { index: 1, label: "1", value: "1" },
  { index: 2, label: "2+", value: "2" }
];


export const TripTypes = [
  { value: "One way", label: "One Way" },
  { value: "Round trip", label: "Round trip" },
  { value: "Multi city", label: "Multi city" },
]


//static data to be used in register agent registration flow


export const businessTypes = [
  { value: "Sole Proprietor", label: "Sole Proprietor" },
  { value: "Partnership", label: "Partnership" },
  { value: "Joint Venture", label: "Joint Venture" },
  { value: "PVT LTD. CO.", label: "PVT LTD. CO." },
  { value: "HUF", label: "HUF" },
  { value: "Limited Liability Company", label: "Limited Liability Company(LLC)" },
]

export const secure_Mode = [
  { value: "", label: "Please select securitization mode" },
  { value: "Unsecure", label: "Unsecure" },
  { value: "Bank Guarantee", label: "Bank Guarantee" },
  { value: "Post Dated Cheque", label: "Post Dated Cheque" }
]

export const agency_Classification = [
  { value: "", label: "Please select agency classification" },
  { value: "Unregistered", label: "Unregistered" },
  { value: "Registered", label: "Registered" },
  { value: "AppliedFor", label: "AppliedFor" }
]

export const social_icons = [
  { className: "fab fa-facebook-f", link: " https://www.facebook.com/perfectdeparture2017" },
  { className: "fab fa-linkedin", link: "https://www.linkedin.com/in/perfect-departure/" },
  { className: "fab fa-instagram", link: "https://www.instagram.com/perfectdeparture/" },
  { className: "fab fa-twitter", link: "https://twitter.com/PerfectPvt" },
  { className: "fab fa-pinterest-p", link: "https://in.pinterest.com/PerfectDeparture/" },
]

export const our_products = [
  { title: "Flight Schedule", value: "flight-schedule", link: "" },
  { title: "Airline Routes", value: "airline-routes", link: "" },
  { title: "Flight Booking", value: "flight-booking", link: "" },
  { title: "Hotel Booking", value: "hotel-booking", link: "" },
  { title: "Tour Package", value: "tour-package", link: "" },
  { title: "Transport Land Arrangement", value: "transport-land-arrangement", link: "" },
  { title: "Cruise Booking", value: "cruise-booking", link: "" },
  { title: "Euro Rail Booking", value: "euro-rail-booking", link: "" },
  { title: "Cab Booking", value: "cab-booking", link: "" },
  { title: "Travel Insurance", value: "travel-insurance", link: "" },
  { title: "Visa Assistance", value: "visa-assistance", link: "" },
  { title: "Foreign Exchange", value: "foreign-exchange", link: "" },
  { title: "Bus Booking in India", value: "bus-booking", link: "" },
  { title: "Chartered Flight Booking in India", value: "chartered-flight", link: "" },
  { title: "Helicopter Booking in India", value: "helicopter-booking", link: "" },
  { title: "Passport Assistance in India", value: "passport-assistance", link: "" },
]


export const hotelStarRating = [
  { value: [0, 5], label: 'Show All' },
  { value: [0, 1], label: '1 star or less' },
  { value: [0, 2], label: '2 star or less' },
  { value: [0, 3], label: '3 star or less' },
  { value: [0, 4], label: '4 star or less' },
  { value: [0, 5], label: '5 star or less' },
  { value: [1, 5], label: '1 star or more' },
  { value: [2, 5], label: '2 star or more' },
  { value: [3, 5], label: '3 star or more' },
  { value: [4, 5], label: '4 star or more' },
  { value: [5, 5], label: '5 star or more' },
]