export const AUTHENTICATE = "AUTHENTICATE";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILED = "AUTH_FAILED";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_REQUEST_SUCCESS = "SIGNUP_REQUEST_SUCCESS";
export const SIGNUP_REQUEST_FAILED = "SIGNUP_REQUEST_FAILED";

export const CHECK_EMAIL_EXIST = "CHECK_EMAIL_EXIST";
export const CHECK_EMAIL_EXIST_SUCCESS = "CHECK_EMAIL_EXIST_SUCCESS";
export const CHECK_EMAIL_EXIST_FAILED = "CHECK_EMAIL_EXIST_FAILED";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";

export const EMAIL_VERIFY_LINK = "EMAIL_VERIFY_LINK";
export const EMAIL_VERIFY_LINK_SUCCESS = "EMAIL_VERIFY_LINK_SUCCESS";
export const EMAIL_VERIFY_LINK_FAILED = "EMAIL_VERIFY_LINK_FAILED";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";


export const SOCIAL_LOGIN = "SOCIAL_LOGIN";
export const SOCIAL_SUCCESS = "SOCIAL_SUCCESS";
export const SOCIAL_FAILED = "SOCIAL_FAILED";