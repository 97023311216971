import React from 'react';
import styled from 'styled-components';


const HotelWrapper = styled.div`


`

const Style = props => {

    const { className = "", children } = props || {};

    return (
        <HotelWrapper className={className}>
            {children}
        </HotelWrapper >
    )
};

export default Style;
