import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import FlightsCards from "../../routes/flightsearch/FlightsCards";
import { timeConvert, AgentKey, currencySign, isAgent, timeDifference } from "../../utils";
import moment from "moment"
import { connect } from 'react-redux';
import CancellationRules from './CancellationRules';

class AccordianDetails extends Component {
    render() {
        const { item = {}, autoFillObjectState = {}, name, traceId, international, isOneWay } = this.props;
   
        let { fetching = false } = this.props.getSearchResult;
        // let Amount = parseInt(item && item.Fare && item.Fare[AgentKey()]) +
        //     parseInt((isAgent() && item && item.Fare && item.Fare.pdc_processingfee) || 0)+
        //             parseInt((isAgent() && item && item.Fare && item.Fare.markup_price) || 0) 
                //   -  parseInt((isAgent() && item && !item.specialfare && item.Fare && item.Fare.pdc_commission) || 0)

                let Amount = parseInt(item && item.Fare && item.Fare.PublishedFare) +
                parseInt((isAgent() && item && item.Fare && item.Fare.pdc_processingfee) || 0)+
                        parseInt((isAgent() && item && item.Fare && item.Fare.markup_price) || 0) 
                    
                   
        return (
            <React.Fragment>
                <Tabs
                    defaultActiveKey="flight"
                    transition={false}
                    id="noanim-tab-example"
                >
                    <Tab eventKey="flight" title="Flight Details" unmountOnExit>
                        {
                            item && item.segments && item.segments.map((data, index) => {
                                // let layoverTime = 0;
                                // if (index > 0) {
                                //     let lastTime = moment(data.StopPointDepartureTime);
                                //     let firstTime = moment(item && item.segments[index - 1].StopPointArrivalTime)
                                //     layoverTime = (firstTime - lastTime) / 60000;
                                //     layoverTime = Math.abs(layoverTime);
                                // }
                                let hasSameLayoverPlane = false;
                                if (index > 0) {
                                    let lastSectorPlane = data.Airline.FlightNumber;
                                    let firstSectorPlane = item.segments[index - 1].Airline.FlightNumber
                                    hasSameLayoverPlane = lastSectorPlane === firstSectorPlane ? true : false;
                                }

                                let depTime = data && data.Origin.DepTime.split("T");
                                let arrTime = data && data.Destination.ArrTime.split("T");
                                let timeDiff = 0;

                                localStorage.setItem(`arrDep_${index}`, JSON.stringify({arr : arrTime, dep : depTime}));
                            
                                // If layover time(groundTime) is zero then get the time by date difference
                                if(index && !(data && data.GroundTime)){
                                    let prevArrDep = JSON.parse(localStorage.getItem(`arrDep_${index-1}`)); 
                                    let currArrDep = JSON.parse(localStorage.getItem(`arrDep_${index}`));
                                    timeDiff = timeDifference((prevArrDep.arr).join(' '), (currArrDep.dep).join(' '))
                                }

                                return <React.Fragment key={index}>
                                    {
                                        index > 0 ? <ul className="depart-custom">

                                            <li className="layover">
                                                <div>
                                                    <p>
                                                        <strong> {hasSameLayoverPlane ? null : "Change of Planes"} </strong>
                                                        {index > 0 ? timeConvert((data && data.GroundTime) ? (data && data.GroundTime) : timeDiff) : null}
                                                        {" "}
                                                        layover in
                                                         <strong>
                                                            {data.Origin.Airport.CityName}
                                                            ({data.Origin.Airport.CityCode})
                                                         </strong>
                                                    </p>
                                                </div>
                                            </li>
                                        </ul> : null
                                    }
                                    <ul className="depart-custom">
                                        <FlightsCards
                                            item={data}
                                            fulldata={item}
                                            type="accordian"
                                            showLastListItem={false}
                                        />
                                    </ul>
                                </React.Fragment>
                            })
                        }

                    </Tab>
                    <Tab eventKey="fare" title="Fare Details" unmountOnExit>
                        <ul className="sub-fare-tabs">
                            <li>
                                <h6>
                                    Base Fare (
                                    {
                                        `${autoFillObjectState && autoFillObjectState.adults}`
                                    }
                                    {autoFillObjectState && autoFillObjectState.adults == "1" ? " Adult" : " Adults"}
                                    {`${autoFillObjectState.children
                                        ? `, ${autoFillObjectState.children} Child`
                                        : ""}`
                                    }
                                    {
                                        `${autoFillObjectState.infants
                                            ? `, ${autoFillObjectState.infants} Infant`
                                            : ""
                                        }`
                                    }
                                    )
                                </h6>
                                <span>{currencySign(item && item.Fare.Currency_code)} {item && item.Fare.BaseFare}</span>
                            </li>
                            <li>
                                <h6>
                                    Taxes and Fees (
                                    {
                                        `${autoFillObjectState && autoFillObjectState.adults}`
                                    }
                                    {autoFillObjectState && autoFillObjectState.adults == "1" ? " Adult" : " Adults"}
                                    {`${autoFillObjectState.children
                                        ? `, ${autoFillObjectState.children} Child`
                                        : ""}`
                                    }
                                    {
                                        `${autoFillObjectState.infants
                                            ? `, ${autoFillObjectState.infants} Infant`
                                            : ""
                                        }`
                                    }
                                    )
                                </h6>
                                <span>
                                    {currencySign(item && item.Fare.Currency_code)} {parseInt(Amount - item.Fare.BaseFare)}
                                </span>
                            </li>
                            <li>
                                <h6>
                                    Total Fare (
                                    {
                                        `${autoFillObjectState && autoFillObjectState.adults}`
                                    }
                                    {autoFillObjectState && autoFillObjectState.adults == "1" ? " Adult" : " Adults"}
                                    {`${autoFillObjectState.children
                                        ? `, ${autoFillObjectState.children} Child`
                                        : ""}`
                                    }
                                    {
                                        `${autoFillObjectState.infants
                                            ? `, ${autoFillObjectState.infants} Infant`
                                            : ""
                                        }`
                                    }
                                    )
                                </h6>
                                <span>{currencySign(item && item.Fare.Currency_code)} {parseInt(Amount)}</span>
                            </li>
                        </ul>
                    </Tab>
                    <Tab eventKey="baggage" title="Baggage Details" unmountOnExit>
                        {
                            item && item.segments && item.segments.map((data, index) => {
                                return <React.Fragment key={index}>
                                    {
                                        index > 0 ? <div className="line">  </div> : null
                                    }
                                    <div>
                                        <strong>{data.Origin.Airport.CityName} - {data.Destination.Airport.CityName} ({data.Airline.AirlineCode}-{data.Airline.FlightNumber})</strong>
                                    </div>
                                    <div className="baggage-tabs">
                                        <ul>
                                            <li>
                                                <h6>Baggage</h6>
                                                <span>Adult</span>
                                            </li>
                                            <li>
                                                <h6>Check-In</h6>
                                                <span>{data.Baggage}</span>
                                            </li>
                                            <li>
                                                <h6>Cabin</h6>
                                                <span>{data.CabinBaggage}</span>
                                            </li>
                                        </ul>
                                        <p>{`The Baggage information is just for reference. Please check with airline before check-in. For more information, visit ${
                                            item.airline
                                            } airline website.`}</p>
                                        {
                                            (item.segments.length == (index + 1) && (data && data.Baggage && data.Baggage.includes("PC"))) ? <p>
                                                1PC* = 15 Kg
                                        </p> : null
                                        }
                                    </div>
                                </React.Fragment>
                            })
                        }

                    </Tab>
                    <Tab eventKey="cancellation" title="Cancellation & Modify Rule" unmountOnExit>

                        <div className="cancellation-fee ">
                            <CancellationRules
                                {...this.props}
                                traceId={traceId}
                                fetching={fetching}
                                international={international}
                                isOneWay={isOneWay}
                            />
                        </div>

                    </Tab>
                </Tabs>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    getSearchResult: state.getSearchResult,
});

export default connect(mapStateToProps)(AccordianDetails);

