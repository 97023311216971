import Cookies from "js-cookie";
import types from "../types";
import {
  loginAPI,
  signupAPI,
  checkEmailAddressExistAPI,
  forgotpasswordAPI,
  emailVerifyAPI,
  resetPasswordAPI,
  socialLoginAPI
} from "../api/auth";

export const login = payload => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.AUTH_LOGIN
    });
    loginAPI(payload)
      .then(res => {
        dispatch({
          type: types.AUTH_SUCCESS,
          payload: res
        });

        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.AUTH_FAILED
        });
        return reject(err);
      });
  });

export const signup = payload => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.SIGNUP_REQUEST
    });
    signupAPI(payload)
      .then(res => {
        dispatch({
          type: types.SIGNUP_REQUEST_SUCCESS
        });

        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.SIGNUP_REQUEST_FAILED
        });
        return reject(err);
      });
  });

//------------------------------------------------------------------------------------------

export const setCurrentUser = user => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: types.SET_CURRENT_USER,
      user
    });

    Cookies.set("mywebsite", user);

    dispatch({
      type: types.AUTHENTICATE,
      authenticated: true
    });

    resolve(user);
  });

export const establishCurrentUser = () => dispatch =>
  new Promise(resolve => {
    let userFromCookie = Cookies.getJSON("mywebsite");

    if (userFromCookie) {
      dispatch(setCurrentUser(userFromCookie));
      resolve(userFromCookie);
    } else {
      resolve({});
    }
  });

export const loginUser = (email, password) => dispatch =>
  new Promise((resolve, reject) => {
    const user = {
      email,
      password,
      name: "Awesome User"
    };

    dispatch(setCurrentUser(user));
    resolve(user);
  });

export const logoutUser = () => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: types.AUTHENTICATE,
      authenticated: false
    });

    dispatch({
      type: types.SET_CURRENT_USER,
      user: {}
    });

    Cookies.remove("mywebsite");
    resolve({});
  });

//action used for checking whether email already exist while sign up or not
export const checkEmailExists = payload => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.CHECK_EMAIL_EXIST
    });
    checkEmailAddressExistAPI(payload)
      .then(res => {
        dispatch({
          type: types.CHECK_EMAIL_EXIST_SUCCESS,
          payload: res
        });

        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.CHECK_EMAIL_EXIST_FAILED
        });
        return reject(err);
      });
  });

//action used for forgot password
export const forgotPassword = payload => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.FORGOT_PASSWORD
    });
    forgotpasswordAPI(payload)
      .then(res => {
        dispatch({
          type: types.FORGOT_PASSWORD_SUCCESS,
          payload: res
        });

        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.FORGOT_PASSWORD_FAILED
        });
        return reject(err);
      });
  });

//this action is used when user has successfully redirect from email verification link
export const emailVerify = payload => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.EMAIL_VERIFY_LINK
    });
    emailVerifyAPI(payload)
      .then(res => {
        dispatch({
          type: types.EMAIL_VERIFY_LINK_SUCCESS,
          payload: res
        });

        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.EMAIL_VERIFY_LINK_FAILED
        });
        return reject(err);
      });
  });

//action used for reset password
export const resetPassword = payload => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.RESET_PASSWORD
    });
    resetPasswordAPI(payload)
      .then(res => {
        dispatch({
          type: types.RESET_PASSWORD_SUCCESS,
          payload: res
        });

        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.RESET_PASSWORD_FAILED
        });
        reject(err);
      });
  });


export const socialLogin = payload => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.AUTH_LOGIN
    });
    socialLoginAPI(payload)
      .then(res => {
        dispatch({
          type: types.AUTH_SUCCESS,
          payload: res
        });

        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.AUTH_FAILED
        });
        return reject(err);
      });
  });
