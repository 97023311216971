import moment from "moment"


export const getDays = (start, end) => {

    let startDate = moment(moment(start).format("YYYY-MM-DD"), 'YYYY-MM-DD')
    let endDate = moment(moment(end).format("YYYY-MM-DD"), 'YYYY-MM-DD')

    return moment.duration(endDate.diff(startDate)).asDays();
}

export const handleHotelSearchQueryParams = (arr = []) => {
    let data = arr.map((item, index) => {
        const { NoOfAdults, NoOfChild, ChildAge: childage = [] } = item;
        let ChildAge = childage.map((data, i) => {
            return `${data || 0}${i + 1 === childage.length ? "" : "-"}`
        })
        return `${NoOfAdults}:${NoOfChild}:${ChildAge}${index + 1 === arr.length ? "" : "_"}`
    })
    return data.toString().replace(/,/g, "")
}

export const convertParamsToStateObject = (parseData) => {
    let rating = parseData.rating.split(",")
    const updatedData = {
        "CheckInDate": parseData.CheckInDate,
        "NoOfNights": parseInt(parseData.NoOfNights) || 1,
        "CountryCode": parseData.CountryCode,
        "CityId": parseData.CityId,
        "ResultCount": null,
        "PreferredCurrency": "INR",
        "GuestNationality": parseData.nationality || "IN",
        "NoOfRooms": parseInt(parseData.NoOfRooms) || 1,
        "RoomGuests": [...convertInternaryToArray(parseData && parseData.RoomGuests)],
        "PreferredHotel": "",
        "MaxRating": Number(rating[1]) || 5,
        "MinRating": Number(rating[0]) || 0,
        "ReviewScore": null,
        "IsNearBySearchAllowed": false,
    }
    return { ...updatedData };
}

export const convertInternaryToArray = (iternary) => {
    const arr = iternary.split("_")
    let updatedData = arr.map((item, index) => {
        const data = item && item.split(":")
        let childage = (data[2] && data[2].split("-") && data[2].split("-").map((item) => {
            return parseInt(item)
        })) || []
        return {
            "NoOfAdults": parseInt(data[0]),
            "NoOfChild": parseInt(data[1]),
            "ChildAge": childage,
        }
    })

    return [...updatedData]
}

export const calculateHotelsPrice = (array = []) => {
    let maxValue = 0;
    let minValue = 0;
    if (array && array.length) {
        let prices = array && array.length > 0 && array.map((item) => item.Price.PublishedPrice);

        maxValue = Math.max(...prices);
        minValue = Math.min(...prices);

        return { maxValue, minValue };
    }
    return {};
}
