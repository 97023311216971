import types from "../types";

const initialState = {
    fetching: false,
    addFetching: false,
    wallet: {},
    offlineFetching: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_WALLET_MONEY:
            return { ...state, addFetching: true };
        case types.ADD_WALLET_MONEY_SUCCESS:
            return {
                ...state,
                addFetching: false,
                // featuredflights: action.payload.info
            };
        case types.ADD_WALLET_MONEY_FAILED:
            return { ...state, addFetching: false };


        case types.ADDED_WALLET_MONEY:
            return { ...state, fetching: true };
        case types.ADDED_WALLET_MONEY_SUCCESS:
            return {
                ...state,
                fetching: false,
                // featuredflights: action.payload.info
            };
        case types.ADDED_WALLET_MONEY_FAILED:
            return { ...state, fetching: false };


        case types.GET_WALLET_DETAILS:
            return { ...state, fetching: true };
        case types.GET_WALLET_DETAILS_SUCCESS:

            return {
                ...state,
                fetching: false,
                wallet: action.payload
            };
        case types.GET_WALLET_DETAILS_FAILED:
            return { ...state, fetching: false };

        //Payment Mode Bank Details
        case types.GET_PAYMENTMODE_OFFLINE_PAYMENT:
            return { ...state, offlineFetching: true };
        case types.GET_PAYMENTMODE_OFFLINE_PAYMENT_SUCCESS:

            return { ...state, offlineFetching: false };
        case types.GET_PAYMENTMODE_OFFLINE_PAYMENT_FAILED:
            return { ...state, offlineFetching: false };



        //when user logouts
        case types.LOGOUT_USER_WALLET: {
            return { ...state, wallet: {} }
        }

        default:
            return state;
    }
};
