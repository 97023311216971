export const REGISTER_BUSINESS = "REGISTER_BUSINESS";
export const REGISTER_BUSINESS_SUCCESS = "REGISTER_BUSINESS_SUCCESS";
export const REGISTER_BUSINESS_FAILED = "REGISTER_BUSINESS_FAILED";


export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_STATES = 'GET_STATES';
export const GET_CITIES = 'GET_CITIES';

export const CLEAR_STATES = 'CLEAR_STATES';
export const CLEAR_CITIES = 'CLEAR_CITIES';

export const VALIDATE_EMAIL_MOBILE = "VALIDATE_EMAIL_MOBILE";
export const VALIDATE_EMAIL_MOBILE_SUCCESS = "VALIDATE_EMAIL_MOBILE_SUCCESS";
export const VALIDATE_EMAIL_MOBILE_FAILED = "VALIDATE_EMAIL_MOBILE_FAILED";


