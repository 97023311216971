import {  apiGet } from "../utils";
import { apiKey } from "../constants";

export function getFeaturedFlightsAPI() {
  return apiGet(`/api/featuredflights?appKey=${apiKey}`
  );
}

export function getFeaturedHotelsAPI() {
  return apiGet(`/api/featuredhotels?appKey=${apiKey}`
  );
}

export function getFeaturedOffersAPI() {
  return apiGet(`/api/featuredoffers?appKey=${apiKey}`
  );
}
