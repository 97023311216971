import types from "../types";

const initialState = {
    fetching: false,
    allCoupons: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_COUPONS:
            return { ...state, fetching: true };
        case types.GET_COUPONS_SUCCESS:
            return {
                ...state,
                fetching: false,
                allCoupons: action.payload.info.map(item => {
                    return { ...item, label: item.promocode }
                })
            };
        case types.GET_COUPONS_FAILED:
            return { ...state, fetching: false };

        default:
            return state;
    }
};
