import React, { Component } from 'react';
import { SelectDropdown } from "../../../../";
import { countArray } from "../../../../../utils";
import Style from "./Style";

class RoomsCounter extends Component {
    state = {
        open: this.props.openFirstAccordian || false
    }

    Toggle = () => {
        this.setState({
            open: !this.state.open
        })
    }

    render() {
        const { data, index, delRooms, adults, childs, onChangeAdultsCount, onChangeChildCount, onChangeChildAge } = this.props;
        const { open } = this.state;
        return (
            <React.Fragment>
                <Style>
                    <li className="hotel-passenger-count selectdiv position-relative">

                        <h6 onClick={() => this.Toggle()}>ROOM{index + 1}</h6>
                        {open ? <ul className="d-flex justify-content-between my-2 Collapse">
                            <li className="d-flex align-items-center">Adults:

                                    <div className="ml-2 selectdiv">
                                    <SelectDropdown
                                        index={index}
                                        value={data.NoOfAdults}
                                        onChange={(e) => onChangeAdultsCount(e, index)}
                                        name="childCount"
                                        options={adults}
                                    />
                                </div>

                            </li >

                            <li className="d-flex align-items-center justify-content-end">
                                Child:

                                <div className="ml-2 selectdiv">
                                    <SelectDropdown
                                        index={index}
                                        value={data.NoOfChild}
                                        onChange={(e) => onChangeChildCount(e, index)}
                                        name="childCount"
                                        options={childs}
                                    />
                                </div>

                            </li >

                        </ul> : null}
                        <ul className="d-flex child-age-list">
                            {
                                open && data && data.ChildAge && data.ChildAge.length ? data.ChildAge.map((val, i) => {
                                    return (
                                        <li key={i} >

                                            Child {i + 1} Age :
                                                <div className="selectdiv">
                                                <SelectDropdown
                                                    index={i}
                                                    value={val}
                                                    name="childAge"
                                                    options={countArray(12, 1)}
                                                    onChange={(e) => onChangeChildAge(e, i, index)}
                                                />
                                            </div>
                                        </li>
                                    )
                                }) : null
                            }
                        </ul>
                        {
                            index > 0 ?
                                <button type="button" onClick={(index) => delRooms(index)} className="close-btn room-close-btn "><i className="fa fa-times" aria-hidden="true"></i></button>
                                : null
                        }

                    </li>
                </Style>
            </React.Fragment>
        );
    }
}

export default RoomsCounter;

