import { apiGet, apiPut, apiPost } from "../utils";
import { apiKey } from "../constants";

export function getUserProfileAPI(token) {
  return apiGet(`/api/viewprofile?appKey=${apiKey}&token=${token}`);
}


export function updatedUserProfileAPI(data, token) {
  let updatedData = JSON.stringify(data)
  return apiPut(`/api/updatecustomerprofile?appKey=${apiKey}&token=${token}`, updatedData);
}


export function addAgentLogoAPI(data, token) {
  let newdata = new FormData();
  newdata.append("image", data.logo)
  return apiPost(`/api/assets?appKey=${apiKey}&token=${token}`, newdata);
}
