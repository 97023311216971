export const GET_SEARCH_OBJECT = "GET_SEARCH_OBJECT";

export const GET_FLIGHT_SEARCH_RESULT = "GET_FLIGHT_SEARCH_RESULT";
export const GET_FLIGHT_SEARCH_RESULT_SUCCESS = "GET_FLIGHT_SEARCH_RESULT_SUCCESS";
export const GET_FLIGHT_SEARCH_RESULT_FAILED = "GET_FLIGHT_SEARCH_RESULT_FAILED";

export const GET_FLIGHT_FARE_RULE = "GET_FLIGHT_FARE_RUL"
export const GET_FLIGHT_FARE_RULE_SUCCESS = "GET_FLIGHT_FARE_RULE_SUCCESS";
export const GET_FLIGHT_FARE_RULE_FAILED = "GET_FLIGHT_FARE_RULE_FAILED";

export const SET_COMPLETE_STATE = "SET_COMPLETE_STATE";
