import React, { useState } from "react";
import classnames from "classnames";
import { Collapse } from "react-bootstrap";
import { AccordianDetails } from "../../components";
import FlightsCards from "./FlightsCards";


const MoreFareFlightDetails = (props) => {
    const [openFlightDetails, setFlightDetails] = useState(false);

    const toggleDetails = (key, e) => {
        e.stopPropagation();
        setFlightDetails(!openFlightDetails)
    }

    const {
        customClassName,
        onwardSelectedCard,
        returnFlightsLength,
        item,
        index,
        onChangeSelectedCard,
        name,
        oopenFlightDetailspen,
        bookNow,
        showOfferedPrices,
        airlineSpecial,
        isShowFare,
        showLastListItem,

        autoFillObjectState,
        traceId,
        international,
        isOneWay
    } = props;

    return (
        <>
            <ul
                className={customClassName}
            >
                <FlightsCards
                    onwardSelectedCard={onwardSelectedCard}
                    returnFlightsLength={returnFlightsLength}
                    item={item}
                    index={index}
                    onChangeSelectedCard={onChangeSelectedCard}
                    name={name}
                    toggleDetails={toggleDetails}
                    oopenFlightDetailspen={openFlightDetails}
                    showLastListItem={showLastListItem}
                    bookNow={bookNow}
                    showOfferedPrices={showOfferedPrices}
                    airlineSpecial={airlineSpecial}
                    isShowFare={isShowFare}
                />
            </ul>

            {
                openFlightDetails ? (
                    <div className={classnames('flight-details-collapse', { "active": onwardSelectedCard === item.id })}>
                        <div className="collapse-custom">
                            <Collapse in={openFlightDetails}>
                                <div id="example-collapse-text">
                                    <AccordianDetails
                                        {...props}
                                        item={item}
                                        type="simple"
                                        name={name}
                                        autoFillObjectState={autoFillObjectState}
                                        traceId={traceId}
                                        international={international}
                                        isOneWay={isOneWay}
                                    />
                                </div>
                            </Collapse>
                        </div>
                    </div>
                ) : null
            }
        </>
    )
}

export default MoreFareFlightDetails