//File Name: Cabs.js
//Path: src/app/coomponents/homepage
//Description: This file is used for Cabs searchs.
import React, { Component } from "react";
import Select from "react-select";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController
} from "react-dates";

const cities = [
  { value: "Delhi", label: "Delhi" },
  { value: "Noida", label: "Noida" },
  { value: "Mumbai", label: "Mumbai" },
  { value: "Chandigarh", label: "Chandigarh" },
  { value: "Mohali", label: "Mohali" }
];

const time = [{ value: "there is no Time", label: "No Time Available" }];
const statuses = ["oneway", "roundtrip"];

class Cabs extends Component {
  state = {
    status: "oneway",
    cabs: {
      from: null,
      to: null,
      depart: null,
      return: null,
      pickup: null
    }
  };

  //Function Name: handleChange
  //Parameters: e,val
  //Description: This function is used to change Cabs searches.
  handleChange = (e, val) => {
    this.setState({
      cabs: {
        ...this.state.cabs,
        [val]: e.value
      }
    });
  };

  //Function Name: onChangeDate
  //Parameters: start, end
  //Description: This function is used to change Cabs depart and return.
  onChangeDate = (start, end) => {
    this.setState({
      cabs: {
        ...this.state.cabs,
        depart: start,
        return: end
      }
    });
  };

  //Function Name: onChangeRadioButton
  //Parameters: status
  //Description: This function is used to change Cabs oneway,roundtrip.
  onChangeRadioButton = status => {
    this.setState({ status });
  };

  render() {
    const { cabs, status } = this.state;
    const isRoundTrip = this.state.status === "oneway";
    return (
      <Container>
        <Row>
          <Col style={{ paddingTop: 20, paddingBottom: 20 }}>
            <Row>
              <Col xs={3} sm={3} md={3}>
                <h5> Booking Options</h5>
              </Col>
              <Col xs={9} sm={9} md={9}>
                <Form>
                  {statuses.map((statusLabel,index) => {
                    return (
                      <React.Fragment key={index}>
                        <label className="checkbox-custom">
                          <Form.Check
                            id={statusLabel}
                            type="radio"
                            name="status"
                            checked={statusLabel === status}
                            onChange={() =>
                              this.onChangeRadioButton(statusLabel)
                            }
                            inline
                          />
                          {statusLabel}
                        </label>
                      </React.Fragment>
                    );
                  })}
                </Form>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12}>
            <form>
              <Row>
                <Col xs={2} sm={2} md={2}>
                  <span style={{ color: "#ed3338" }}>From</span>
                  <Select
                    value={cities.find(i => i.value === cabs.from)}
                    onChange={e => this.handleChange(e, "from")}
                    options={cities}
                  />
                </Col>
                <Col xs={2} sm={2} md={2}>
                  <span style={{ color: "#ed3338" }}>To</span>
                  <Select
                    value={cities.find(i => i.value === cabs.to)}
                    onChange={e => this.handleChange(e, "to")}
                    options={cities}
                  />
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} className="">
                  Depart <span> Return</span>
                  <DateRangePicker
                    hideKeyboardShortcutsPanel={true}
                    startDate={cabs.depart}
                    startDateId=""
                    endDate={cabs.return}
                    endDateId=""
                    onDatesChange={({ startDate, endDate }) =>
                      this.onChangeDate(startDate, endDate)
                    }
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput =>
                      this.setState({ focusedInput })
                    }
                    disabled={isRoundTrip ? "endDate" : false}
                  />
                </Col>
                <Col xs={2} sm={2} md={2}>
                  <span style={{ color: "#ed3338" }}>Pick-Up Time</span>
                  <Select
                    value={time.find(i => i.value === cabs.pickup)}
                    onChange={e => this.handleChange(e, "pickup")}
                    options={time}
                  />
                </Col>
                <Col
                  xs={2}
                  sm={2}
                  md={2}
                  style={{ paddingTop: 25, paddingBottom: 25 }}
                >
                  <Button
                    style={{
                      backgroundColor: "#ed3338",
                      border: "none"
                    }}
                  >
                    SEARCH
                  </Button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Cabs;
