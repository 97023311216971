//File Name: Hotels.js
//Path: src/app/coomponents/homepage
//Description: This file is used for Hotels searchs.
import React, { Component } from "react";
import Select, { Async } from 'react-select';
import { Row, Col, Button, Popover, Form } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import { adults, childs, hotelStarRating } from "../../../../staticData";
import moment from "moment"

// import { countArray } from "../../../../utils";
import { ThemeButton } from "../../../";
import RoomsCounter from "./RoomCounter"
import { ThemeColor, Nationality } from "../../../../constants";
import { getDays, handleHotelSearchQueryParams } from "../../../../utilsHotels";
import actions from "../../../../actions";
import { connect } from "react-redux";
import InputField from "../../../common/InputField";
import Style from "./Style";

const initialData = {
  "NoOfAdults": 1,
  "NoOfChild": 0,
  "ChildAge": []
}

class Hotels extends Component {
  state = {
    hotels: {
      //city: "Delhi",
      check_in: moment(),
      check_out: moment().add(1, "day"),
      searchNationality: '',
      selectedCountryCode: "",
      searchCity: '',
      NoOfNights: 1,
      starRating: [0, 5]
    },
    RoomGuests: [{ ...initialData }],
    allCount: {
      allPassengers: 1,
      allRooms: 1
    },
    focusedInput: null,
    passengerShow: false,
    getHotelCountriesApiReqPara: {
      search: ''
    }
  };

  // Description: This function is used to fetch country list after render the dom
  // componentDidMount() {
  //   let { getHotelCountriesApiReqPara } = this.state
  //   this.props.dispatch(actions.getHotelCountries(getHotelCountriesApiReqPara))
  // }

  //Function Name: handleChange
  //Parameters: e,val
  //Description: This function is used to change Hotels searches.
  // handleChange = (e, val) => {
  //   this.setState({
  //     hotels: {
  //       ...this.state.hotels,
  //       [val]: e.value
  //     }
  //   });
  // };

  //Description: This function is used to search and select country Name
  //  onChangeSearchCountry = (search) => {
  //   this.setState({
  //     searchState:search.value
  //   },()=>this.onCity(this.state.searchState))
  // }
  onChangeCountry = (search) => {
    const { hotels } = this.state;
    this.setState({
      hotels: {
        ...hotels,
        selectedCountryCode: search.value
      }
    }, () => this.cityListHitApi(search.value))
  }

  cityListHitApi = (selectedCountryCode) => {
    this.props.dispatch(actions.getHotelDestinationCityList(selectedCountryCode))
  }

  filterValueCountry = (search, callback) => {
    let { getStaticInfo } = this.props
    let { countryList } = getStaticInfo
    let filterValue = countryList && countryList.length ? countryList.filter(i => i && i.label && i.label.toLowerCase().includes(search.toLowerCase())) : []
    callback(filterValue)
  }

  loadOptionsCountry = (search, callback) => {
    setTimeout(() => {
      this.filterValueCountry(search, callback)
    }, 800);
  }

  // Description : this fucntion is used for search and select star Rating of hotel
  // onChangeStarRating = (value) => {
  //   let { hotels } = this.state;

  //   this.setState({
  //     hotels: {
  //       ...hotels,
  //       starRating: value
  //     }
  //   })
  // }

  // filterValueHotelStarRating = (search, callback) => {
  //   let filterValue = hotelStarRating && hotelStarRating.length ? hotelStarRating.filter(i => i && i.label && i.label.toLowerCase().includes(search.toLowerCase())) : []
  //   callback(filterValue)
  // }

  // Description : this function  is used search and selectcity
  // onChangeCity = (search) => {
  //   let { hotels } = this.state
  //   this.setState({
  //     hotels: {
  //       ...hotels,
  //       searchCity: search.value
  //     }
  //   })
  // }

  filterValueCity = (search, callback) => {
    let { Hotel } = this.props
    let { cityList } = Hotel
    let filterValue = cityList && cityList.length ? cityList.filter(i => i && i.label && i.label.toLowerCase().includes(search.toLowerCase())) : []
    callback(filterValue)
  }
  loadOptionsCity = (search, callback) => {
    setTimeout(() => {
      this.filterValueCity(search, callback)
    }, 1000);
  }


  //  Description : this function  is used search and select Nationality
  onChangeCommon = (value, key) => {
    let { hotels } = this.state
    this.setState({
      hotels: {
        ...hotels,
        [key]: value
      }
    })
  }

  filterValueNationality = (search, callback) => {
    let filterValue = Nationality && Nationality.length ? Nationality.filter(i => i && i.label && i.label.toLowerCase().includes(search.toLowerCase())) : []
    callback(filterValue)
  }
  loadOptionsNationality = (search, callback) => {
    setTimeout(() => {
      this.filterValueNationality(search, callback)
    }, 1000);
  }

  //Function Name: onChangeDate
  //Parameters: start, end
  //Description: This function is used to change Hotels CheckIn and CheckOut.
  onChangeDate = (check_in, check_out) => {
    let differenceNight = getDays(check_in, check_out) || 1;
    this.setState({
      hotels: {
        ...this.state.hotels,
        check_in,
        check_out,
        NoOfNights: differenceNight
      },
    });
  };

  //Function Name: handleShowPassenger
  //Parameters: handleShowPassenger
  //Description: This function is used to show passenger popover.
  handleShowPassenger = passengerShow => {
    this.setState({ passengerShow: !this.state.passengerShow }, () => this.countPassengers());
  };

  countPassengers = () => {
    const { passengerShow, RoomGuests, allCount } = this.state;
    if (!passengerShow) {
      let Count = RoomGuests.reduce((acc, current) => (
        {
          "NoOfAdults": parseInt(acc.NoOfAdults) + parseInt(current.NoOfAdults),
          "NoOfChild": parseInt(acc.NoOfChild) + parseInt(current.NoOfChild)
        }
      ))

      this.setState({
        allCount: {
          allPassengers: Count.NoOfAdults + Count.NoOfChild,
          allRooms: RoomGuests.length
        }
      })
    }
  }

  //Function Name: addRooms
  //Parameters: null
  //Description: This function is used to add Rooms .
  addRooms = () => {
    const { RoomGuests } = this.state;
    let updatedData = [...RoomGuests]
    updatedData.push(initialData);
    this.setState({
      RoomGuests: updatedData
    })
  }

  //Function Name: delRooms
  //Parameters: key
  //Description: This function is used to delete rooms.
  delRooms = (key) => {
    const { RoomGuests } = this.state;
    let updatedData = [...RoomGuests]
    updatedData.splice(key, 1)
    this.setState({
      RoomGuests: updatedData
    })
  }

  onChangeAdultsCount = (e, index) => {
    let { name, value } = e.target;
    const { RoomGuests } = this.state;
    let updated = [...RoomGuests]
    let updatedData = { ...updated[index] }
    updatedData.NoOfAdults = parseInt(value)
    updated[index] = updatedData

    this.setState({
      RoomGuests: updated
    })
  }

  onChangeChildCount = (e, index) => {
    let { name, value } = e.target;
    const { RoomGuests } = this.state;
    let updated = [...RoomGuests]
    let updatedData = { ...updated[index] }
    updatedData.NoOfChild = parseInt(value)
    updatedData.ChildAge = Array(parseInt(value)).fill(1);
    updated[index] = updatedData

    this.setState({
      RoomGuests: updated
    })
  }

  onChangeChildAge = (e, index, parentIndex) => {
    let { name, value } = e.target;
    const { RoomGuests } = this.state;
    let updated = [...RoomGuests]
    let updatedData = { ...updated[parentIndex] }
    updatedData.ChildAge[index] = parseInt(value);
    updated[parentIndex] = updatedData
    this.setState({
      RoomGuests: updated
    })

  }

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput }, () => this.checkDates(focusedInput))
  }

  checkDates = (focusedInput) => {
    const { check_in, check_out } = this.state.hotels;
    if (focusedInput == null && check_out == null) {
      this.onChangeDate(check_in, moment(check_in).add(1, "day"))
      return
    }
    return
  }


  // Description this function is used select noOfNights
  onNightChange = (e) => {
    const { value } = e.target;
    if (value < 1) return

    let { check_in } = this.state.hotels
    this.setState({
      hotels: {
        ...this.state.hotels,
        NoOfNights: Number(value),
        check_out: moment(check_in).add(value, "day")
      }
    })
  }

  //Function Name: onRedirectToSearchPage
  //Parameters: null
  //Description: This function is used to search flights search page .
  onRedirectToSearchPage = () => {
    let { hotels, RoomGuests, allCount } = this.state;
    let { searchCity, check_in, check_out, selectedCountryCode, starRating, searchNationality } = hotels

    let data = handleHotelSearchQueryParams(RoomGuests);

    let obj = {
      CheckInDate: moment(hotels.check_in).format("DD/MM/YYYY"),
      NoOfNights: getDays(hotels.check_in, hotels.check_out) || 1,
      CountryCode: "IN",
      CityId: "130443",
      ResultCount: null,
      PreferredCurrency: "INR",
      GuestNationality: "IN",
      NoOfRooms: RoomGuests.length || 1,
      RoomGuests: RoomGuests || [],
      PreferredHotel: "",
      MaxRating: starRating[1] || 5,
      MinRating: starRating[0] || 0,
      ReviewScore: null,
      IsNearBySearchAllowed: false,
      //allPassengers: allCount.allPassengers,
      EndUserIp: "192.168.10.130",
    }

    // return 
    this.props.history.push(`/hotel/search?CheckInDate=${moment(check_in).format("DD/MM/YYYY")}&CheckOutDate=${moment(check_out).format("DD/MM/YYYY")}&NoOfNights=${hotels.NoOfNights}&NoOfRooms=${RoomGuests.length || 1}&RoomGuests=${data}&CityName=${searchCity.label}&CityId=${searchCity.value}&CountryCode=${selectedCountryCode}&allPassengers=${allCount && allCount.allPassengers}&rating=${starRating || [0, 5]}&nationality=${searchNationality}`)
  };

  render() {
    const { hotels, passengerShow, RoomGuests, allCount, } = this.state;
    const { Hotel, getStaticInfo } = this.props
    const { cityList } = Hotel
    let { countryList } = getStaticInfo
    return (
      <Style>
        <Col xs={12} sm={12} md={12} className="form-booking hotel-form-search ">
          <form>
            <Row>
              <Col xs={12} sm={12} md={6} className="from-group-custom" >
                <label>Country</label>
                <Async
                  cacheOptions
                  loadOptions={this.loadOptionsCountry}
                  defaultOptions={countryList || []}
                  onChange={this.onChangeCountry}
                />
              </Col>
              <Col xs={12} sm={12} md={6} className="from-group-custom" >
                <label>CITY</label>
                <Async
                  cacheOptions
                  loadOptions={this.loadOptionsCity}
                  defaultOptions={cityList || []}
                  onChange={(e) => this.onChangeCommon(e, "searchCity")}
                />
              </Col>
              <Col xs={12} sm={12} md={12} className="from-group-custom datepicker mt-3">
                <label>
                  CHECK IN <span className="return-flight"> CHECK OUT</span>
                </label>
                <DateRangePicker
                  hideKeyboardShortcutsPanel={true}
                  startDate={hotels.check_in}
                  startDateId=""
                  displayFormat="DD/MM/YYYY"
                  endDate={hotels.check_out}
                  endDateId=""
                  onDatesChange={({ startDate, endDate }) =>
                    this.onChangeDate(startDate, endDate)
                  }
                  focusedInput={this.state.focusedInput}
                  onFocusChange={focusedInput => this.onFocusChange(focusedInput)}
                />
              </Col>

              {/* <Col xs={12} sm={6} md={6} className="from-group-custom">
              <label>City/Hotel/Area</label>
              <Select
                // value={}
                onChange={e => this.handleChange(e, "city")}
                options={[{label:"test"}]}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: ThemeColor.primary,
                  }
                })}
              />
            </Col> */}
              <Col sm={6} xl={6} md={6} className="from-group-custom">

                <InputField
                  label="Nights"
                  type="number"
                  placeholder="nights"
                  value={hotels.NoOfNights}
                  onChange={this.onNightChange}
                />
                {/* <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Nights</Form.Label>
                <Form.Control type="number" placeholder="nights" value={hotels.NoOfNights} onChange={this.onNightChange} />
              </Form.Group> */}
              </Col>
              <Col xs={12} sm={6} md={6} className="from-group-custom">
                <label>Rooms & Guests</label>
                <div className="no-passengers" onClick={this.handleShowPassenger}>
                  {allCount.allRooms} Rooms {allCount.allPassengers} Guests
              </div>
                {passengerShow ? (
                  <React.Fragment>
                    <Popover placement="bottom" className="fullwidth" id="popover-passenger">
                      <div className="counter-container">
                        <ul className=" clearfix list-unstyled p-0">
                          {
                            RoomGuests.map((data, index) => {
                              return (<React.Fragment key={index}>
                                <RoomsCounter
                                  data={data}
                                  index={index}
                                  adults={adults}
                                  childs={childs}
                                  openFirstAccordian={index == 0 ? true : false}
                                  delRooms={this.delRooms}
                                  onChangeAdultsCount={this.onChangeAdultsCount}
                                  onChangeChildCount={this.onChangeChildCount}
                                  onChangeChildAge={this.onChangeChildAge}
                                />
                              </React.Fragment>)
                            })
                          }
                          {
                            RoomGuests && RoomGuests.length < 6 ? <Button className="add-more" onClick={this.addRooms}>Add Another Room</Button> : null
                          }

                        </ul>
                      </div>
                    </Popover>
                    <div
                      onClick={this.handleShowPassenger}
                      className="popover-overlay"
                    />
                  </React.Fragment>
                ) : null}
              </Col>
              <Col xs={12} sm={12} md={6} className="from-group-custom" >
                <label>Star Rating</label>
                <Select
                  isSearchable={false}
                  value={hotelStarRating.find(
                    i => i.value.toString() === hotels.starRating.toString()
                  )}
                  onChange={e => this.onChangeCommon(e.value, "starRating")}
                  options={hotelStarRating}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: ThemeColor.primary,
                    }
                  })}
                  defaultOptions={hotelStarRating || []}
                />
              </Col>
              <Col xs={12} sm={12} md={6} className="from-group-custom" >
                <label>Nationality</label>

                <Async
                  cacheOptions
                  loadOptions={this.loadOptionsNationality}
                  defaultOptions={Nationality || []}
                  onChange={(e) => this.onChangeCommon(e.value, "searchNationality")}
                />

                {/* <Select
                  isSearchable={false}
                  onChange={(e) => this.onChangeCommon(e.value, "searchNationality")}
                  options={Nationality}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: ThemeColor.primary,
                    }
                  })}
                // value={selectedCity || {}}
                /> */}
              </Col>

              <Col xs={12} sm={12} md={12} className="btn-booking-custom text-center">
                <ThemeButton text={"Search"} className="btn-green" color="primary" onClick={this.onRedirectToSearchPage} />
              </Col>
            </Row>
          </form>
        </Col>
      </Style>
    );
  }
}

const mapStateToProps = state => {
  return {
    Hotel: state.hotels,
    getStaticInfo: state.getStaticInfo
  }
}

export default connect(mapStateToProps)(Hotels);



