//File Name: FeaturedFlights.js
//Path: src/app/coomponents/homepage
//Description: This file is used for Featuredflights searches.
import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContentLoaderCards from "../common/ContentLoaderCards";
import { currencySign } from "../../utils";

class FeaturedFlights extends Component {
  state = {};
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      cssEase: "linear",
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    const { featuredflights, fetching = false } = this.props;
    return (
      <div className="featured-flights">
        <h2 align="center" className="sub-title">
          FEATURED <span className="custom-label">FLIGHTS</span>
        </h2>
        {fetching ? (
          <ContentLoaderCards flights="flights" />
        ) : (
            <div className="slider-custom">
              <Slider {...settings}>
                {featuredflights.map((index, key) => {
                  return (
                    <div className="silder-list-view" key={key}>
                      <div className="silder-img">
                        <img src={index.flight_logo} />
                      </div>
                      <div className="ticket-air">
                        <h6>{index.flight_name}</h6>
                        {/* <h5>
                          {currencySign()} {index.flight_price}
                        </h5> */}
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          )}
      </div>
    );
  }
}

export default FeaturedFlights;
