import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { Route, Redirect } from "react-router-dom";
import { Header, TopNotificationBar, Sidebar } from "../../components";
import { Container, Col, Row } from "react-bootstrap";
// import { isLoggedIn } from "../../utils";
import { FooterFlightSearch } from '../../components';
import Footer from "../common/Footer";

const UnauthenticatedRoute = ({ component: Component, ...rest }) => {
  let query = queryString.parse(rest.location.search);
  const isShowingMenu =
    rest.path === "/login" ||
    rest.path === "/signup" ||
    rest.path === "/forgotpassword" ||
    rest.path === "/resetpassword" ||
    rest.path === "/verify-email";

  const isShowingTopNotification = rest.path === "/";
  const { showFooter = true, showStickyHeader = false } = rest;
  return (
    <Route
      {...rest}
      render={props => {
        setTimeout(() => {
          window.scrollTo(0, 0)
        }, 250);
        return (
          !rest.isAuthenticated ? (
            <React.Fragment>
              {isShowingTopNotification ? <TopNotificationBar /> : null}
              <section className="top-header-fixed" id={showStickyHeader ? "sticky-header" : ""}>
                <Container>
                  <Row>
                    <Sidebar
                      {...props}
                      pageWrapId={"homepage"}
                      outerContainerId={"app"}
                    />
                    <Col>
                      <Header
                        isShowingMenu={!isShowingMenu}
                        {...props}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
              <div className='min-height-loader-content'>
                <Component {...props} />
              </div>
              {showFooter ? <FooterFlightSearch /> : <Footer />}
            </React.Fragment>
          ) : (
              <Redirect to={query.redirect || "/dashboard"} />
            )
        )
      }
      }
    />
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  null
)(UnauthenticatedRoute);
