import React from "react";
import Page from "../../components/page";
import {
  Row,
  Col,
  Container,
  Button,
  Form,
  FormControl
} from "react-bootstrap";

export default () => (
  <Page
    id="not-found"
    title="Perfect Departure.Com | Not Found"
    description="This is embarrassing."
    noCrawl
  >
    <Container>
      <Col xs={12} sm={12} md={12} className="text-center not-found">
        NOT FOUND
      </Col>
    </Container>
  </Page>
);
