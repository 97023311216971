import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class ScrollBottomToTop extends Component {
  state = {
    intervalId: 0,
    scroll: true
  };

  scrollStep = () => {
    if (window.pageYOffset === 0) {
      window.clearInterval(this.state.intervalId);
      this.setState({
        scroll: true
      })
    }
    else {
      window.scrollTo(0, window.pageYOffset - this.props.scrollStepInPx);
    }

  }

  scrollToTop = () => {
    if (this.state.scroll) {
      let intervalId = setInterval(() => this.scrollStep(), this.props.delayInMs);
      this.setState({ intervalId: intervalId, scroll: false });
    }

  }

  render() {
    const { isNearBottom = false } = this.props
    return (
      <React.Fragment>
        {isNearBottom ? <Button className='scroll toptobottom' onClick={() => this.scrollToTop()}>
          <i className="fas fa-chevron-up"></i>
        </Button> : null}
      </React.Fragment>
    )
  }
}

export default ScrollBottomToTop;