import React from "react";
import { Col } from "react-bootstrap";
import ContentLoader from "react-content-loader";

const ContentLoaderCards = props => {
  const { count = 1 } = props;
  const loader = () => {
    let i = 0;
    let html = [];
    while (i < count) {
      html.push(
        <Col key={i} xs={12} sm={12} md={12} style={{ marginBottom: "20px" }}>
          {props.hotels !== "hotels" ? (
            <ContentLoader height={40} width={500} speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
              {...props}
            >
              <rect x="0" y="2" rx="0" ry="0" width="60" height="36" />
              <rect x="65" y="10" rx="0" ry="0" width="35" height="6" />
              <rect x="65" y="22" rx="0" ry="0" width="35" height="6" />
              <rect x="135" y="2" rx="0" ry="0" width="60" height="36" />
              <rect x="200" y="10" rx="0" ry="0" width="35" height="6" />
              <rect x="200" y="22" rx="0" ry="0" width="35" height="6" />
              <rect x="265" y="2" rx="0" ry="0" width="60" height="36" />
              <rect x="330" y="10" rx="0" ry="0" width="35" height="6" />
              <rect x="330" y="22" rx="0" ry="0" width="35" height="6" />
              <rect x="400" y="2" rx="0" ry="0" width="60" height="36" />
              <rect x="465" y="10" rx="0" ry="0" width="35" height="6" />
              <rect x="465" y="22" rx="0" ry="0" width="35" height="6" />
            </ContentLoader>
          ) : (
            <ContentLoader
              height={115}
              width={500}
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
              {...props}
            >
              <rect x="0" y="2" rx="0" ry="0" width="110" height="70" />
              <rect x="0" y="80" rx="0" ry="0" width="40" height="5" />
              <rect x="80" y="87" rx="0" ry="0" width="25" height="6" />
              <rect x="80" y="98" rx="0" ry="0" width="30" height="6" />
              <rect x="0" y="90" rx="0" ry="0" width="60" height="6" />
              <rect x="0" y="100" rx="0" ry="0" width="45" height="5" />

              <rect x="123" y="2" rx="0" ry="0" width="110" height="70" />
              <rect x="123" y="80" rx="0" ry="0" width="40" height="5" />
              <rect x="203" y="87" rx="0" ry="0" width="25" height="6" />
              <rect x="203" y="98" rx="0" ry="0" width="30" height="6" />
              <rect x="123" y="90" rx="0" ry="0" width="60" height="6" />
              <rect x="123" y="100" rx="0" ry="0" width="45" height="5" />

              <rect x="255" y="2" rx="0" ry="0" width="110" height="70" />
              <rect x="255" y="80" rx="0" ry="0" width="40" height="5" />
              <rect x="335" y="87" rx="0" ry="0" width="25" height="6" />
              <rect x="335" y="98" rx="0" ry="0" width="30" height="6" />
              <rect x="255" y="90" rx="0" ry="0" width="60" height="6" />
              <rect x="255" y="100" rx="0" ry="0" width="45" height="5" />

              <rect x="390" y="2" rx="0" ry="0" width="110" height="70" />
              <rect x="390" y="80" rx="0" ry="0" width="40" height="5" />
              <rect x="470" y="87" rx="0" ry="0" width="25" height="6" />
              <rect x="470" y="98" rx="0" ry="0" width="30" height="6" />
              <rect x="390" y="90" rx="0" ry="0" width="60" height="6" />
              <rect x="390" y="100" rx="0" ry="0" width="45" height="5" />
            </ContentLoader>
          )}
        </Col>
      );
      i++;
    }
    return html;
  };
  return <Col key={count}>{loader()}</Col>;
};

export default ContentLoaderCards;
