//File Name: PopularDestinations.js
//Path: src/app/coomponents/homepage
//Description: This file is used for PopularDestinations searches.

import React, { Component } from "react";
import { CardColumns, Card, Row, Col, Container } from "react-bootstrap";
import moment from "moment";


class PopularDestination extends Component {
  state = {};

  onClickDestination = (index) => {
    let month  = MonthsImages[moment().format("MMMM")]
    let name = month && month[index] && month[index].name
    this.props.history.push(`/query/tour-package/${name}`, { name: name })
  }

  render() {
    const { destinations = [] } = this.props;

    return (
      <div className="populardestination">
        <Container>
          <h2 align="center" className="sub-title">
            POPULAR <span className="custom-label">DESTINATIONS</span>
          </h2>
          <Row>
            <PopularDestinationMonths months={moment().format("MMMM")} onClickDestination={this.onClickDestination} />
          </Row>
        </Container>
      </div>
    );
  }
}

export default PopularDestination;

const PopularDestinationMonths = (props) => {
  switch (props.months) {
    case "January":
      return <DestinationUI destinations={MonthsImages.January} onClickDestination={props.onClickDestination} />

    case "February":
      return <DestinationUI destinations={MonthsImages.February} onClickDestination={props.onClickDestination} />

    case "March":
      return <DestinationUI destinations={MonthsImages.March} onClickDestination={props.onClickDestination} />

    case "April":
      return <DestinationUI destinations={MonthsImages.April} onClickDestination={props.onClickDestination} />

    case "May":
      return <DestinationUI destinations={MonthsImages.May} onClickDestination={props.onClickDestination} />

    case "June":
      return <DestinationUI destinations={MonthsImages.June} onClickDestination={props.onClickDestination} />

    case "July":
      return <DestinationUI destinations={MonthsImages.July} onClickDestination={props.onClickDestination} />

    case "August":
      return <DestinationUI destinations={MonthsImages.August} onClickDestination={props.onClickDestination} />

    case "September":
      return <DestinationUI destinations={MonthsImages.September} onClickDestination={props.onClickDestination} />

    case "October":
      return <DestinationUI destinations={MonthsImages.October} onClickDestination={props.onClickDestination} />

    case "November":
      return <DestinationUI destinations={MonthsImages.November} onClickDestination={props.onClickDestination} />

    case "December":
      return <DestinationUI destinations={MonthsImages.December} onClickDestination={props.onClickDestination} />

    default:
      return <DestinationUI destinations={MonthsImages.May} onClickDestination={props.onClickDestination} />
  }
}

const DestinationUI = (props) => {
  const { onClickDestination, destinations } = props;
  return (<React.Fragment>
    <Col xs={12} sm={12} md={3} className="hotal-review" onClick={() => onClickDestination(0)}>
      <div className="hovereffect group-one img-view">
        <img src={destinations && destinations[0] && destinations[0].image} />
        <div className="overlay">
          <h2>{destinations && destinations[0] && destinations[0].location} </h2>
          <p>Highly rated for culture.</p>
        </div>
      </div>
      <div className="hotal-name">
        <p>{destinations && destinations[0] && destinations[0].location} </p>
      </div>
    </Col>

    <Col xs={12} sm={12} md={6} className="center-div-custom"  >
      <Row>
        <Col xs={6} sm={6} md={6} className="hotal-review" onClick={() => onClickDestination(1)}>
          <div className="hovereffect group-two img-view">
            <img src={destinations && destinations[1] && destinations[1].image} />
            <div className="overlay">
              <h2>{destinations && destinations[1] && destinations[1].location} </h2>
              <p>Highly rated for culture.</p>
            </div>
          </div>

          <div className="hotal-name">
            <p>{destinations && destinations[1] && destinations[1].location} </p>
          </div>
        </Col>
        <Col xs={6} sm={6} md={6} className="hotal-review " onClick={() => onClickDestination(2)} >
          <div className="hovereffect group-three img-view">
            <img src={destinations && destinations[2] && destinations[2].image} />
            <div className="overlay">
              <h2>{destinations && destinations[2] && destinations[2].location} </h2>
              <p>Highly rated for culture.</p>
            </div>
          </div>
          <div className="hotal-name">
            <p>{destinations && destinations[2] && destinations[2].location} </p>
          </div>
        </Col>

        <Col xs={12} sm={12} md={12} className="hotal-review" onClick={() => onClickDestination(3)} >
          <div className="hovereffect group-four img-view">
            <img src={destinations && destinations[3] && destinations[3].image} />
            <div className="overlay">
              <h2>{destinations && destinations[3] && destinations[3].location}</h2>
              <p>Highly rated for culture.</p>
            </div>
          </div>
          <div className="hotal-name">
            <p>{destinations && destinations[3] && destinations[3].location}</p>
          </div>
        </Col>
      </Row>
    </Col>
    <Col xs={12} sm={12} md={3} className="hotal-review" onClick={() => onClickDestination(4)} >
      <div className="hovereffect group-five img-view">
        <img src={destinations && destinations[4] && destinations[4].image} />
        <div className="overlay">
          <h2>{destinations && destinations[4] && destinations[4].location} </h2>
          <p>Highly rated for culture.</p>
        </div>
      </div>
      <div className="hotal-name">
        <p>{destinations && destinations[4] && destinations[4].location} </p>
      </div>
    </Col>
  </React.Fragment>)
}

const MonthsImages = {
  January: [{ image: require("../../assets/homepage/destinations/populardestiations/jan1.jpg"), name: "Dubai", location: "Dubai" },
  { image: require("../../assets/homepage/destinations/populardestiations/jan2.jpg"), name: "Goa", location: "Goa" },
  { image: require("../../assets/homepage/destinations/populardestiations/jan3.jpg"), name: "Kerala", location: "Kerala" },
  { image: require("../../assets/homepage/destinations/populardestiations/jan4.jpg"), name: "Rajasthan", location: "Rajasthan" },
  { image: require("../../assets/homepage/destinations/populardestiations/jan5.jpg"), name: "Singapore", location: "Singapore" }],
  February: [{ image: require("../../assets/homepage/destinations/populardestiations/feb1.jpg"), name: "Andaman", location: "Andaman" },
  { image: require("../../assets/homepage/destinations/populardestiations/feb2.jpg"), name: "Dubai", location: "Dubai" },
  { image: require("../../assets/homepage/destinations/populardestiations/feb3.jpg"), name: "Maharashtra", location: "Maharashtra" },
  { image: require("../../assets/homepage/destinations/populardestiations/feb4.jpg"), name: "Hong Kong", location: "Hong Kong" },
  { image: require("../../assets/homepage/destinations/populardestiations/feb5.jpg"), name: "Gurjat", location: "Gurjat" }],
  March: [{ image: require("../../assets/homepage/destinations/populardestiations/march1.jpg"), name: "Maldives", location: "Maldives" },
  { image: require("../../assets/homepage/destinations/populardestiations/march2.jpg"), name: "Kerala", location: "Kerala" },
  { image: require("../../assets/homepage/destinations/populardestiations/march3.jpg"), name: "Sikkim", location: "Sikkim" },
  { image: require("../../assets/homepage/destinations/populardestiations/march4.jpg"), name: "Uttrakhand", location: "Uttrakhand" },
  { image: require("../../assets/homepage/destinations/populardestiations/march5.jpg"), name: "Vietnam", location: "Vietnam" }],
  April: [{ image: require("../../assets/homepage/destinations/populardestiations/april1.jpg"), name: "Bali", location: "Bali" },
  { image: require("../../assets/homepage/destinations/populardestiations/april2.jpg"), name: "Assam", location: "Assam" },
  { image: require("../../assets/homepage/destinations/populardestiations/april3.jpg"), name: "Europe", location: "Europe" },
  { image: require("../../assets/homepage/destinations/populardestiations/april4.jpg"), name: "Meghalaya", location: "Meghalaya" },
  { image: require("../../assets/homepage/destinations/populardestiations/april5.jpg"), name: "Sikkim", location: "Sikkim" }],
  May: [{ image: require("../../assets/homepage/destinations/populardestiations/may1.jpg"), name: "Bali", location: "Bali" },
  { image: require("../../assets/homepage/destinations/populardestiations/may2.jpg"), name: "U.S.A", location: "U.S.A" },
  { image: require("../../assets/homepage/destinations/populardestiations/may3.jpg"), name: "Himachal Pradesh", location: "Himachal Pradesh" },
  { image: require("../../assets/homepage/destinations/populardestiations/may4.jpg"), name: "Assam", location: "Assam" },
  { image: require("../../assets/homepage/destinations/populardestiations/may5.jpg"), name: "Europe", location: "Europe" }],
  June: [{ image: require("../../assets/homepage/destinations/populardestiations/june1.jpg"), name: "Kashmir", location: "Kashmir" },
  { image: require("../../assets/homepage/destinations/populardestiations/june2.jpg"), name: "CharDham", location: "CharDham" },
  { image: require("../../assets/homepage/destinations/populardestiations/june3.jpg"), name: "Leh Ladakh", location: "Leh Ladakh" },
  { image: require("../../assets/homepage/destinations/populardestiations/june4.jpg"), name: "Mauritius", location: "Mauritius" },
  { image: require("../../assets/homepage/destinations/populardestiations/june5.jpg"), name: "South Africa", location: "South Africa" }],
  July: [{ image: require("../../assets/homepage/destinations/populardestiations/jul1.jpg"), name: "Russia", location: "Russia" },
  { image: require("../../assets/homepage/destinations/populardestiations/jul2.jpg"), name: "Amarnath", location: "Amarnath" },
  { image: require("../../assets/homepage/destinations/populardestiations/jul3.jpg"), name: "Leh Ladakh", location: "Leh Ladakh" },
  { image: require("../../assets/homepage/destinations/populardestiations/jul4.jpg"), name: "Mauritius", location: "Mauritius" },
  { image: require("../../assets/homepage/destinations/populardestiations/jul5.jpg"), name: "U.K.", location: "U.K." }],
  August: [{ image: require("../../assets/homepage/destinations/populardestiations/aug1.jpg"), name: "Europe", location: "Europe" },
  { image: require("../../assets/homepage/destinations/populardestiations/aug2.jpg"), name: "Bali", location: "Bali" },
  { image: require("../../assets/homepage/destinations/populardestiations/aug3.jpg"), name: "CharDham", location: "CharDham" },
  { image: require("../../assets/homepage/destinations/populardestiations/aug4.jpg"), name: "South Africa", location: "South Africa" },
  { image: require("../../assets/homepage/destinations/populardestiations/aug5.jpg"), name: "U.S.A", location: "U.S.A" }],
  September: [{ image: require("../../assets/homepage/destinations/populardestiations/sept1.jpg"), name: "China", location: "China" },
  { image: require("../../assets/homepage/destinations/populardestiations/sept2.jpg"), name: "Japan", location: "Japan" },
  { image: require("../../assets/homepage/destinations/populardestiations/sept3.jpg"), name: "TamilNadu", location: "TamilNadu" },
  { image: require("../../assets/homepage/destinations/populardestiations/sept4.jpg"), name: "U.K", location: "U.K" },
  { image: require("../../assets/homepage/destinations/populardestiations/sept5.jpg"), name: "Uttarakhand", location: "Uttarakhand" }],
  October: [{ image: require("../../assets/homepage/destinations/populardestiations/oct1.jpg"), name: "Bhutan", location: "Bhutan" },
  { image: require("../../assets/homepage/destinations/populardestiations/oct2.jpg"), name: "China", location: "China" },
  { image: require("../../assets/homepage/destinations/populardestiations/oct3.jpg"), name: "Dubai", location: "Dubai" },
  { image: require("../../assets/homepage/destinations/populardestiations/oct4.jpg"), name: "Kerala", location: "Kerala" },
  { image: require("../../assets/homepage/destinations/populardestiations/oct5.jpg"), name: "Maharashtra", location: "Maharashtra" }],
  November: [{ image: require("../../assets/homepage/destinations/populardestiations/nov1.jpg"), name: "Goa", location: "Goa" },
  { image: require("../../assets/homepage/destinations/populardestiations/nov2.jpg"), name: "Bhutan", location: "Bhutan" },
  { image: require("../../assets/homepage/destinations/populardestiations/nov3.jpg"), name: "Gujarat", location: "Gujarat" },
  { image: require("../../assets/homepage/destinations/populardestiations/nov4.jpg"), name: "Kerala", location: "Kerala" },
  { image: require("../../assets/homepage/destinations/populardestiations/nov5.jpg"), name: "Maldives", location: "Maldives" }],
  December: [{ image: require("../../assets/homepage/destinations/populardestiations/dec1.jpg"), name: "Dubai", location: "Dubai" },
  { image: require("../../assets/homepage/destinations/populardestiations/dec2.jpg"), name: "Andaman", location: "Andaman" },
  { image: require("../../assets/homepage/destinations/populardestiations/dec3.jpg"), name: "Goa", location: "Goa" },
  { image: require("../../assets/homepage/destinations/populardestiations/dec4.jpg"), name: "Rajasthan", location: "Rajasthan" },
  { image: require("../../assets/homepage/destinations/populardestiations/dec5.jpg"), name: "Singapore", location: "Singapore" }]
}