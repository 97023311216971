import types from "../types";
import { getStaticDataInfo, subscribeEmailAPI, getCardPercentageAPI, unSubscribeEmailAPI, getCountriesListAPI } from "../api/staticData";

export const getStaticInfo = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.GET_STATIC_DATA
    });
    getStaticDataInfo()
      .then(res => {
        dispatch({
          type: types.GET_STATIC_DATA_SUCCESS,
          payload: res
        });

        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.GET_STATIC_DATA_FAILED
        });
        return reject(err);
      });
  });

export const subscribeEmail = payload => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.SUBSCRIBE_EMAIL
    });
    subscribeEmailAPI(payload)
      .then(res => {
        dispatch({
          type: types.SUBSCRIBE_EMAIL_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.SUBSCRIBE_EMAIL_FAILED
        });
        return reject(err);
      });
  });

export const getCardPercentage = payload => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.GET_CARD_PERCENTAGE
    });
    getCardPercentageAPI(payload)
      .then(res => {
        dispatch({
          type: types.GET_CARD_PERCENTAGE_SUCCESS,
          payload: res
        });

        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.GET_CARD_PERCENTAGE_FAILED
        });
        return reject(err);
      });
  });
}

export const UnSubscribeEmail = payload => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.UN_SUBSCRIBE_EMAIL
    });
    unSubscribeEmailAPI(payload)
      .then(res => {
        dispatch({
          type: types.UN_SUBSCRIBE_EMAIL_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.UN_SUBSCRIBE_EMAIL_FAILED
        });
        return reject(err);
      });
  }
  );
}

export const getCountriesList = payload => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.GET_COUNTRIES_LIST_RESULT
    })
    getCountriesListAPI()
      .then((res) => {
        const modifyCountries = (res && res.info && res.info.map(item => ({ label: item.Name, value: item.Code }))) || []
        dispatch({
          type: types.GET_COUNTRIES_LIST_RESULT_SUCCESS,
          payload: modifyCountries
        })
        return resolve(modifyCountries)
      }).catch((err) => {
        dispatch({
          type: types.GET_COUNTRIES_LIST_RESULT_FAILED
        })
        return reject(err)
      })
  })
}
