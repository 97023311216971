//File Name: Testimonials.js
//Path: src/app/coomponents/homepage
//Description: This file is used for Persons Review.
import { CardDeck, Card, Image, Col } from "react-bootstrap";
import React, { Component, useState } from "react";
import Slider from "react-slick";
import ReactStars from "react-stars";
import {ReadMore} from "../../components"
import { ContentLoaderCards } from "../";
import { currencySign, truncate } from "../../utils";

class Testimonials extends Component {
  state = {};
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      cssEase: "linear",
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    const { travelexp = [] } = this.props;
    const maxContLen = 300; // Max. Description content length for read more

    return (
      <div>
        <h2 align="center" className="sub-title">
          TRAVELLER'S <span className="custom-label">EXPERIENCES</span>
        </h2>
        {/* <CardDeck> */}
        <div className="testimonials-slider">
          <Slider {...settings}>
            {travelexp && travelexp.length
              ? travelexp.map((item, index) => {
                return (
                  <div key={index} className="testimonials-view-details">
                    <Card>

                      <Card.Body>
                        <Card.Text>
                          <ReadMore maxContLen={maxContLen} description={item.description} index={index}  />
                        </Card.Text>
                      </Card.Body>
                      <div className="star">
                        <div className="star-rating">
                          <ReactStars
                            edit={false}
                            count={5}
                            value={5}
                            size={20}
                            color2={"#ffd700"}
                          />
                        </div>
                        <Card.Title>{item.name}</Card.Title>
                        <Card.Title>
                          <img
                            src={require(`../../assets/social_icons/${item.review_from}.png`)}
                          />
                        </Card.Title>
                      </div>
                    </Card>
                  </div>
                );
              })
              : null}
          </Slider>
        </div>
        {/* </CardDeck> */}
      </div>
    );
  }
}

export default Testimonials;
