import types from '../types'
import { getUser } from '../utils';

const initState = {
    fetching: false,
    userInfo: getUser()
}

export default (state = initState, action) => {
    switch (action.type) {
        case types.GET_USER_PROFILE:
            return { ...state, fetching: true }

        case types.GET_USER_PROFILE_SUCCESS:
            return { ...state, fetching: false, userInfo: { ...action.payload } }

        case types.GET_USER_PROFILE_FAILED:
            return { ...state, fetching: false }


        case types.UPDATE_USER_PROFILE:
            return { ...state, fetching: true }

        case types.UPDATE_USER_PROFILE_SUCCESS:
            return { ...state, fetching: false }

        case types.UPDATE_USER_PROFILE_FAILED:
            return { ...state, fetching: false }


        case types.EMPTY_LOGGEDIN_USER:
            return { ...state, userInfo: null }

        case types.ADD_AGENT_LOGO:
            return { ...state, fetching: true }

        case types.ADD_AGENT_LOGO_SUCCESS:
            return { ...state, fetching: false }

        case types.ADD_AGENT_LOGO_FAILED:
            return { ...state, fetching: false }


        default:
            return state;
    }
}  