export const MANAGE_BOOKINGS = "MANAGE_BOOKINGS";
export const MANAGE_BOOKINGS_SUCCESS = "MANAGE_BOOKINGS_SUCCESS";
export const MANAGE_BOOKINGS_FAILED = "REGISTER_BUSINESS_FAILED";

export const CUSTOM_MANAGE_BOOKINGS = "CUSTOM_MANAGE_BOOKINGS";
// export const UPCOMING_MANAGE_BOOKINGS_SUCCESS = "UPCOMING_MANAGE_BOOKINGS_SUCCESS";
// export const CANCELLED_MANAGE_BOOKINGS_SUCCESS = "CANCELLED_MANAGE_BOOKINGS_SUCCESS";
// export const COMPELETED_MANAGE_BOOKINGS_SUCCESS = "COMPELETED_MANAGE_BOOKINGS_SUCCESS";





export const GET_BOOKING_DETAIL = 'GET_BOOKING_DETAIL';
export const GET_BOOKING_DETAIL_SUCCESS = 'GET_BOOKING_DETAIL_SUCCESS';
export const GET_BOOKING_DETAIL_FAILED = 'GET_BOOKING_DETAIL_FAILED';

export const CANCEL_BOOKING ='CANCEL_BOOKING';
export const CANCEL_BOOKING_SUCCESS ='CANCEL_BOOKING_SUCCESS';
export const CANCEL_BOOKING_FAILED ='CANCEL_BOOKING_FAILED';

export const GET_LATEST_PDFS ='GET_LATEST_PDFS';
export const GET_LATEST_PDFS_SUCCESS ='GET_LATEST_PDFS_SUCCESS';
export const GET_LATEST_PDFS_FAILED ='GET_LATEST_PDFS_FAILED';


export const UPDATE_ETICKET = "UPDATE_ETICKET" 