import types from "../types";

const initialState = {
    fetching: false,
    countries:[],
    states:[],
    cities:[]
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.REGISTER_BUSINESS:
            return { ...state, fetching: true };
        case types.REGISTER_BUSINESS_SUCCESS:
            return { ...state, fetching: false };
        case types.REGISTER_BUSINESS_FAILED:
            return { ...state, fetching: false };

        case types.GET_COUNTRIES:
            return { ...state,countries:action.payload.info || [] };
        case types.GET_STATES:
            return { ...state, states:action.payload.info || []};
        case types.GET_CITIES:
            return { ...state, cities:action.payload.info  || []};

        case types.CLEAR_CITIES:
            return {...state , cities:[] }
            
        case types.CLEAR_STATES:
            return {...state , states:[] }    

        default:
            return state;
    }
};
