// The basics
import React, { Component } from "react";
import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
// import { Modal } from "react-bootstrap";
// Action creators and helpers
import actions from "./actions";
import { isServer } from "../store";
import Routes from "./routes";
import "react-dates/initialize";
import "./app.css";
import IdleTimer from 'react-idle-timer'
import { logOut, isLoggedIn } from "./utils";
import { ToastContainer, toast } from "react-toastify";


let myapplicationInterval = false;

class App extends Component {
  constructor(props) {
    super(props);
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
  }
  componentWillMount() {
    if (!isServer) {
      this.props.establishCurrentUser();
    }
  }

  componentDidMount() {
    window.addEventListener('focus', this.startTimer);
    window.addEventListener('blur', this.stopTimer);
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.startTimer);
    window.removeEventListener('blur', this.stopTimer);
  }

  // Start timer
  startTimer() {
    myapplicationInterval = true;
  }

  // Stop timer
  stopTimer() {
    myapplicationInterval = false;
  }

  onIdle = async (e) => {

    if (isLoggedIn() && myapplicationInterval) {
      await this.props.history.push("/");
      await logOut();
      await this.props.logout();
      await this.props.history.go("/");

      // await toast.success(`Session Timeout: 
      // Your session expired due to inactivity. Kindly login again to continue with your booking.`, {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: false
      // });

    }

  }

  render() {
    return (
      <div id="app">
        <div id="seconds">
        </div>
        <div>
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={() => { }}
            onIdle={this.onIdle}
            onAction={() => { }}
            debounce={250}
            timeout={1000 * 60 * 15} />
          <Routes />
          <ToastContainer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});


const mapDispatchToProps = dispatch => ({
  establishCurrentUser: () => dispatch(actions.establishCurrentUser()),
  logout: () => dispatch(actions.EmptyLoggedinUser())
})


export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
