import types from "../types";
import { postSpecialFaresAPI, getSpecialFaresListingAPI, editSpecialFareAPI, postMultipleSpecialFaresAPI } from "../api/specialFares";

export const postSpecialFares = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.POST_SPECIAL_FARES
        });
        postSpecialFaresAPI(data)
            .then(res => {
                dispatch({
                    type: types.POST_SPECIAL_FARES_SUCCESS,
                });
                return resolve(res);
            })
            .catch(err => {

                dispatch({
                    type: types.POST_SPECIAL_FARES_FAILED
                });
                return reject(err);
            });
    }
    );

export const getSpecialFaresListing = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.GET_SPECIAL_FARES_LISTING
        });
        getSpecialFaresListingAPI(data)
            .then(res => {
                dispatch({
                    type: types.GET_SPECIAL_FARES_LISTING_SUCCESS,
                    payload: res
                });
                return resolve(res);
            })
            .catch(err => {

                dispatch({
                    type: types.GET_SPECIAL_FARES_LISTING_FAILED
                });
                return reject(err);
            });
    }
    );


export const editSpecialFare = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.EDIT_SPECIAL_FARES
        });
        editSpecialFareAPI(data)
            .then(res => {
                dispatch({
                    type: types.EDIT_SPECIAL_FARES_SUCCESS,
                    payload: res
                });
                return resolve(res);
            })
            .catch(err => {

                dispatch({
                    type: types.EDIT_SPECIAL_FARES_FAILED
                });
                return reject(err);
            });
    }
    );

export const postMultipleSpecialFares = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.POST_SPECIAL_FARES
        });
        postMultipleSpecialFaresAPI(data)
            .then(res => {
                dispatch({
                    type: types.POST_SPECIAL_FARES_SUCCESS,
                });
                return resolve(res);
            })
            .catch(err => {

                dispatch({
                    type: types.POST_SPECIAL_FARES_FAILED
                });
                return reject(err);
            });
    }
    );



