import types from "../types";

const initialState = {
  fetching: false,
  staticInformation: {},
  cardPercentage: {},
  countryList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_STATIC_DATA:
      return { ...state, fetching: true };
    case types.GET_STATIC_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        staticInformation: action && action.payload.info
      };
    case types.GET_STATIC_DATA_FAILED:
      return { ...state, fetching: false };


    case types.GET_CARD_PERCENTAGE:
      return { ...state };
    case types.GET_CARD_PERCENTAGE_SUCCESS:
      return {
        ...state,
        cardPercentage: action && action.payload.info
      };
    case types.GET_CARD_PERCENTAGE_FAILED:
      return { ...state };

    case types.GET_COUNTRIES_LIST_RESULT_SUCCESS:
      return { ...state, countryList: action.payload || [] }

    default:
      return state;
  }
};
