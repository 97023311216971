export const POST_SPECIAL_FARES = "POST_SPECIAL_FARES";
export const POST_SPECIAL_FARES_SUCCESS = "POST_SPECIAL_FARES_SUCCESS";
export const POST_SPECIAL_FARES_FAILED = "POST_SPECIAL_FARES_FAILED";


export const GET_SPECIAL_FARES_LISTING = "GET_SPECIAL_FARES_LISTING";
export const GET_SPECIAL_FARES_LISTING_SUCCESS = "GET_SPECIAL_FARES_LISTING_SUCCESS";
export const GET_SPECIAL_FARES_LISTING_FAILED = "GET_SPECIAL_FARES_LISTING_FAILED";


export const EDIT_SPECIAL_FARES = "EDIT_SPECIAL_FARES";
export const EDIT_SPECIAL_FARES_SUCCESS = "EDIT_SPECIAL_FARES_SUCCESS";
export const EDIT_SPECIAL_FARES_FAILED = "EDIT_SPECIAL_FARES_FAILED";