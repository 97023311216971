import React, { Component } from "react";

class TimeZonesFilter extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <h6>{title}</h6>
        <ul className="list_timezones">
          {timeZonesData.map((item, index) => {
            const active = selectedFilter.find(i => i === item.value) || false;
            return (
              <li
                key={index}
                onClick={() => handleFilters(name, item.value)}
                className={active ? "active" : ""}
              >
                <img
                  src={active ? item.activeImg : item.inactiveImg}
                  className={active ? "active" : ""}
                />
                <span className={active ? "active" : ""}>{item.label}</span>
              </li>
            );
          })}
        </ul> */}
      </React.Fragment>
    );
  }
}

export default TimeZonesFilter;