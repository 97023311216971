import types from '../types'
import {
    getUserProfileAPI,
    updatedUserProfileAPI,
    addAgentLogoAPI
} from '../api/profileSettings';

//action user for get user detail
export const getUserProfile = (payload) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.GET_USER_PROFILE
        })
        getUserProfileAPI(payload).then(res => {
            dispatch({
                type: types.GET_USER_PROFILE_SUCCESS,
                payload: res
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: types.GET_USER_PROFILE_FAILED
            })
            return reject(err)
        })
    }
    )

//action used for updating user profile
export const updateUserProfile = (payload, token) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.UPDATE_USER_PROFILE
        })
        updatedUserProfileAPI(payload, token).then(res => {
            dispatch({
                type: types.UPDATE_USER_PROFILE_SUCCESS,
                payload: res
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: types.UPDATE_USER_PROFILE_FAILED
            })
            return reject(err)
        })
    }
    )


export const EmptyLoggedinUser = () => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: types.EMPTY_LOGGEDIN_USER,
            payload: null
        })
        dispatch({
            type: types.LOGOUT_USER_WALLET,
            payload: null
        })
        return resolve()
    }
    );
}

export const addAgentLogo = (payload, token) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.ADD_AGENT_LOGO
        })
        addAgentLogoAPI(payload, token).then(res => {
            dispatch({
                type: types.ADD_AGENT_LOGO_SUCCESS,
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: types.ADD_AGENT_LOGO_FAILED
            })
            return reject(err)
        })
    }
    )