
import React, { useState } from 'react';

const ReadMore = props => {
    const { maxContLen = 50, description = '', index = 0 } = props;
    const [isReadMore, setisReadMore] = useState(true);
    const [indx, setindx] = useState(-1);

  return (<>
    {((isReadMore) && (description !== null) && (description.length > maxContLen))
       ? description.slice(0, maxContLen) 
       : ((indx == index) ? description : ((description !== null) ? description.slice(0, maxContLen) : null) ) 
    }
    {((description !== null) && (description.length > maxContLen))
       ? <span onClick={() => { setisReadMore(!(isReadMore)); setindx(index);}} className="read-or-hide"> 
            {isReadMore ? "...read more" : ((indx == index) ? " read less" : "...read more") }
         </span> 
       : null }
    </>);
};

export default ReadMore;
