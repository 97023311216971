//File Name: Footer.js
//Path: src/app/coomponents/common
//Description: This file is used for Footer.
import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { social_icons, our_products } from "../../staticData"
import { Link } from "react-router-dom"

class Footer extends Component {
  state = {};

  render() {
    return (
      <Container>
        <Row className="footer-main">
          <Col xs={12} sm={12} md={12} lg={6} className="list-footer">
            <h5>OUR PRODUCTS</h5>
            <ul className="list-style-custom our-product">
              {
                our_products.map((data, index) => {
                  return (
                    <React.Fragment key={index}>
                      <li>
                        <Link to={`/query/${data.value}`}>{data.title}</Link>
                      </li>
                    </React.Fragment>
                  )
                })
              }
            </ul>
          </Col>

          <Col xs={12} sm={6} lg={3} className="list-footer">
            <h5>Company</h5>
            <ul className="list-style-custom">
              <li>
                <Link to="/about">About Us </Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/support" >
                  FAQs
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms-conditions" >
                  Terms of Use
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={6} sm={6} lg={3} className="list-footer soical-custom ">
            <h5>SOCIAL</h5>
            <ul className="list-style-custom  soical-media-custom">
              {
                social_icons.map((data, index) => {
                  return (
                    <React.Fragment key={index}>
                      <li>
                        <a href={data.link} target="_blank" >
                          <i className={data.className} />
                        </a>
                      </li>
                    </React.Fragment>
                  )
                })
              }
            </ul>
          </Col>
          <Col xs={12} sm={12} md={12} className="text-center footer-copyright">
            <p>
              {" "}
              © All Rights Reserved by{" "}
              <span className="logo-color"> Perfect Departure.Com Pvt. Ltd.</span>
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Footer;
