import { apiPost, getUser } from "../utils";
import { apiKey } from "../constants";



export function getCouponsAPI(value) {
  const data = JSON.stringify({
    ...value,
    "token": getUser() && getUser().token ? getUser().token : ""
  })
  return apiPost(`/api/discount?&appKey=${apiKey}`, data);

}
