import types from "../types";
import {
  getBestInsuranceAPI
} from "../api/insurance";


// action used for getting best insurance while flight booking

export const getBestInsurance = (data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.GET_BEST_INSURANCE
    });
    getBestInsuranceAPI(data)
      .then(res => {
        dispatch({
          type: types.GET_BEST_INSURANCE_SUCCESS,
          payload: res
        });
        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.GET_BEST_INSURANCE_FAILED
        })
        return reject(err);
      });
  });

