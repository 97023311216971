import React from "react";
import ContentLoader from "react-content-loader";

const ContentLoaderFare = props => {
    const { count = 1 } = props;
    const loader = () => {
        let i = 0;
        let html = [];
        while (i < count) {
            html.push(
                <div key={i}  >
                    <ContentLoader height={15} width={100} speed={1.5}
                        primaryColor="#f3f3f3"
                        secondaryColor="#e4e3e3"
                        {...props}
                    >
                        <rect x="20" y="0" rx="0" ry="0" width="25" height="25" />

                        <rect x="35" y="0" rx="0" ry="0" width="25" height="25" />

                        <rect x="60" y="0" rx="0" ry="0" width="25" height="25" />
                    </ContentLoader>
                </div>
            );
            i++;
        }
        return html;
    };
    return <div key={count}>{loader()}</div>;
};

export default ContentLoaderFare;
