import React from 'react';
import { Row, Col, Container } from "react-bootstrap";
import { social_icons } from "../../staticData";


const FooterFlightSearch = () => {
    return (
        <React.Fragment>
            <section className="footer-bottom">
                <div className="footer-pdc">
                    <Container className="pdcsecure">
                        <Row>
                            <Col md={6} lg={6} sm={5} className="securecontenticon">
                                <img src={require("../../assets/four-logos.png")} alt="not found" />
                            </Col>
                            <Col md={6} lg={6} sm={7} className="Connect-with-us-footer pull-right">
                                <ul className="list-style-custom soical-media-custom">
                                    {
                                        social_icons.map((data, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <li>
                                                        <a href={data.link} target="_blank" rel="noopener noreferrer"  >
                                                            <i className={data.className} />
                                                        </a>
                                                    </li>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="copyright">
                    <h4>Copyright © 2019 All Rights Reserved by Perfect Departure.Com Pvt. Ltd.</h4>
                </div>
            </section>
        </React.Fragment>
    )
}

export default FooterFlightSearch;