import React from 'react';
import {
    Row,
    Col,
    Container,
    Button,
    Form,
    FormControl,
    Overlay,
    Tooltip,
    Popover,
    OverlayTrigger,
    Badge
} from "react-bootstrap";
import Select, { Async } from "react-select";
import {
    passengersOption,
    classOption,
    statuses,
    passengerTypes,
    convertStaticData
} from '../../staticData'

import {
    DateRangePicker,
    SingleDatePicker,
    DayPickerRangeControllery
} from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";

class Multcity extends React.Component {
    state = {}
    render() {
        const {
            multicity = [],
            multicitydates,
            loadOptions,
            delcities,
            blockedDates,
            addcities,
            width
        } = this.props

        return (
            <React.Fragment>
                <Col xs={12} sm={12} md={12}>
                    {multicity.map((data, key) => {
                        let from = data.from && data.from.hasOwnProperty("iata")
                        let to = data.to && data.to.hasOwnProperty("iata")
                        let show1 = false;
                        if (from && to) {
                            show1 = (data.from && data.from.iata) === (data.to && data.to.iata)
                        }

                        return (
                            <Row className="custom-row" key={key}>
                                <Col xs={12} sm={4} md={4} className="from-group-custom react-select"
                                >
                                    <label> From</label>
                                    <Async
                                        value={data.from}
                                        placeholder={""}
                                        onChange={e => multicitydates(e, key, "from")}
                                        loadOptions={loadOptions}
                                        defaultOptions={convertStaticData(passengersOption)}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary: "#ed3237",
                                            }
                                        })}
                                    />
                                </Col>
                                <Col xs={12} sm={4} md={4} className="from-group-custom react-select"
                                >
                                    <label>To </label>
                                    <Async
                                        placeholder={""}
                                        value={data.to}
                                        defaultOptions={convertStaticData(passengersOption)}
                                        onChange={e => multicitydates(e, key, "to")}
                                        loadOptions={loadOptions}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary: "#ed3237",
                                            }
                                        })}
                                    />
                                    {show1 ? (
                                        <Tooltip
                                            placement="bottom"
                                            show={show1.toString()}
                                            id="tooltip-right"
                                        >
                                            <span>From & To cannot be same</span>
                                        </Tooltip>
                                    ) : null}
                                </Col>
                                <Col xs={12} sm={4} md={4} className="from-group-custom">
                                    <label>Depart </label>
                                    <SingleDatePicker
                                        readOnly
                                        date={moment(data.date)}
                                        displayFormat="DD/MM/YYYY"
                                        onDateChange={date =>
                                            multicitydates(date, key, "date")
                                        }
                                        focused={data.focused}
                                        onFocusChange={e =>
                                            multicitydates(e, key, "focused")
                                        }
                                        id={key.toString()}
                                        isDayBlocked={e => blockedDates(e, key)}
                                        orientation={width < 999 ? 'vertical' : 'horizontal'}
                                        verticalHeight={355}
                                        daySize={width < 999 ? 30 : 45}
                                    />
                                </Col>
                                {key >= 2 ? (
                                    <Button
                                        className="close-btn"
                                        onClick={() => delcities(key)}
                                    >
                                        <i className="fa fa-times" aria-hidden="true" />
                                    </Button>
                                ) : null}
                            </Row>
                        );
                    })}
                    {multicity.length == 5 ? null : (
                        <Col xs={12} sm={12} md={12} className="nopadding">
                            <Button className="add-more" onClick={addcities}>
                                Add more City
                      </Button>
                        </Col>
                    )}
                </Col>
            </React.Fragment>
        );
    }
}

export default Multcity;