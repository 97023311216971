import types from "../types";
import { getCouponsAPI } from "../api/coupon";

export const getAllCoupons = payload => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: types.GET_COUPONS
        });
        getCouponsAPI(payload)
            .then(res => {
                dispatch({
                    type: types.GET_COUPONS_SUCCESS,
                    payload: res
                });
                return resolve(res);
            })
            .catch(err => {
                dispatch({
                    type: types.GET_COUPONS_FAILED
                });
                return reject(err);
            });
    });
}