import React from "react";
import { Col } from "react-bootstrap";

class ProgressBar extends React.Component {
  render() {
    const { activeTab = "" } = this.props;
    return (
      <React.Fragment>
        <div className="row bs-wizard">
          <Col
            xs={4}
            sm={4}
            md={4}
            href="#menu1"
            className={
              activeTab === "details" ||
                activeTab === "review" ||
                activeTab === "payment"
                ? "bs-wizard-step complete"
                : "bs-wizard-step active"
            }
          >
            <div className="progress">
              <div className="progress-bar" />
            </div>
            <div className="bs-wizard-dot" />
            <div className="bs-wizard-info text-center">
              Booking
            </div>
          </Col>

          <Col
            xs={4}
            sm={4}
            md={4}
            href="#menu2"
            className={
              activeTab === "review" || activeTab === "payment"
                ? "bs-wizard-step complete"
                : "bs-wizard-step active"
            }
          >
            <div className="progress">
              <div className="progress-bar" />
            </div>
            <div className="bs-wizard-dot" />
            <div className="bs-wizard-info text-center">Booking Review</div>
          </Col>

          <Col
            xs={4}
            sm={4}
            md={4}
            href="#menu3"
            className={
              activeTab === "payment"
                ? "bs-wizard-step complete"
                : "bs-wizard-step active"
            }
          >
            <div className="progress">
              <div className="progress-bar" />
            </div>
            <div className="bs-wizard-dot" />
            <div className="bs-wizard-info text-center">Payment</div>
          </Col>
        </div>
      </React.Fragment>
    );
  }
}

export default ProgressBar;
