import { apiPost, apiGet, getObject, apiPut, getUser } from "../utils";
import { apiKey, TokenId } from "../constants";

export function postSpecialFaresAPI(data) {
    let updatedData = {
        ...data,
        "TokenId": TokenId,
        "EndUserIp": getObject('ipaddress')
    }
    let newdata = JSON.stringify(updatedData);
    return apiPost(`/api/addspecialfare?appKey=${apiKey}`, newdata);
}

export function getSpecialFaresListingAPI(token) {
    return apiGet(`/api/getspecialfare?appKey=${apiKey}&token=${token}`)
}


export function editSpecialFareAPI(data) {
    let newData = JSON.stringify(data);
    let token = getUser() && getUser().token ? getUser().token : "";
    return apiPut(`/api/editgetspecialfare?appKey=${apiKey}&token=${token}`, newData)
}


export function postMultipleSpecialFaresAPI(data) {
    let updatedData = {
        ...data,
        "TokenId": TokenId,
        "EndUserIp": getObject('ipaddress')
    }
    let newdata = new FormData();
    Object.keys(updatedData).forEach((item) => {
        newdata.append(item, updatedData[item])
    })
    return apiPost(`/api/multiplespecialfare?appKey=${apiKey}`, newdata);
}
