//File Name: TopNotificationBar.js
//Path: src/app/coomponents/common
//Description: This file is used to show contact details on Top.
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const TopNotificationBar = () => (
  <div>
    {/* <Container>
      <Row>
        <Col xs={3} sm={3} md={3}>
          Call Us Now: +91-141-4101991
        </Col>
        <Col xs={4} sm={4} md={4}>
          Email: info@prefectdeparture.com
        </Col>
        <Col>Office Hours: Mon-Sat 10.00-20.00.Sunday CLOSED</Col>
      </Row>
    </Container> */}
  </div>
);

export default TopNotificationBar;
