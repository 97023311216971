import React, { Component } from 'react';
import {
    Col,
    Popover,
    OverlayTrigger
} from "react-bootstrap";

class PassengerTypes extends Component {
    state = {}
    render() {
        const {
            responsive = false,
            onChangeRadioButtonPassenger,
            Type = [],
            passengertype
        } = this.props;
        return (
            <React.Fragment>
                {Type.map((typesLabel, i) => {
                    return (
                        <React.Fragment key={i}>
                            <li
                                className="list-style-radio-btn tooltip_custom"
                            >
                                {responsive ? (
                                    <label className="customradio">
                                        <input
                                            id={typesLabel && typesLabel.value}
                                            type="checkbox"
                                            name="types"
                                            checked={
                                                (typesLabel && typesLabel.value) === (passengertype && passengertype.value)
                                            }
                                            onChange={() =>
                                                onChangeRadioButtonPassenger(
                                                    typesLabel,
                                                    "passengertype"
                                                )
                                            }
                                            inline="true"
                                        />

                                        <span className="radiotextsty">
                                            {" "}
                                            {typesLabel && typesLabel.value}
                                        </span>
                                        <span className="checkmark" />
                                    </label>
                                ) :
                                    (
                                        <label className="customradio">
                                            <input
                                                id={typesLabel && typesLabel.value}
                                                type="checkbox"
                                                name="types"
                                                checked={
                                                    (typesLabel && typesLabel.value) === (passengertype && passengertype.value)
                                                }
                                                onChange={() =>
                                                    onChangeRadioButtonPassenger(
                                                        typesLabel,
                                                        "passengertype"
                                                    )
                                                }
                                                inline="true"
                                            />
                                            <OverlayTrigger
                                                trigger="hover"
                                                key={i}
                                                overlay={
                                                    <Popover
                                                        id={`popover-positioned-${"top"}`}
                                                        className={typesLabel && typesLabel.value == "Normal" ? "d-none" : ""}
                                                        title={typesLabel && typesLabel.label}
                                                    >
                                                        {typesLabel && typesLabel.tooltipContent}
                                                    </Popover>
                                                }
                                            >


                                                <span className="radiotextsty">
                                                    {" "}
                                                    {typesLabel && typesLabel.value}
                                                </span>
                                            </OverlayTrigger>
                                            <span className="checkmark" />
                                        </label>
                                    )
                                }
                            </li>
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    }
}

export default PassengerTypes;