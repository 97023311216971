 
export const GET_HOTEL_DestinationCityList_RESULT_SUCCESS = "GET_HOTEL_DestinationCityList_RESULT_SUCCESS"
export const GET_HOTEL_DestinationCityList_RESULT_FAILED = "GET_HOTEL_DestinationCityList_RESULT_FAILED"

export const GET_HOTEL_SEARCH_RESULT = "GET_HOTEL_SEARCH_RESULT"
export const GET_HOTEL_SEARCH_RESULT_SUCCESS = "GET_HOTEL_SEARCH_RESULT_SUCCESS"
export const GET_HOTEL_SEARCH_RESULT_FAILED = "GET_HOTEL_SEARCH_RESULT_FAILED"

export const GET_HOTEL_DETAILS = "GET_HOTEL_DETAILS";
export const GET_HOTEL_DETAILS_SUCCESS = "GET_HOTEL_DETAILS_SUCCESS"
export const GET_HOTEL_DETAILS_FAILED = "GET_HOTEL_DETAILS_FAILED"