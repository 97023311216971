import React, { Component } from "react";
import { Button } from "react-bootstrap";

class Counter extends Component {
  onChange = (value ,e) => {
    const { name, onChange} = this.props;

    if (name === "adults" && value < 1) {
      return;
    }

    if (value < 0) {
      return;
    }

    onChange(name, value ,e);
  };

  render() {
    const { maxValue, persons, name, title, age = "" } = this.props;

    return (
      <React.Fragment>
        <li className="adult-pax-list">
          <label>
            {title}
            <p>{`${age}`}</p>
          </label>
          <div className="no-of-counts">
            <Button
              onClick={(e) => this.onChange(persons[name] - 1, e)}
              disabled={!persons[name]}
              className={
                !persons[name] ? "disabled_counter_button" : "pax-minus"
              }
            >
              <span className="fal fa-minus" />
            </Button>
            <span className="counter adult-pax"> {persons[name] || 0}</span>
            <Button
              onClick={(e) => this.onChange(persons[name] + 1, e)}
              disabled={persons[name] === maxValue}
              className="pax-plus "
            >
              <span className="fal fa-plus" />
            </Button>
          </div>
        </li>
      </React.Fragment>
    );
  }
}

export default Counter;
