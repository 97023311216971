import types from "../types";

const initialState = {
  fetching: false,
  offlineFetching: false,
  faresPrices: [],
  flightService: {},
  bookingDetails: {},
  loadingFlightReview: false,
  servicesFetch: false,
  fareQuotaFetching: false,
  bookingAfterPay: false,
  paymentStatus: false,
  bookingStatus: false,
  bookingAfterPayData: {},
  paymentOrderGenerated: {},
  errorMessage: "",
  sub_message: "",
  TBOfetch: false,
  inSufficientFunds: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_FARES_DATEWISE:
      return { ...state, fetching: true, faresPrices: [] };
    case types.GET_FARES_DATEWISE_SUCCESS:
      return {
        ...state,
        fetching: false,
        faresPrices: action.payload.info && action.payload.info.SearchResults
      };
    case types.GET_FARES_DATEWISE_FAILED:
      return { ...state, fetching: false, faresPrices: [] };

    //reducer used for flight booking
    case types.SET_BOOKING:
      return { ...state, loadingFlightReview: true }
    case types.SET_BOOKING_SUCCESS:
      return { ...state, bookingDetailsLCC: action.payload, loadingFlightReview: false }
    case types.SET_BOOKING_FAILED:
      return { ...state, loadingFlightReview: false }

    //reducer used for flight ticket booking
    case types.SET_TICKET_DETAILS:
      return { ...state, loadingFlightReview: true }
    case types.SET_TICKET_DETAILS_SUCCESS:
      return { ...state, bookingDetailsLCC: action.payload, loadingFlightReview: false }
    case types.SET_TICKET_DETAILS_FAILED:
      return { ...state, loadingFlightReview: false }

    //reducer used for payment transaction
    case types.MAKE_PAYMENT:
      return { ...state, fetching: true }
    case types.MAKE_PAYMENT_SUCCESS:
      return { ...state, fetching: false, paymentOrderGenerated: action.payload }
    case types.MAKE_PAYMENT_FAILED:
      return { ...state, fetching: false }

    //reducer used to handle baggage and meals 
    case types.SET_FLIGHT_SERVICE:
      return { ...state, servicesFetch: true }
    case types.SET_FLIGHT_SERVICE_SUCCESS:
      return { ...state, servicesFetch: false }
    case types.SET_FLIGHT_SERVICE_FAILED:
      return { ...state, servicesFetch: false }

    case types.SET_FARE_QUOTA:
      return { ...state, fareQuotaFetching: true }
    case types.SET_FARE_QUOTA_SUCCESS:
      return { ...state, fareQuotaFetching: false }
    case types.SET_FARE_QUOTA_FAILED:
      return { ...state, fareQuotaFetching: false }

    case types.TICKET_BOOKING_AFTER_PAYMENT:
      return { ...state, bookingAfterPay: true, bookingStatus: false }
    case types.TICKET_BOOKING_AFTER_PAYMENT_SUCCESS:
      return { ...state, bookingAfterPay: false, bookingStatus: true, bookingAfterPayData: action.payload }
    case types.TICKET_BOOKING_AFTER_PAYMENT_FAILED:
      
      return {
        ...state,
        bookingAfterPay: false,
        bookingStatus: false,
        bookingAfterPayData: (action.error && action.error.response && action.error.response.data) || {},
        errorMessage: action.error.response.data.message,
        sub_message: (action.error.response.data.sub_message) || ""
      }


    case types.PAYMENT_SUCCESS_ORDER:
      return { ...state, fetching: true, paymentStatus: false }
    case types.PAYMENT_SUCCESS_ORDER_SUCCESS:
      return { ...state, fetching: false, paymentStatus: true }
    case types.PAYMENT_SUCCESS_ORDER_FAILED:
      return {
        ...state,
        fetching: false,
        paymentStatus: false,
        bookingAfterPayData: (action && action.error && action.error.response && action.error.response.data && action.error.response.data.info && action.error.response.data.info[0]) || {},
        errorMessage: (action && action.error && action.error.response && action.error.response.data && action.error.response.data.message) || "",
      }

    case types.CHECK_TBO_BALANCE:
      return { ...state, TBOfetch: true }
    case types.CHECK_TBO_BALANCE_SUCCESS:
      return { ...state, TBOfetch: false }
    case types.CHECK_TBO_BALANCE_FAILED:
      return { ...state, TBOfetch: false }

    case types.INSUFFICIENT_FUNDS:
      return { ...state, inSufficientFunds: true }
    case types.INSUFFICIENT_FUNDS_SUCCESS:
      return { ...state, inSufficientFunds: false }
    case types.INSUFFICIENT_FUNDS_FAILED:
      return { ...state, inSufficientFunds: false }


    case types.REQUEST_OFFLINE_PAYMENT:
      return { ...state, offlineFetching: true }
    case types.REQUEST_OFFLINE_PAYMENT_SUCCESS:
      return { ...state, offlineFetching: false }
    case types.REQUEST_OFFLINE_PAYMENT_FAILED:
      return { ...state, offlineFetching: false }


    default:
      return state;
  }
};
