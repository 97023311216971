import React, { Component } from 'react';
import actions from '../../actions'
import { connect } from 'react-redux';
import classnames from "classnames"

class CancellationRules extends Component {
    state = {
        data: []
    }
    componentDidMount() {
        const { item, traceId, international } = this.props;

        let data = {
            "ResultIndex": item.id,
            "TraceId": traceId,
            "airlineCode": item.AirlineCode,
            "international": international,
            "Source": item.Source,
            "specialfare":item.specialfare
        }
        this.props.dispatch(actions.getFlightFareRule(data)).then(res => {

            if (res && res.info && res.info.FareRules) {
                this.setState({ data: res.info.FareRules })
            }
        }).catch(err => {
        })
    }

    render() {
        const { data = [] } = this.state;
        const { fetching, isOneWay = false } = this.props;
        return (
            <React.Fragment>
                {
                    fetching ? <img style={{ height: "50px", width: "50px" }} src={require('../../assets/loader.gif')} alt="not found" /> :
                        <React.Fragment>
                            {data && data.length ? data.map((val, index) => {
                                return <div key={index}>
                                    <h6>Cancellation & Modify Fees {(val && val.Origin) || ""} - {(val && val.Destination) || ""}<span> per passenger per sector</span></h6>
                                    <pre className={classnames("cancellation-fee-con", { 'one-way': isOneWay })} dangerouslySetInnerHTML={{ __html: '<p>' + val.FareRuleDetail + '</p>' }}></pre>
                                </div>
                            }) : <div>
                                    No data found
                  </div>
                            }
                        </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    getSearchResult: state.getSearchResult,
});

export default connect(mapStateToProps)(CancellationRules);