export const ADD_WALLET_MONEY = "ADD_WALLET_MONEY";
export const ADD_WALLET_MONEY_SUCCESS = "ADD_WALLET_MONEY_SUCCESS";
export const ADD_WALLET_MONEY_FAILED = "ADD_WALLET_MONEY_FAILED";

export const ADDED_WALLET_MONEY = "ADDED_WALLET_MONEY";
export const ADDED_WALLET_MONEY_SUCCESS = "ADDED_WALLET_MONEY_SUCCESS";
export const ADDED_WALLET_MONEY_FAILED = "ADDED_WALLET_MONEY_FAILED";

export const GET_WALLET_DETAILS = "GET_WALLET_DETAILS";
export const GET_WALLET_DETAILS_SUCCESS = "GET_WALLET_DETAILS_SUCCESS";
export const GET_WALLET_DETAILS_FAILED = "GET_WALLET_DETAILS_FAILED";

export const GET_PAYMENTMODE_BANKDETAILS = "GET_PAYMENTMODE_BANKDETAILS";
export const GET_PAYMENTMODE_BANKDETAILS_SUCCESS = "GET_PAYMENTMODE_BANKDETAILS_SUCCESS";
export const GET_PAYMENTMODE_BANKDETAILS_FAILED = "GET_PAYMENTMODE_BANKDETAILS_FAILED";

export const GET_PAYMENTMODE_OFFLINE_PAYMENT = "GET_PAYMENTMODE_OFFLINE_PAYMENT";
export const GET_PAYMENTMODE_OFFLINE_PAYMENT_SUCCESS = "GET_PAYMENTMODE_OFFLINE_PAYMENT_SUCCESS";
export const GET_PAYMENTMODE_OFFLINE_PAYMENT_FAILED = "GET_PAYMENTMODE_OFFLINE_PAYMENT_FAILED";
