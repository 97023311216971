import { apiPost, getObject } from "../utils";
import { apiKey } from "../constants"


export function getHotelDestinationCityListsAPI(payload) {
    const updatedData = JSON.stringify({ CountryCode: payload })
    return apiPost(`/api/destinationCityList?appKey=${apiKey}`, updatedData)
}

export function getHotelResultAPI(payload) {

    const updatedData = JSON.stringify({
        ...payload,
        "EndUserIp": getObject('ipaddress'),
        //"CheckInDate": "28/06/2020",
        // "token":"",
        //"isDomestic":true
        "testData": true 
    })

    return apiPost(`/api/searchHotels?appKey=${apiKey}`, updatedData)
}

export function getHotelDetailAPI(payload) {
    const updatedData = JSON.stringify({
        ...payload,
        "EndUserIp": getObject('ipaddress'),
        // "testData": true
    })
    return apiPost(`/api/getHotelDetail?appKey=${apiKey}`, updatedData)
}


