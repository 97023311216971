import types from "../types";
import { getFeaturedFlightsAPI, getFeaturedHotelsAPI,getFeaturedOffersAPI } from "../api/featuredSection";

export const getFeaturedFlights = payload => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.GET_FEATURED_FLIGHTS
        });
        getFeaturedFlightsAPI(payload)
            .then(res => {
                dispatch({
                    type: types.GET_FEATURED_FLIGHTS_SUCCESS,
                    payload: res
                });
                return resolve(res);
            })
            .catch(err => {
                dispatch({
                    type: types.GET_FEATURED_FLIGHTS_FAILED
                });
                return reject(err);
            });
    });

export const getFeaturedHotels = payload => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.GET_FEATURED_HOTELS
        });
        getFeaturedHotelsAPI(payload)
            .then(res => {
                dispatch({
                    type: types.GET_FEATURED_HOTELS_SUCCESS,
                    payload: res
                });
                return resolve(res);
            })
            .catch(err => {
                dispatch({
                    type: types.GET_FEATURED_HOTELS_FAILED
                });
                return reject(err);
            });
    });


    export const getFeaturedOffers = payload => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.GET_FEATURED_OFFERS
        });
        getFeaturedOffersAPI(payload)
            .then(res => {
                dispatch({
                    type: types.GET_FEATURED_OFFERS_SUCCESS,
                    payload: res
                });
                return resolve(res);
            })
            .catch(err => {
                dispatch({
                    type: types.GET_FEATURED_HOTELS_FAILED
                });
                return reject(err);
            });
    });