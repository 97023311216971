import { apiPost, getObject, getUser, evaluateJourneyType } from "../utils";
import { apiKey, TokenId } from "../constants";

export function getFlightResultsAPI(payload) {
  const { iternary = [] } = payload;
  let data = [];

  let ResultFareType=2;

  if(payload.passengertype == "Student"){
    ResultFareType=3
  }

  data = iternary && iternary.length ? iternary.map((item, index) => {
    return {
      "Origin": item.from.iata || "",
      "Destination": item.to.iata || "",
      "FlightCabinClass": payload.class,
      "PreferredDepartureTime": `${item.date}T00:00:00`,
      "PreferredArrivalTime": `${item.date}T00:00:00`
    }
  }) : null

  const updatedData = JSON.stringify({
    "EndUserIp": getObject('ipaddress'),
    "AdultCount": parseInt(payload.adults) || 0,
    "ChildCount": parseInt(payload.children) || 0,
    "InfantCount": parseInt(payload.infants) || 0,
    "DirectFlight": "false",
    "OneStopFlight": "false",
    "JourneyType": evaluateJourneyType(payload.trip),
    "PreferredAirlines": null,
    "Segments": data,
    "TokenId": TokenId,
    "token": getUser() && getUser().token ? getUser().token : "",
    "isDomestic": payload.international === "false" ? true : false,
    "defence": payload && payload.passengertype === "Armed Forces" ? 1 : 0,
    "testData": true,
    "ResultFareType":ResultFareType,
  })



  return apiPost(`/api/searchflight?appKey=${apiKey}`, updatedData)
}

export function getFlightFareRuleAPI(data) {
  return apiPost(`/api/farerule?appKey=${apiKey}`, {
    ...data,
    "token": getUser() && getUser().token ? getUser().token : "",
    "TokenId": TokenId,
    "EndUserIp": getObject('ipaddress'),
    "isDomestic": data && data.international === "false" ? true : false
  });
}



