//File Name: Flights.js
//Path: src/app/coomponents/homepage
//Description: This file is used for flights searchs.
import React, { Component } from "react";
import FlightsForm from "./FlightsForm";
import moment from "moment";
import actions from "../../../actions";
import { connect } from "react-redux";
import {
  getOnlyIATAAirports,
  handleLoadAirportOptions,
  multiCityDateHandler,
  handleFlightSearchQueryParams,
  convertSearchObjectMulticity,
  evaluateTripType
} from "../../../utils";

const city = [
  {
    from: {},
    to: {},
    date: moment()
  },
  {
    from: {},
    to: {},
    date: moment()
  }
];


class Flights extends Component {
  state = {
    flightsObj: {
      status: "One way",
      iternary: [],
      class: "1",
      adults: 1,
      children: 0,
      infants: 0,
      passengertype: {
        id: 0,
        value: "Normal",
        label: "Normal",
        tooltipContent: "Normal",
        ResultFareType:2
      },
    },
    focusedInput: null,
    airports: [],
    passengerShow: false,
    errors: {},
    errorToFrom: false,
    focusedSinglePicker: null
  };

  componentDidMount = () => {
    let iataAirports = getOnlyIATAAirports();
    this.setState({
      airports: iataAirports,
      flightsObj: {
        ...this.state.flightsObj,
        iternary: [city[0]]
      }
    });
    // this.minimunFare(flights);
  };


  //Function Name: minimunFare
  //Parameters: value
  //Description: This function is used to send api hit to get minimum fare in calender.
  minimunFare = (value) => {
    if ((value[0].from && value[0].from.city) && (value[0].to && value[0].to.city)) {
      let data = {
        "PreferredDepartureTime": moment(value[0].date).format("YYYY-MM-DDTHH:mm:ss"),
        "Origin": value[0].from.iata,
        "Destination": value[0].to.iata,
        "FlightCabinClass": "1"
      }

      this.props.dispatch(
        actions.getFaresInfo(data)
      );
    }
    else {
      return
    }
  }

  //Function Name: minimunFare
  //Parameters: value
  //Description: This function is used to send api hit to get minimum fare in calender.
  minimunFareMonth = (value) => {
    const { flightsObj } = this.state;
    const { iternary } = { ...flightsObj }

    if ((iternary[0].from && iternary[0].from.city) && (iternary[0].to && iternary[0].to.city)) {
      let data = {
        "PreferredDepartureTime": moment(value).format("YYYY-MM-DDTHH:mm:ss"),
        "Origin": iternary[0].from.iata,
        "Destination": iternary[0].to.iata,
        "FlightCabinClass": "1"
      }

      this.props.dispatch(
        actions.getFaresInfo(data)
      );
    }
    else {
      return
    }
  }

  //Function Name: multicitydates
  //Parameters: date,key,name
  //Description: This function is used to change multicity datesFocus.
  multicitydates = (val, key, name) => {
    const { flightsObj } = this.state;
    const { iternary } = { ...flightsObj }

    let updatedData = multiCityDateHandler(val, key, name, iternary);
    let error = false;
    if (updatedData[0].from.iata === updatedData[0].to.iata) {
      error = true
    }
    this.setState({
      flightsObj: {
        ...flightsObj,
        iternary: updatedData
      },
      errorToFrom: error
    })
  };

  //Function Name: addcities
  //Parameters: null
  //Description: This function is used to add multicity cities.
  addcities = () => {
    const { flightsObj } = this.state;
    const { iternary } = { ...flightsObj }

    let data = {
      from: iternary[iternary.length - 1].to,
      to: null,
      date: iternary[iternary.length - 1].date
    };
    iternary.push(data);
    this.setState({
      flightsObj: {
        ...flightsObj,
        iternary
      }
    });
  };

  //Function Name: delcities
  //Parameters: key
  //Description: This function is used to delete multicity cities.
  delcities = key => {
    const { flightsObj } = this.state;
    const { iternary = [] } = { ...flightsObj }

    iternary.splice(key, 1);
    this.setState({
      flightsObj: {
        ...flightsObj,
        iternary
      }
    });
  };

  //Function Name: handleShowPassenger
  //Parameters: handleShowPassenger
  //Description: This function is used to show passenger popover.
  handleShowPassenger = passengerShow => {
    this.setState({ passengerShow: !this.state.passengerShow });
  };

  //Function Name: blockedDates
  //Parameters: null
  //Description: This function is used to block past days.
  blockedDates = (date, key) => {
    const { flightsObj } = this.state;
    const { iternary } = { ...flightsObj }

    let data;
    if (key === 0) return;
    else
      data = iternary[key - 1].date;
    return date.isBefore(data.valueOf());
  };

  //Function Name: handleChange
  //Parameters: e,val
  //Description: This function is used to change flights searches.
  handleChange = (data, key) => {
    const { flightsObj } = this.state;
    const { iternary } = { ...flightsObj }
    let updatedData = [...iternary];
    let { city, iata, country } = data;

    if (data == null) {
      return null;
    }

    data = {
      "country": country,
      "city": city,
      "iata": iata,
      label: (
        <React.Fragment>
          <strong className="city-name">{city} </strong>
          <span className="des"> ({iata}) </span>
        </React.Fragment>
      )
    };

    if (key === "from") {
      updatedData[0] = { ...updatedData[0], from: data }
      if (updatedData.length === 2) {
        updatedData[1] = { ...updatedData[1], to: data }
      }
    }

    if (key === "to") {
      updatedData[0] = { ...updatedData[0], to: data }
      if (updatedData.length === 2) {
        updatedData[1] = { ...updatedData[1], from: data }
      }
    }

    let from = updatedData[0] && updatedData[0].from && updatedData[0].from.iata
    let to = updatedData[0] && updatedData[0].to && updatedData[0].to.iata
    let error = (from) === (to) ? true : false;

    this.setState({
      flightsObj: {
        ...flightsObj,
        iternary: [...updatedData]
      },
      errorToFrom: error
    }, () => { this.minimunFare(updatedData) });
  };

  //Function Name: onchangeFocus
  //Parameters: event,key
  //Description: This function is used to Hide To and from error.
  onchangeFocus = (e, key) => {
    this.setState({
      errorToFrom: false
    })
  }

  //Function Name: onchangeBlur
  //Parameters: event,key
  //Description: This function is used to Show To and from error.
  onchangeBlur = (e, key) => {
    const { flightsObj } = this.state;
    const { iternary } = { ...flightsObj }
    let from = iternary[0] && iternary[0].from && iternary[0].from.hasOwnProperty("iata")
    let to = iternary[0] && iternary[0].to && iternary[0].to.hasOwnProperty("iata")
    if (from && to) {
      let fromIata = iternary[0] && iternary[0].from.iata;
      let toIata = iternary[0] && iternary[0].to.iata;
      let error = fromIata === toIata;
      this.setState({ errorToFrom: error })
    }

  }

  handleChangeClass = (key) => {
    const { flightsObj } = this.state;

    this.setState({
      flightsObj: {
        ...flightsObj,
        class: key
      }
    })

  }

  //Function Name: onChangeDate
  //Parameters: start, end
  //Description: This function is used to change flights depart and return.
  onChangeDate = (start, end) => {
    const { flightsObj, focusedInput } = this.state;
    const { iternary } = { ...flightsObj }
    let updatedData = [...iternary];

    updatedData[0] = { ...updatedData[0], date: start };


    if (end && focusedInput === "endDate") {
      if (updatedData.length === 1) {
        updatedData[1] = { date: null, from: { ...updatedData[0].to }, to: { ...updatedData[0].from } }
      }
      updatedData[1] = { ...updatedData[1], date: end }
    }
    if (!end) {
      updatedData.splice(1, 1)
    }

    this.setState({
      flightsObj: {
        ...flightsObj,
        iternary: updatedData,
        status: end ? "Round trip" : "One way"
      },
    });
  };

  //Function Name: onFocusChange
  //Parameters: onFocusChange
  //Description: This function is used to change datesFocus.
  onFocusChange = focusedInput => {
    this.setState({ focusedInput });
  };

  //Function Name: clearReturnDate
  //Parameters:null
  //Description: This function is used to clear return date.
  clearReturnDate = () => {
    const { flightsObj } = this.state;
    const { iternary } = { ...flightsObj }
    let updatedData = [...iternary];

    updatedData = [{ ...updatedData[0] }];

    this.setState({
      flightsObj: {
        ...flightsObj,
        iternary: updatedData,
        status: "One way"
      },
    })
  }

  //Function Name: onChangeRadioButton
  //Parameters: value
  //Description: This function is used to change flights oneway,roundtrip.
  onChangeRadioButton = (value) => {
    const { flightsObj } = this.state;
    const { iternary } = { ...flightsObj }
    let updatedData = [...iternary];

    if (value === "One way") {
      updatedData = [{ ...updatedData[0] }];
    }

    if (value === "Round trip") {
      if (updatedData.length === 1) {

        updatedData[1] = { date: null, from: { ...updatedData[0].to }, to: { ...updatedData[0].from } }
      }

      updatedData[1] = { date: moment(updatedData[0].date).add(1, "day"), from: { ...updatedData[0].to }, to: { ...updatedData[0].from } }
      updatedData = [updatedData[0], updatedData[1]]
    }

    if (value === "Multi city") {

      if (updatedData.length === 1) {

        updatedData[1] = { date: null, from: { ...updatedData[0].to }, to: { ...updatedData[0].from } }
      }

      updatedData[1] = { ...updatedData[1], date: moment(updatedData[0].date).add(1, "day") }
      updatedData = [updatedData[0], updatedData[1]]
    }

    this.setState({
      flightsObj: {
        ...flightsObj,
        status: value,
        iternary: updatedData
      }

    });
  };

  //Function Name: handleChangePersons
  //Parameters: key,value
  //Description: This function is used to select Adults,children,Infants.
  handleChangePersons = (key, value) => {
    const { flightsObj } = this.state;
    const { passengertype } = { ...flightsObj };
    let data = { ...passengertype };
    let newPersons = {
      ...flightsObj,
      [key]: value
    };
    if (
      key === "adults" &&
      newPersons.children &&
      newPersons.children + value > 9
    ) {
      newPersons = {
        ...newPersons,
        children: newPersons.children - 1
      };
    }

    if (
      key === "adults" &&
      newPersons.infants &&
      newPersons.infants > newPersons.adults
    ) {
      newPersons = {
        ...newPersons,
        infants: newPersons.infants - 1
      };
    }
    //-------------
    if (passengertype.label === "Senior Citizen" && newPersons.children > 0) {
      data = {
        id: 0,
        value: "Normal",
        label: "Normal",
        tooltipContent: "Normal",
        ResultFareType:2,
      };
    } else if (
      passengertype.label === "Family & Friends" &&
      newPersons.adults + newPersons.children < 4
    ) {
      data = {
        id: 0,
        value: "Normal",
        label: "Normal",
        tooltipContent: "Normal",
        ResultFareType:2,
      };
    } else if (passengertype.label === "Student" && newPersons !== 0) {
      data = {
        id: 0,
        value: "Normal",
        label: "Normal",
        tooltipContent: "Normal",
        ResultFareType:3
      };
    } else if (passengertype.label === "Minor" && newPersons.adults >= 1) {
      data = {
        id: 0,
        value: "Normal",
        label: "Normal",
        tooltipContent: "Normal",
        ResultFareType:2
      };
    } else if (passengertype.label === "Minor" && newPersons.children == 0) {
      newPersons = {
        ...newPersons,
        adults: 1
      };
      data = {
        id: 0,
        value: "Normal",
        label: "Normal",
        tooltipContent: "Normal",
        ResultFareType:2
      };
    }


    this.setState({
      flightsObj: {
        ...newPersons,
        passengertype: data
      },
    });
  };

  //Function Name: onChangeRadioButtonPassenger
  //Parameters: value,name
  //Description: This function is used to select Person Types.
  onChangeRadioButtonPassenger = (value, name) => {

    const { flightsObj } = this.state;

    let updated = { ...flightsObj };

    if (value.label === "Normal") {
      updated = {
        ...updated,
        adults: 1,
        infants: 0
      };
    } else if (value.label === "Senior Citizen") {
      updated = {
        ...flightsObj,
        children: 0,
        adults: 1,
        infants: 0
      };
    } else if (
      value.label === "Family & Friends" &&
      flightsObj.adults + flightsObj.children < 4
    ) {
      updated = {
        ...flightsObj,
        adults: 4,
        children: 0
      };
    } else if (value.label === "Minor") {
      updated = {
        ...flightsObj,
        children: flightsObj.adults >= 1 ? 1 : 1,
        adults: 0,
        infants: 0
      };
    } else if (value.label === "Student") {
      updated = {
        ...flightsObj,
        children: 0,
        adults: 1,
        infants: 0
      };
    }
    else if (value.label === "Armed Forces") {
      updated = {
        ...flightsObj,
        children: 0,
        adults: 1,
        infants: 0
      };
    }

    this.setState({
      flightsObj: {
        ...updated,
        [name]: value,
      }
    });
  };

  //Function Name: loadOptions
  //Parameters: term,callback
  //Description: This function is used to find Airports autocomplete.
  loadOptions = (term, callback) => {
    const { airports } = this.state;
    handleLoadAirportOptions(term, callback, airports);
  };

  //Function Name: handleFarePrices
  //Parameters: date
  //Description: This function is used to show fare prices in calender.
  handleFarePrices = date => {
    const { faresPrices = [] } = this.props && this.props.getFaresInfo;

    if (date.isBefore(moment().subtract(1, "day"))) {
      return {};
    }
    const price = faresPrices && faresPrices.length && faresPrices.find((data, index) => {
      return (
        moment(data.DepartureDate).format("YYYY-MM-DD") === date.format("YYYY-MM-DD")
      );
    });

    if (price && price.Fare) {
      return {
        fare: parseInt(price.Fare) || 0,
        lowFare: (price && price.IsLowestFareOfMonth) || false,
        Currency_code: price && price.Currency_code
      }
    }
    return {}
  };

  //Function Name: handleSingleDatePicker
  //Parameters: date
  //Description: This function is used for One way date picker.
  handleSingleDatePicker = (date) => {
    const { flightsObj } = this.state;
    const { iternary } = { ...flightsObj }
    let updatedData = [...iternary];

    updatedData[0] = { ...updatedData[0], date: date };

    this.setState({
      flightsObj: {
        ...flightsObj,
        iternary: updatedData
      },
      focusedSinglePicker: false
    })
  }

  //Function Name: handleFocusSingleDatePicker
  //Parameters: none
  //Description: This function is used for Focused for Single date picker.
  handleFocusSingleDatePicker = (focus) => {
    this.setState({
      focusedSinglePicker: focus && focus.focused
    })
  }

  //Function Name: handleFocusArrivalPicker
  //Parameters: none
  //Description: This function is used for to change SingleDatePicker to DateRangePicker.
  handleFocusArrivalPicker = () => {
    const { flightsObj } = this.state;
    const { iternary } = { ...flightsObj }
    let updatedData = [...iternary];

    updatedData[1] = {
      date: moment(updatedData[0].date).add(1, "day"),
      from: { ...updatedData[0].to },
      to: { ...updatedData[0].from }
    }
    this.setState({
      focusedInput: "endDate",
      flightsObj: {
        ...flightsObj,
        status: "Round trip",
        iternary: updatedData
      }
    })
  }


  //Function Name: onRedirectToSearchPage
  //Parameters: null
  //Description: This function is used to search flights search page .
  onRedirectToSearchPage = () => {

    const { errorToFrom, flightsObj } = this.state;
    const { iternary } = { ...flightsObj };

    const showError = iternary[0].from.iata === iternary[0].to.iata;
    let personError = (flightsObj.adults + flightsObj.children + flightsObj.infants) < 10 ? true : false;
    let validations = !showError && personError && errorToFrom;


    let newData = convertSearchObjectMulticity(iternary);

    const internary = handleFlightSearchQueryParams(newData);


    const updatedData = {
      newIternary: newData,
      infants: flightsObj.infants,
      children: flightsObj.children,
      adults: flightsObj.adults,
      trip: flightsObj.status,
      class: flightsObj.class,
      passengertype: flightsObj.passengertype && flightsObj.passengertype.label
    };

    let international = "false";
    const { trip = {}, adults = '', infants = '', children = '', newIternary = [] } = updatedData;
    
    newIternary.map((item, index) => {
      if ((item.from.country !== "India") || (item.to.country !== "India")) {
        international = "true"
      }
    })

    // hit api
    if (!validations) {
      this.props.history.push(`/flights/search?iternary=${internary}&tripType=${evaluateTripType(newData, trip)}&A=${adults}&C=${children}&I=${infants}&intl=${international}&cabinClass=${updatedData.class}&passengertype=${updatedData.passengertype}`)
      return
    }


    //----------------------------
    // let prepareMulticityInternary = [];
    // let object1 = {}




    //----------------------------------------------------
    // object1 = {
    //   "date": moment(date).format("YYYY-MM-DD"),
    //   "from": { iata: from.iata, country: from.country, city: from.city },
    //   "to": { iata: to.iata, country: to.country, city: to.city },
    // }

    // prepareMulticityInternary.push(object1)
    // if (status == "Round trip") {
    //   let object2 = {
    //     "date": moment(flights.return).format("YYYY-MM-DD"),
    //     "from": { iata: to.iata, country: to.country, city: to.city },
    //     "to": { iata: from.iata, country: from.country, city: from.city },
    //   }
    //   prepareMulticityInternary.push(object2)
    // }

    // if (status == "Multi city") {
    // validations = true;
    // international = "false";
    // prepareMulticityInternary = convertSearchObjectMulticity(multicity);
    //error handling
    //   prepareMulticityInternary.some((item, index) => {
    //     if (item.from.iata == item.to.iata) {
    //       validations = false;
    //       return true
    //     } else {
    //       validations = true
    //     }
    //   })
    //   //check in multicity if international
    //   prepareMulticityInternary.map((item, index) => {
    //     if ((prepareMulticityInternary[0].from.country != item.from.country) || (prepareMulticityInternary[0].from.country != item.to.country)) {
    //       international = "true"
    //     }
    //   })
    // }

    // const internary = handleFlightSearchQueryParams(prepareMulticityInternary);

    // if (validations) {
    //   const updatedData = {
    //     trip: status,
    //     from: flights.from,
    //     to: flights.to,
    //     date: moment(flights.date).format("YYYY-MM-DD"),
    //     return: flights.return ? moment(flights.return).format("YYYY-MM-DD") : null,
    //     class: flights.class,
    //     infants: persons.infants,
    //     children: persons.children,
    //     adults: persons.adults
    //   };
    //   this.props.dispatch(actions.autofillSearchParameters(updatedData));
    //   const { trip = '', adults = '', infants = '', children = '' } = updatedData;

    //   this.props.history.push(`/flights/search?iternary=${internary}&tripType=${evaluateTripType(prepareMulticityInternary, trip)}&A=${adults}&C=${children}&I=${infants}&intl=${international}&cabinClass=${updatedData.class}`)
    //   return;
    // }
  };


  render() {
    const {
      flights,
      airports,
      family,
      focusedInput,
      passengerShow,
      errors,
      errorToFrom,
      focusedSinglePicker,
      flightsObj
    } = this.state;

    const { faresPrices = [], fetching = false } = this.props.getFaresInfo;
    // let show = flights.from && flights.to && flights.from.iata === flights.to.iata;

    return (
      <FlightsForm
        {...this.props}
        flightsObj={flightsObj}
        handleChangeClass={this.handleChangeClass}
        onRedirectToSearchPage={this.onRedirectToSearchPage}
        handleChange={this.handleChange}
        onChangeRadioButtonPassenger={this.onChangeRadioButtonPassenger}
        handleFarePrices={this.handleFarePrices}
        faresPrices={faresPrices}
        onChangeDate={this.onChangeDate}
        onChangeRadioButton={this.onChangeRadioButton}
        loadOptions={this.loadOptions}
        onFocusChange={this.onFocusChange}
        multicitydates={this.multicitydates}
        addcities={this.addcities}
        delcities={this.delcities}
        blockedDates={this.blockedDates}
        handleChangePersons={this.handleChangePersons}
        handleShowPassenger={this.handleShowPassenger}
        clearReturnDate={this.clearReturnDate}
        onchangeBlur={this.onchangeBlur}
        onchangeFocus={this.onchangeFocus}
        handleFocusArrivalPicker={this.handleFocusArrivalPicker}
        handleSingleDatePicker={this.handleSingleDatePicker}
        handleFocusSingleDatePicker={this.handleFocusSingleDatePicker}
        minimunFareMonth={this.minimunFareMonth}
        errors={errors}
        passengerShow={passengerShow}
        show={errorToFrom}
        status={flightsObj && flightsObj.status}
        airports={airports}
        passengertype={flightsObj && flightsObj.passengertype}
        family={family}
        focusedInput={focusedInput}
        focusedSinglePicker={focusedSinglePicker}
        fetching={fetching}
        responsive={false}
      />
    );
  }
}

const mapStateToProps = state => ({
  getFaresInfo: state.getFaresInfo
});

export default connect(mapStateToProps, null, null, { withRef: true })(Flights);
