import * as auth from "./auth";
import * as getFaresInfo from "./getFaresInfo";
import * as getFlightsResult from "./getFlightResult";
import * as featuredSection from "./featuredSection";
import * as staticData from "./staticData";
import * as registerBusiness from "./registerBusiness";
import * as insurance from './insurance'
import * as manageBookings from './manageBookings';
import * as wallet from './wallet'
import * as specialFares from './specialFares'
import * as agentMarkup from './agentMarkup'
import * as profileSettings from './profileSettings';
import * as sendQuery from "./queryForm";
import * as hotels from "./hotels";
import * as coupons from "./coupons"

export default {
  ...auth,
  ...getFaresInfo,
  ...getFlightsResult,
  ...featuredSection,
  ...staticData,
  ...registerBusiness,
  ...insurance,
  ...manageBookings,
  ...wallet,
  ...specialFares,
  ...agentMarkup,
  ...profileSettings,
  ...sendQuery,
  ...hotels,
  ...coupons
};
