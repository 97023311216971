import { apiPost, apiGet } from "../utils";
import { apiKey } from "../constants";
import { isLoggedIn } from "../utils"

//Add Wallet money api
export function addWalletMoneyAPI(value) {
    let token = isLoggedIn();
    let data = JSON.stringify(value);
    return apiPost(`/api/orderwallet?appKey=${apiKey}&token=${token}`, data);
}

//Added wallet money Success Api
export function addedWalletMoneyAPI(value) {
    let token = isLoggedIn();
    let data = JSON.stringify(value);
    return apiPost(`/api/addwallet?appKey=${apiKey}&token=${token}`, data);
}

//Get Wallet Details
export function getWalletDetailsAPI() {
    let token = isLoggedIn();
    return apiGet(`/api/getwallet?appKey=${apiKey}&token=${token}`);
}

//Get Filtered Details
export function getFilteredWalletDetailsAPI(value) {
    let token = isLoggedIn();
    return apiGet(`/api/getwallet?appKey=${apiKey}&token=${token}&from=${value.startDate}&to=${value.endDate}&search=${value.search}&start=${value.start}&limit=${value.limit}`);
}

//payment Mode Api 
export function getBankDetailsAPI(value) {
    let token = isLoggedIn();
    return apiGet(`/api/banks?appKey=${apiKey}&token=${token}&type=${value}`)
}

export function offlinePaymentAPI(value) {
    let token = isLoggedIn();
    let data = JSON.stringify(value);
    return apiPost(`/api/offline?appKey=${apiKey}&token=${token}`, data)
}
