import types from "../types";

const initialState = {
  fetching: false,
  fetchingResults: false,
  onwardFlights: [],
  returnedFlights: [],
  autoFillObject: {},
  flightSearchState: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_FLIGHT_SEARCH_RESULT:
      return { ...state, fetchingResults: true };
    case types.GET_FLIGHT_SEARCH_RESULT_SUCCESS:
      return {
        ...state,
        onwardFlights: [
          action &&
          action.payload &&
          action.payload.info &&
          action.payload.info.onwardFlights,
          
        ],
        returnedFlights: [
          action &&
          action.payload &&
          action.payload.info &&
          action.payload.info.returnedFlights
        ],
        fetchingResults: false
      };
    case types.GET_FLIGHT_SEARCH_RESULT_FAILED:
      return { ...state, fetchingResults: false };

    case types.GET_SEARCH_OBJECT:
      return { ...state, autoFillObject: { ...action.payload } };

    case types.GET_FLIGHT_FARE_RULE:
      return { ...state, fetching: true };
    case types.GET_FLIGHT_FARE_RULE_SUCCESS:
      return { ...state, fetching: false };
    case types.GET_FLIGHT_FARE_RULE_FAILED:
      return { ...state, fetching: false };
    case types.SET_COMPLETE_STATE:
      return { ...state, flightSearchState: action.payload }
    default:
      return state;
  }
};
