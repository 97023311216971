//File Name:Header.js
//Path: src/app/coomponents/common
//Description: This file is used for Header.
import React, { Component } from "react";
import { Nav, NavDropdown, Col, Row, Navbar, Badge } from "react-bootstrap";
import { getUser, logOut, isAgent, isLoggedIn, checkVerticalScroll, currencySign } from "../../utils";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import actions from '../../actions';
import { isEmpty } from "lodash";
import { toast, ToastContainer } from "react-toastify";

class Header extends Component {


  componentDidMount() {
    //document.addEventListener("scroll", this.trackScrolling);
    if (isLoggedIn() && !(this.props.wallet && this.props.wallet.wallet && this.props.wallet.wallet.hasOwnProperty("wallet"))) {
      this.props.dispatch(actions.getWalletDetails());
      this.props.dispatch(actions.getUserProfile(isLoggedIn()))
    }
  }
 
  // componentWillUnmount() {
  //   toast.success("Logout Successful", {
  //     position: toast.POSITION.TOP_RIGHT
  //   });
  // }

  /*   trackScrolling = () => {
      const header = document.getElementById("sticky-header");
      var sticky = header && header.offsetTop;
      if (checkVerticalScroll() && header) {
        if (window.pageYOffset > sticky) {
          header && header.classList.add("sticky")
        }
        else {
          header && header.classList.remove("sticky")
        }
      }
    } */

  Logout = async() => {
    await toast.success("Logout Successful", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose:3000
    });
    await logOut();
    await this.props.dispatch(actions.EmptyLoggedinUser());
    await setTimeout(() => {  
      this.props.history.push("/");
    },3000);

  }

  render() {
    const { isShowingMenu = false } = this.props;
    const { wallet = {} } = this.props && this.props.wallet && this.props.wallet.wallet || {};
    const { userInfo = {} } = this.props.profileSettings;


    return (
      <Nav
        justify
        variant="pills"
        defaultActiveKey="2"
        className="header"
        fixed="top"
      // id="header"
      >
        <Col xs={12} sm={12} md={2} lg={2} className="nopadding">
          <div className="header-logo">
            <a onClick={() => this.props.history.push("/")}>
              <img src={require("../../assets/logo.png")} />
            </a>
          </div>
        </Col>
        <Col xs={12} sm={12} md={10} lg={10} className="nopadding display-none">
          {isShowingMenu ? (
            <React.Fragment >
              <ul className="list-style pull-right">
                {/* <li>
                  <Nav.Link>Contact</Nav.Link>
                </li> */}
                <li style={{ marginRight: "8px" }}>
                  <NavDropdown
                    title={isLoggedIn() ?
                      <React.Fragment>
                        {
                          !isAgent() ? <React.Fragment>
                            <span className="bage-style">{`${userInfo && userInfo.info && userInfo.info.first_name && userInfo.info.first_name.trim()}`.substr(0, 1)} </span>
                            <span> Hey {userInfo && userInfo.info && userInfo.info.first_name} {(userInfo && userInfo.info && userInfo.info.ai_title) ? `(${userInfo.info.ai_title})` : ""}</span>
                          </React.Fragment>
                            :
                            <React.Fragment>
                              <span className="bage-style">{`${userInfo && userInfo.info && userInfo.info.agency_name && userInfo.info.agency_name.trim()}`.substr(0, 1)}</span>
                              <span> Hey {userInfo && userInfo.info && userInfo.info.agency_name} {(userInfo && userInfo.info && userInfo.info.ai_title) ? `(${userInfo.info.ai_title})` : ""}</span>
                            </React.Fragment>
                        }
                      </React.Fragment>
                      : "My Account"
                    }
                    id="nav-dropdown"
                  >

                    {isLoggedIn() ? (
                      <ul>
                        {/* <li>
                          {!isAgent() ? <React.Fragment>
                            <span>{data && data.info && data.info.first_name} </span>
                            <span className="bage-style">{`${data && data.info && data.info.first_name}`.substr(0, 1)}</span>
                          </React.Fragment>
                            :
                            <React.Fragment>
                              <span>{data && data.info && data.info.agency_name} </span>
                              <span className="bage-style">{`${data && data.info && data.info.agency_name}`.substr(0, 1)}</span>
                            </React.Fragment>
                          }
                        </li> */}
                        <li onClick={() => this.props.history.push("/manage-bookings")} className='cursor-pointer'>
                          <a>Manage bookings</a>
                        </li>
                        {
                          (isAgent() && (getUser().info && getUser().info.special_fare) == "1") ? <li onClick={() => this.props.history.push("/special-fares")} className='cursor-pointer'>
                            <a>Special Fares</a>
                          </li> : null
                        }
                        <li onClick={() => this.props.history.push("/profile-settings")} className='cursor-pointer'>
                          <a>My Profile</a>
                        </li>
                        <li onClick={() => this.Logout()} className='cursor-pointer'>
                          <a >
                            Logout
                          </a>
                        </li>
                      </ul>
                    ) : (
                        <ul>
                          <li >
                            <NavLink activeClassName="visited" to="/signup">
                              Sign Up
                          </NavLink>
                          </li>
                          <li  >
                            <NavLink activeClassName="visited" to="/login">
                              Sign In
                          </NavLink>
                          </li>
                          <li  >
                            <NavLink activeClassName="visited" to="/manage/bookings">
                              My Trips
                          </NavLink>
                          </li>
                        </ul>
                      )}
                  </NavDropdown>
                </li>

                {
                  isLoggedIn() ? <React.Fragment>
                    {
                      isAgent() ? <React.Fragment>
                        <li className="wallet-icon-img box-shadow-menu">
                          <Link to="/my-wallet" className="nav-link wallet-header">
                            <i className="fal fa-credit-card wallet-icon"></i>
                            <div className="wallet-amt">
                              {wallet && wallet.currency_code ? <span>{currencySign(wallet && wallet.currency_code)} -{wallet && wallet.due_amount}
                              </span> : null}
                              {/* <small>in your wallet</small> */}
                            </div>
                          </Link>
                        </li>
                      </React.Fragment> : null
                    }
                    <li className="wallet-icon-img box-shadow-menu">
                      <Link to="/my-wallet" className="nav-link wallet-header">
                        <i className="fas fa-wallet wallet-icon"></i>
                        <div className="wallet-amt">
                          {wallet && wallet.currency_code ? <span>{currencySign(wallet && wallet.currency_code)} {(wallet && wallet.amount) || 0}
                          </span> : 0}
                          <small>in your wallet</small>
                        </div>
                      </Link>
                    </li>
                  </React.Fragment>
                    : <li>
                      <Link to="/register-business" className="nav-link">
                        Register for Business
                  </Link>
                    </li>
                }
              </ul>
            </React.Fragment>
          ) : null}
        </Col>
        <ToastContainer autoClose={5000} />
      </Nav>
    );
  }
}

const mapStateToProps = state => ({
  wallet: state.wallet,
  auth: state.auth,
  profileSettings: state.profileSettings
});

export default connect(mapStateToProps)(Header);
