import React from 'react';
import styled from 'styled-components';
import { ThemeColor } from "../../../../../constants";


const CounterWrapper = styled.div`
   
    li{
        font-size: 12px;
        .form-control {
            height: 30px;
            padding: 0 16px 0 6px;
            font-size: 12px;
        } 
        .child-age-list {
            justify-content: space-between;
            li{
                width:auto;
                .selectdiv:after {
                    color: ${ThemeColor.secondary};
                    right: 6px;
                    top: 8px;
                    font-size: 14px;
                }
            }
        }
        .room-close-btn {
            color: #ffffff;
            position: absolute;
            right: 0;
            padding: 0;
            top: 0;
            border-radius: 50%;
            height: 16px;
            width: 16px;
            background: ${ThemeColor.primary};
            border: none;
            box-shadow: none;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            i.fa.fa-times{
                font-size: 12px;
            }
        }
        &.hotel-passenger-count {
            background: #f9f9f9;
            padding: 12px;
            margin: 12px 0 ; 
        }
       .selectdiv:after {
             color: ${ThemeColor.secondary};
             right: 6px;
             top: 8px;
             font-size: 14px;
        }
        &:hover{
            .room-close-btn {
                opacity: 1;
            }
        }
        &.selectdiv:after {
            right: 16px;
            font-size: 16px;
        }
    }

`

const Style = props => {

    const { className = "", color = '', onClick = () => { }, block = false, text = '', disabled = false, children } = props || {};

    return (
        <CounterWrapper className={className}>
            {children}
        </CounterWrapper >
    )
};

export default Style;
