import React, { Component } from "react";
import { slide as Menu } from "react-burger-menu";
import { isAgent, getUser, logOut, isLoggedIn } from "../../utils";
import { connect } from "react-redux";
import actions from '../../actions';

class Sidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }

  changeRoute = (path, val) => {
    this.setMenuIsOpen(val);
    this.props.history.push(path);
  }

  onStateChange = (state) => {
    this.setMenuIsOpen(state.isOpen);
  }

  setMenuIsOpen(isOpen) {
    this.setState({
      isOpen
    });
  }

  LogOut = () => {
    this.props.dispatch(actions.EmptyLoggedinUser());
    logOut();
  }

  render() {
    const { wallet = {} } = (this.props && this.props.wallet && this.props.wallet.wallet) || {}
    const data = getUser();

    const { isOpen = false } = this.state;

    return (
      <React.Fragment>
        <Menu isOpen={isOpen} onStateChange={this.onStateChange} {...this.props} >
          <ul className="nav-list-style">
            {
              isLoggedIn() ? <React.Fragment>
                <li>
                  <div className="login-style">
                    {
                      !isAgent() ? <React.Fragment>
                        <span className="bage-style">{`${data && data.info && data.info.first_name && data.info.first_name.trim()}`.substr(0, 1)} </span>
                        <span> Hey {data && data.info && data.info.first_name} {(data && data.info && data.info.ai_title) ? `(${data.info.ai_title})` : ""}</span>
                      </React.Fragment>
                        :
                        <React.Fragment>
                          <span className="bage-style">{`${data && data.info && data.info.agency_name && data.info.agency_name.trim()}`.substr(0, 1)}</span>
                          <span> Hey {data && data.info && data.info.agency_name} {(data && data.info && data.info.ai_title) ? `(${data.info.ai_title})` : ""}</span>
                        </React.Fragment>
                    }

                  </div>

                </li>

                <li className="wallet-icon-img login-style-btn" onClick={() => this.changeRoute("/my-wallet", false)}>
                  <a className="nav-link wallet-header">
                    <i className="fas fa-wallet wallet-icon"></i>
                    <div className="wallet-amt">
                      {wallet && wallet.currency_code ? <span>{`${wallet && wallet.currency_code} ${wallet && wallet.amount}`}
                      </span> : null}
                      <small>in your wallet</small>
                    </div>
                  </a>
                </li>

                <li onClick={() => this.changeRoute("/manage-bookings", false)} className='cursor-pointer'>
                  <a> <i className="fas fa-suitcase-rolling"></i>{" "}Manage bookings</a>
                </li>

                {isAgent() ? <li onClick={() => this.changeRoute("/special-fares", false)} className='cursor-pointer'>
                  <a> <i className="fas fa-ticket-alt"></i> Special Fares</a>
                </li> : null}

                <li onClick={() => this.changeRoute("/profile-settings", false)} className='cursor-pointer'>
                  <a> <i className="fas fa-user"></i> My Profile</a>
                </li>

                <li onClick={() => { this.LogOut(); this.changeRoute("/", false); }} className='cursor-pointer'>
                  <a><i className="fas fa-sign-out"></i> Logout </a>
                </li>

              </React.Fragment>
                :
                <React.Fragment>
                  <li>
                    <div className="login-style" onClick={() => this.changeRoute("/login", false)}>
                      <a
                        data-toggle="modal"
                        data-target="#loginModal"
                        className="menu-item"
                      >
                        <span>
                          <i className="fas fa-user-circle" />
                        </span>
                        <div >
                          Login/Sign Up
                      <span className="icon-forward">
                            <i className="icon-arrow" />
                          </span>
                        </div>
                      </a>
                    </div>
                    {/* <div className="login-style-btn">
                      <Button onClick={() => this.changeRoute('/register-business', false)} >Register for Business</Button>
                    </div> */}
                  </li>

                  <li>
                    <a onClick={() => this.changeRoute('/register-business', false)}>
                      <i className="fas fa-business-time" /> &nbsp;Register for Business</a>
                  </li>

                  <li>
                    <a onClick={() => this.changeRoute('/', false)}>
                      <i className="fas fa-plane" /> &nbsp;Flights    </a>
                  </li>
                  <li>
                    <a onClick={() => this.changeRoute('/', false)}>
                      <i className="fas fa-hotel" /> &nbsp;Hotels   </a>
                  </li>
                  <li>
                    <a onClick={() => this.changeRoute('/', false)}>
                      <i className="fas fa-bus" /> &nbsp;Bus     </a>
                  </li>
                  <li>
                    <a onClick={() => this.changeRoute('/', false)}>
                      <i className="fas fa-taxi" /> &nbsp;Cab      </a>
                  </li>
                  <li>
                    <a  onClick={() => this.props.history.push('/')}>
                      <i className="fas fa-handshake" /> &nbsp;Investors             </a>
                  </li>
                </React.Fragment>
            }




          </ul>
        </Menu>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  wallet: state.wallet,
  auth: state.auth,
  profileSettings: state.profileSettings
});

export default connect(mapStateToProps)(Sidebar);
