//File Name: FeaturedOffers.js
//Path: src/app/coomponents/homepage
//Description: This file is used for FeaturedOffers searches.

import React, { Component } from "react";
import Slider from "react-slick";
import { Card } from "react-bootstrap";
import ReactStars from "react-stars";
import { ContentLoaderCards } from "..";
import { currencySign } from "../../utils";
import { Link } from "react-router-dom";
import moment from "moment";

class FeaturedOffers extends Component {
  state = {};
  render() {
    const settings = {
      infinite: true,
      speed: 1000,
      slidesToShow: 3.5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500,
      cssEase: "linear",
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    const { featuredoffers, fetching } = this.props;
    const minSliderLength = 4
    const compareResult = (featuredoffers.length < minSliderLength)
    const divClass = compareResult ? '' : 'g4'
    const finalSettings = compareResult ? {...settings, infinite: false} : {...settings}

    return (
      <div className={(!fetching && !featuredoffers.length) ? 'featuredoffers' : `featuredoffers exists ${divClass}` }>
       {(!fetching && !featuredoffers.length) ? '' : 
        <h2 align="center" className="sub-title">
          FEATURED <span className="custom-label"> OFFERS</span>
        </h2>
        }
        {fetching ? (
          <ContentLoaderCards hotels="hotels" />
        ) : (
            <div>
              <Slider {...finalSettings}>
                {featuredoffers.map((data, key) => (
                  <Card key={key}>
                      <Card.Img variant="top" src={data.offer_image} />
                      <Card.Body>
                        <div className="validity-view">
                          {data.valid_from ? moment(data.valid_from).format('D MMM,YYYY') : ''} {(data.valid_from && data.valid_from) ?  '-' : ''} {data.valid_till ? moment(data.valid_till).format('D MMM,YYYY') : ''} {((data.show_price == 1) && (data.price != 0)) ? `(${data.price})` : ''}
                        </div>
                        <div className={data.description ? 'sub-view name-cover' : 'sub-view'}>
                          <div className="name-view">
                            <h6>{data.offer_title}</h6>
                          </div>
                        </div>
                        <div className="sub-view">
                          <div className="description-view">
                            <span>{data.description}</span>
                          </div>
                        </div>
                      </Card.Body>
                  </Card>
                ))}
              </Slider>
            </div>
          )}
      </div>
    );
  }
}

export default FeaturedOffers;
