import types from "../types";
import {
    manageBookingsAPI,
    // customManageBookingsAPI,
    getBookingDetailAPI,
    cancelBookingAPI,
    getLatestPdfsAPI
} from "../api/manageBookings";

export const manageBookings = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.MANAGE_BOOKINGS
        });
        manageBookingsAPI(data)
            .then(res => {
                dispatch({
                    type: types.MANAGE_BOOKINGS_SUCCESS,
                    payload: res
                });
                return resolve(res);
            })
            .catch(err => {
                dispatch({
                    type: types.MANAGE_BOOKINGS_FAILED
                });
                return reject(err);
            });
    }
    );
    

export const cancelBooking = (payload) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.CANCEL_BOOKING
        });
        cancelBookingAPI(payload)
            .then(res => {
                dispatch({
                    type: types.CANCEL_BOOKING_SUCCESS,
                    payload: res
                });
                return resolve(res);
            })
            .catch(err => {
                dispatch({
                    type: types.CANCEL_BOOKING_FAILED
                });
                return reject(err);
            });
    }
    );


export const getBookingDetail = (payload) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.GET_BOOKING_DETAIL
        });
        getBookingDetailAPI(payload)
            .then(res => {
                dispatch({
                    type: types.GET_BOOKING_DETAIL_SUCCESS,
                    payload: res
                });
                return resolve(res);
            })
            .catch(err => {
                dispatch({
                    type: types.GET_BOOKING_DETAIL_FAILED
                });
                return reject(err);
            });
    }
    );


export const updateEticketInAgent = (data) => dispatch => {
    new Promise((resolve, reject) =>
        dispatch({
            type: types.UPDATE_ETICKET,
            payload: data
        })

    )
};


export const getLatestPdf = (payload) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: types.GET_LATEST_PDFS
        });
        getLatestPdfsAPI(payload)
            .then(res => {
                dispatch({
                    type: types.GET_LATEST_PDFS_SUCCESS,
                    payload: res
                });
                return resolve(res);
            })
            .catch(err => {
                dispatch({
                    type: types.GET_LATEST_PDFS_FAILED
                });
                return reject(err);
            });
    }
    );
}