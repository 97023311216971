export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILED = "GET_USER_PROFILE_FAILED";

export const EMPTY_LOGGEDIN_USER = "EMPTY_LOGGEDIN_USER"
export const LOGOUT_USER_WALLET = "LOGOUT_USER_WALLET"

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILED = "UPDATE_USER_PROFILE_FAILED";

export const ADD_AGENT_LOGO = "ADD_AGENT_LOGO";
export const ADD_AGENT_LOGO_SUCCESS = "ADD_AGENT_LOGO_SUCCESS";
export const ADD_AGENT_LOGO_FAILED = "ADD_AGENT_LOGO_FAILED";