

import React from 'react';
import { Row, Col, Badge } from "react-bootstrap";
import { timeConvert, getMulticityItems } from "../../utils"
import moment from "moment"
import CardComponent from './CardComponent';


let item2=null;
const Cards = props => {
    const item = props && props.flightBookedResults;
    const {
        title = "",
        segments = [],
        isMulticity
    } = props;
    const segmentsLength = segments && segments.length;
    const fromCity = segments.length && segments[0].Origin && segments[0].Origin.Airport && segments[0].Origin.Airport.CityName
    const toCity = segments.length && segments[segmentsLength - 1].Destination && segments[segmentsLength - 1].Destination.Airport && segments[segmentsLength - 1].Destination.Airport.CityName

    const consoleItem =(item,text)=>{
        console.log(item,text);
    }
    item2=(item) ? item : item2;
    
    const renderMulticityCards = (original, destination, index, layovers) => {
        const ArrayWithLayovers = [{ ...original }, ...(layovers || [])];
        const fromCity = original.Origin.Airport.CityName || ""
        const toCity = destination.Destination.Airport.CityName || ""
        let layoverTime = (layovers.length && layovers.reduce((layover1, layover2) => ({ GroundTime: layover1.GroundTime + layover2.GroundTime }))) || 0;

        return (

            <div className='flightreview-card-style'>
                <Row className="depart-cards test555 box-shadow-add background_white text-center">
                    <Col sm={12} md={12} className="cards-heading ">
                        <h6>
                            <img src={require(`../../assets/flight${title}.png`)} alt="not found" /> TRIP {index + 1}
                        </h6>
                    </Col>
                    <Col sm={12} md={12}>
                        <h6 className="text-color d-inline float-left" >{fromCity} - {toCity}</h6>
                        <p className="float-right" >Total Time: {timeConvert((destination && destination.AccumulatedDuration) || original.Duration)}</p>
                    </Col>
                    {
                        ArrayWithLayovers && ArrayWithLayovers.length ? ArrayWithLayovers.map((data, index) => {
                            let hasSameLayoverPlane = false;
                            if (index > 0) {
                                let lastSectorPlane = data.Airline.FlightNumber;
                                let firstSectorPlane = ArrayWithLayovers[index - 1].Airline.FlightNumber
                                hasSameLayoverPlane = lastSectorPlane === firstSectorPlane ? true : false;
                            }
                            return (
                                <React.Fragment key={index}>
                                    <CardComponent
                                        key={index}
                                        data={data}
                                        index={index}
                                        item={item}
                                        title={title}
                                        fromCity={fromCity}
                                        toCity={toCity}
                                        layoverTime={(layoverTime && layoverTime.GroundTime) || 0}
                                        hasSameLayoverPlane={hasSameLayoverPlane}
                                    />
                                </React.Fragment>

                            )
                        }) : null
                    }
                </Row>
            </div>
        )
    }

    const isAccumulatedPropertyExists = segments && segments.length && segments.findIndex((item) => item.hasOwnProperty("AccumulatedDuration"))

    return (
        (!isMulticity && segments && segments.length) ? <React.Fragment>
                       {/* consoleItem(segments," this is segments") }
                       { consoleItem(item," this is item") }
    { consoleItem(item2," this is item 222") */}
            <Row className="depart-cards box-shadow-add background_white text-center">
                <Col sm={12} md={12} className="cards-heading back-color-bg">
                    <h6>
                        <img src={require(`../../assets/flight${title}.png`)} alt="not found" /> {title === "departure" ? "Outbound" : "Inbound"}
                    </h6>
                    <div className="float-right">
                        <Badge variant="secondary">
                            {(item2 && item2.IsRefundable) ? " Partially Refundable" : "Non-Refundable"  }
                        </Badge>
                        {
                            (item2 && item2.specialfare) ? <Badge variant="secondary">
                                PDC Special
                            </Badge> : null
                        }
                        {
                             (item2 && item2.ResultFareType && item2.ResultFareType =="StudentFare") ?
                             <Badge variant="secondary">
                                Student Fare
                            </Badge> : null
                        }
                        {
                             (item2 && item2.Source && item2.Source == 4 && !item2.specialfare) ?
                             <Badge variant="secondary">
                                PDC SPECIAL#
                            </Badge> : null
                        }
                    </div>
                </Col>
                <Col sm={12} md={12}>
                    <h6 className="text-color d-inline float-left" >{fromCity} - {toCity}</h6>
                    <p className="float-right" >Total Time: {isAccumulatedPropertyExists === -1 ? item2 && item2.duration : timeConvert(segments && segments.length && segments[isAccumulatedPropertyExists] && segments[isAccumulatedPropertyExists].AccumulatedDuration)}</p>
                </Col>
                {

                    segments && segments.length ? segments.map((data, index) => {
                        let hasSameLayoverPlane = false;
                        if (index > 0) {
                            let lastSectorPlane = data.Airline.FlightNumber;
                            let firstSectorPlane = segments[index - 1].Airline.FlightNumber
                            hasSameLayoverPlane = lastSectorPlane === firstSectorPlane ? true : false;
                        }
                        return (
                            <CardComponent
                                key={index}
                                data={data}
                                index={index}
                                item={item2}
                                title={title}
                                fromCity={fromCity}
                                toCity={toCity}
                                layoverTime={(data && data.GroundTime) || 0}
                                hasSameLayoverPlane={hasSameLayoverPlane}
                            />

                        )
                    }) : null
                }
            </Row>

        </React.Fragment> : <React.Fragment>
                {getMulticityItems(segments, renderMulticityCards)}
            </React.Fragment>
    );
};

export default Cards;