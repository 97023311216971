const config = {
  NODE_ENV: process.env.NODE_ENV,
  dev: process.env.NODE_ENV === "development",
  prod: process.env.NODE_ENV === "production",
  test: process.env.NODE_ENV === "test",
  debug: process.env.DEBUG || false,
  baseName: process.env.BASENAME || "",
  api: process.env.API || "",
  apiUrl: process.env.REACT_APP_API_URL || "http://dev.api.pdctrip.com",
  //apiUrl: "https://api.perfectdeparture.com",
  apiKey: process.env.API_KEY || "F65EC6920FD50AE6B1894F93C11ADEE6",
  redirectLink:process.env.REACT_APP_REDIRECT_URL || "http://dev.pdc.ths.agency",
  //redirectLink:"https://perfectdeparture.com",
  hdfc_access_code:process.env.HDFC_ACCESS_CODE || "AVGP63IL51BR51PGRB",
  hdfc_request_encrypt_code:process.env.HDFC_REQUEST_ENCRYPT_CODE || "112",
};
export default config;
