export const GET_FEATURED_FLIGHTS = "GET_FEATURED_FLIGHTS";
export const GET_FEATURED_FLIGHTS_SUCCESS = "GET_FEATURED_FLIGHTS_SUCCESS";
export const GET_FEATURED_FLIGHTS_FAILED = "GET_FEATURED_FLIGHTS_FAILED";

export const GET_FEATURED_HOTELS = "GET_FEATURED_HOTELS";
export const GET_FEATURED_HOTELS_SUCCESS = "GET_FEATURED_HOTELS_SUCCESS";
export const GET_FEATURED_HOTELS_FAILED = "GET_FEATURED_HOTELS_FAILED";

export const GET_FEATURED_OFFERS = "GET_FEATURED_OFFERS";
export const GET_FEATURED_OFFERS_SUCCESS = "GET_FEATURED_OFFERS_SUCCESS";
