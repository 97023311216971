import React from 'react';
import { Button } from "react-bootstrap";
import styled from 'styled-components';


const ButtonWrapper = styled.div`
    button{
        background-color: ${props => props.color}
        border-color:${props => props.color}
        &:hover{
            background-color: ${props => props.color}
        }
        &.btn-white {
            border: 1px solid ${props => props.color};
            color: ${props => props.color};
            background-color: #fff;
            height: ${props => props.height};
            text-transform: uppercase;
            &:hover {
                color: #fff;
                background-color: ${props => props.color};
            }
        }
        &.btn-green {
            border: 1px solid ${props => props.color};
            color: #fff;
            background-color: ${props => props.color};
            height: ${props => props.height};
            text-transform: uppercase;
            &:hover {
                color: ${props => props.color};
                background-color: #fff;
            }
        }
        &.small{
            font-size: 12px ;
            padding: 0 12px ;
            text-transform: uppercase;
            border-radius: 3px;
            font-weight: 600;
            height: 32px ;
            line-height: normal ;
        }
    }

`

const ThemeButton = props => {

    const { className = "", color = '', onClick = () => { }, block = false, text = '', disabled = false } = props || {};

    const colors = {
        primary: "#ed3237",
        secondary: "#4b4b4d",
        primarygreen: "#34a853",
        textcolor: "#333333",
        bordercolor: "#4b4b4d",
        primarylight10: "lighten(#ed3237, 10%)",
        primarydark10: "darken(#ed3237, 10%)",
        secondarylight10: "lighten(#4b4b4d, 40%)",
    };

    return (
        <ButtonWrapper color={colors[color] || ''} >
            <Button disabled={disabled} className={className} onClick={onClick} block={block}>{text}</Button>
        </ButtonWrapper>
    )
};

export default ThemeButton;
