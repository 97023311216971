import types from "../types"
import { getHotelResultAPI, getHotelDetailAPI, getHotelDestinationCityListsAPI } from "../api/hotels"


export const getHotelDestinationCityList = payload => dispatch => {
    return new Promise((resolve, reject) => {
        getHotelDestinationCityListsAPI(payload)
            .then((res) => {
                let modifyCity = res && res.info && res.info.map(item => ({ label: item.CityName, value: item.CityId }))
                dispatch({
                    type: types.GET_HOTEL_DestinationCityList_RESULT_SUCCESS,
                    payload: modifyCity
                })
                return resolve(modifyCity)
            }).catch((err) => {
                dispatch({
                    type: types.GET_HOTEL_DestinationCityList_RESULT_FAILED
                })
                return reject(err)
            })
    })
}



export const getHotelResult = payload => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: types.GET_HOTEL_SEARCH_RESULT
        })
        getHotelResultAPI(payload)
            .then((res) => {
                
                dispatch({
                    type: types.GET_HOTEL_SEARCH_RESULT_SUCCESS,
                    payload: res
                })
                return resolve(res)
            }).catch((err) => {
                dispatch({
                    type: types.GET_HOTEL_SEARCH_RESULT_FAILED
                })
                return reject(err)
            })
    })
}

export const getHotelDetails = payload => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: types.GET_HOTEL_DETAILS
        })
        getHotelDetailAPI(payload)
            .then((res) => {
                dispatch({
                    type: types.GET_HOTEL_DETAILS_SUCCESS,
                    payload: res
                })

                return resolve(res)
            }).catch((err) => {
                dispatch({
                    type: types.GET_HOTEL_DETAILS_FAILED
                })
                return reject(err)
            })
    })
}