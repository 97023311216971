import types from "../types"

const initState = {
    fetching: false,
    hotelsResults: [],
    TraceId: "",
    hotelDetailResponse: {},
}

export default (state = initState, action) => {

    switch (action.type) {

        case types.GET_HOTEL_DestinationCityList_RESULT_SUCCESS:
            return { ...state, cityList: action.payload || [] }

        case types.GET_HOTEL_SEARCH_RESULT:
            return { ...state, fetching: true }
        case types.GET_HOTEL_SEARCH_RESULT_SUCCESS:
            return {
                ...state,
                fetching: false,
                hotelsResults: (action && action.payload && action.payload.info.HotelResults) || [],
                TraceId: (action.payload && action.payload.info.TraceId) || ""
            }
        case types.GET_HOTEL_SEARCH_RESULT_FAILED:
            return { ...state, fetching: false }

        case types.GET_HOTEL_DETAILS:
            return { ...state, fetching: true }
        case types.GET_HOTEL_DETAILS_SUCCESS:
            return { ...state, fetching: false, hotelDetailResponse: action.payload.info }
        case types.GET_HOTEL_DETAILS_FAILED:
            return { ...state, fetching: false }
        default:
            return state;
    }
}