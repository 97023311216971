import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Badge,
  Form,
  FormControl,
  FormGroup,
  Tooltip,
  Popover,
  Overlay,
  OverlayTrigger,
  Modal
} from "react-bootstrap";
import Select, { Async } from "react-select";
import moment from "moment";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import { convertStaticData, passengersOption, TripTypes, classOption } from "../../staticData";
import { Counter, Multicity, ContentLoaderFare } from "../";
import { autofillSearchParameters } from "../../actions/getFlightResult";
import PassengerTypes from "./PassengerType";
import { currencySign, calenderPriceShow } from "../../utils";
import { isEmpty } from "lodash";

const generateViaCities = (arr) => {
  let returnedString = [];
  arr && arr.map((item, index) => {
    const { from, to } = item;
    if (index == 0) {
      return returnedString.push(to && to.city)
    }
    if (index + 1 == arr.length) {
      return returnedString.push(from && from.city)
    }
    else {
      returnedString.push(from && from.city)
      return returnedString.push(to && to.city)
    }
  })
  let uniqueValues = [...new Set(returnedString)];
  return uniqueValues.toString()
}

class ModifySearch extends Component {
  state = {
    openMulticity: false
  }

  handleMulticityModal = (val) => {
    this.setState({
      openMulticity: val
    })
  }

  render() {
    const {
      autoFillModifySearch,
      addcities,
      loadOptions,
      handleChange,
      errorToFrom,
      onChangeDate,
      onFocusChange,
      focusedInput,
      handleChangePersons,
      onChangeClass,
      clearReturnDate,
      modifySearch,
      handleTripsType,
      attachRef,
      togglePopover,
      swapDestinations,
      onOpenMenu,
      onCloseMenu,
      fetchingResults,
      delcities,
      multicitydates,
      blockedDates,
      validateFilledDetails,
      onSingleDateChange,
      focusedSinglePicker,
      handleFocusSingleDatePicker,
      handleFocusArrivalPicker,
      handleFarePrices,
      responsive,
      Type,
      onChangeRadioButtonPassenger,
      passengertype,
      minimunFareMonth,
      fetching
    } = this.props;

    const {
      openMulticity
    } = this.state;

    const { iternary = [] } = autoFillModifySearch
    let width = window.innerWidth;
    let modifySearcherror = false;

    if (autoFillModifySearch.trip && autoFillModifySearch.trip.value == "Round trip" && autoFillModifySearch.return == null) {
      modifySearcherror = true;
    }
    modifySearcherror = false;
    let passengerCount = (autoFillModifySearch.adults + autoFillModifySearch.children + autoFillModifySearch.infants) > 9 ? true : false;

    return (
      <React.Fragment>
        <Row className="list-style-custom-search">
          <Col className="nopadding">
            <ul className="list-style-flex">
              <li className="round-trip-oneway" controlid="exampleForm.ControlSelect1">
                <Async
                  value={autoFillModifySearch && autoFillModifySearch.trip}
                  defaultOptions={TripTypes}
                  onChange={(e) => handleTripsType(e.value)}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: "#e61616",
                    }
                  })}
                />
              </li>
              {autoFillModifySearch && autoFillModifySearch.trip && autoFillModifySearch.trip.value != "Multi city" ?
                <React.Fragment>
                  <li className="display-flex-search"
                    controlid="exampleFosdrm.ControlSelect1">
                    <ul className="sub-stlye-list">
                      <li>
                        <Async
                          value={iternary.length && iternary[0].from || null}
                          onMenuOpen={() => onOpenMenu()}
                          onMenuClose={() => onCloseMenu()}
                          placeholder={""}
                          defaultOptions={convertStaticData(passengersOption)}
                          loadOptions={loadOptions}
                          onChange={e => handleChange(e, "from")}
                          theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary: "#e61616",
                            }
                          })}
                        />
                      </li>
                      <li>
                        <div className="swap-icon" onClick={swapDestinations}>
                          <i className="fas fa-exchange-alt fa-lg"></i>
                        </div>
                      </li>
                      <li className="m-0">
                        <Async
                          value={iternary.length && iternary[0].to || null}
                          onMenuOpen={() => onOpenMenu()}
                          onMenuClose={() => onCloseMenu()}
                          defaultOptions={convertStaticData(passengersOption)}
                          placeholder={""}
                          loadOptions={loadOptions}
                          onChange={e => handleChange(e, "to")}
                          theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary: "#e61616",
                            }
                          })}
                        />
                        {errorToFrom ? (
                          <Tooltip
                            placement="bottom"
                            show={errorToFrom.toString()}
                            id="tooltip-right"
                          >
                            <span>From & To cannot be same</span>
                          </Tooltip>
                        ) : null}
                      </li>
                    </ul>
                  </li>
                  {
                    autoFillModifySearch.trip.value == "Round trip" ?
                      <li className="" >
                        <FormGroup className="custom_datepicker">
                          <i className='fas fa-times' onClick={clearReturnDate} />

                          <React.Fragment>
                            <DateRangePicker
                              readOnly
                              regular
                              hideKeyboardShortcutsPanel={true}
                              displayFormat="DD/MM/YYYY"
                              minimumNights={0}
                              numberOfMonths={width < 991 ? 1 : 2}
                              startDate={iternary && iternary.length && iternary[0] && moment(iternary[0].date) || moment()}
                              startDateId=""
                              endDate={iternary && iternary.length && iternary[1] && moment(iternary[1].date) || null}
                              endDateId=""
                              orientation={width < 991 ? "vertical" : "horizontal"}
                              daySize={width < 991 ? 30 : 45}
                              onDatesChange={({ startDate, endDate }) =>
                                onChangeDate(startDate, endDate)
                              }
                              focusedInput={focusedInput}
                              onFocusChange={e => onFocusChange(e)}
                              renderDayContents={(date, index) => {
                                let { fare = null, lowFare = false, Currency_code } = handleFarePrices(date);
                                if (fetching) {
                                  return (
                                    <React.Fragment>
                                      {date.format("DD")}
                                      <ContentLoaderFare />
                                    </React.Fragment>
                                  )
                                }
                                return (
                                  <React.Fragment>
                                    {date.format("DD")}
                                    {fare && <span className={lowFare ? "pricetag green" : "pricetag"}> {currencySign(Currency_code)}{calenderPriceShow(fare)}</span>}
                                  </React.Fragment>
                                );
                              }}
                              onNextMonthClick={(event) => minimunFareMonth(event)}
                              onPrevMonthClick={(event) => { minimunFareMonth(event) }}
                            />
                            {modifySearcherror ? (
                              <Tooltip
                                placement="bottom"
                                show={modifySearcherror.toString()}
                                id="tooltip-right"
                              >
                                <span>Return date is required</span>
                              </Tooltip>
                            ) : null}
                          </React.Fragment>

                        </FormGroup>
                      </li>
                      :
                      <React.Fragment>
                        <li>
                          <SingleDatePicker
                            regular
                            readOnly
                            placeholder="Pick a Date"
                            date={iternary && iternary.length && iternary[0] && moment(iternary[0].date) || moment()} // momentPropTypes.momentObj or null
                            onDateChange={(date) => onSingleDateChange(date)} // PropTypes.func.isRequired
                            focused={focusedSinglePicker}
                            displayFormat="DD/MM/YYYY"
                            daySize={width < 991 ? 30 : 45}
                            numberOfMonths={width < 991 ? 1 : 2}
                            orientation={width < 991 ? "vertical" : "horizontal"}
                            onFocusChange={({ focused }) => handleFocusSingleDatePicker({ focused })} // PropTypes.func.isRequired
                            id="youdfgr" // PropTypes.string.isRequired,
                            renderDayContents={(date, index) => {
                              let { fare = null, lowFare = false, Currency_code } = handleFarePrices(date);
                              if (fetching) {
                                return (
                                  <React.Fragment>
                                    {date.format("DD")}
                                    <ContentLoaderFare />
                                  </React.Fragment>
                                )
                              }
                              return (
                                <React.Fragment>
                                  {date.format("DD")}
                                  {fare && <span className={lowFare ? "pricetag green" : "pricetag"}>{Currency_code} {calenderPriceShow(fare)}</span>}
                                </React.Fragment>
                              );
                            }}
                            onNextMonthClick={(event) => minimunFareMonth(event)}
                            onPrevMonthClick={(event) => { minimunFareMonth(event) }}
                          />

                          <SingleDatePicker
                            placeholder="Pick a Date"
                            date={iternary && iternary.length && iternary[1] && moment(iternary[1].date) || null}
                            onDateChange={(date) => { }} // PropTypes.func.isRequired
                            focused={false}
                            displayFormat="DD/MM/YYYY"
                            onFocusChange={({ focused }) => handleFocusArrivalPicker({ focused })} // PropTypes.func.isRequired
                            id="yoxfur" // PropTypes.string.isRequired,
                          />
                        </li>
                      </React.Fragment>
                  }
                </React.Fragment>
                :
                (
                  <React.Fragment>
                    <li className="display-flex-search multicity" onClick={() => this.handleMulticityModal(true)} >
                      <React.Fragment>
                        <strong>{`${iternary && iternary.length && iternary[0].from && iternary[0].from.city || ""} to ${iternary && iternary.length && iternary[iternary.length - 1].to && iternary[iternary.length - 1].to.city || ""} `}</strong>
                        <span>{`Via ${generateViaCities(iternary)}`}</span>
                      </React.Fragment>
                    </li>
                  </React.Fragment>
                )
              }
              <li className="passenger-list-ui">
                <div controlid="exampleForm.ControlSelect1" className="no-passengers" >
                  <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={<Popover id="overlay-example" className='my-4 search_passenger'>
                    <Row className="counter-container">
                      <ul className="passengers-list clearfix list-unstyled mb-3">
                        {
                          passengerCount ?
                            <li className="mb-1">
                              <Badge variant="danger">Maximum of 9 travellers allowed</Badge>
                            </li>
                            : null
                        }
                        <Counter
                          persons={autoFillModifySearch}
                          maxValue={9}
                          name="adults"
                          title="Adults"
                          age="12+ years"
                          onChange={handleChangePersons}
                        />
                        <Counter
                          persons={autoFillModifySearch}
                          maxValue={
                            autoFillModifySearch &&
                              autoFillModifySearch.adults &&
                              autoFillModifySearch.adults >= 5
                              ? 9 - autoFillModifySearch.adults
                              : 4
                          }
                          name="children"
                          age="2 to 12 years"
                          title="Children"
                          onChange={handleChangePersons}
                        />
                        <Counter
                          persons={autoFillModifySearch}
                          maxValue={
                            autoFillModifySearch.adults > 4
                              ? 4
                              : autoFillModifySearch.adults
                          }
                          name="infants"
                          title="Infants"
                          age="7 days to 2 years"
                          onChange={handleChangePersons}
                        />
                        <li className="badge-list-btn">
                          {
                            classOption.map((item, index) => {
                              return <React.Fragment key={index}>
                                <Badge
                                  onClick={() => onChangeClass(item.value)}
                                  className="badges_airlineClass"
                                  pill
                                  variant={autoFillModifySearch.class == item.value ? "primary" : "secondary"}
                                >
                                  {item.label}
                                </Badge>
                              </React.Fragment>
                            })
                          }
                        </li>
                      </ul>
                    </Row>
                  </Popover>}>
                    <span className="span_passengersinfo" ref={attachRef} onClick={(e) => togglePopover(e)}>
                      {`${parseInt(
                        autoFillModifySearch &&
                        autoFillModifySearch.adults +
                        autoFillModifySearch.children +
                        autoFillModifySearch.infants
                      )} Passengers `}
                      <span className="small">
                        {!isEmpty(autoFillModifySearch) ? classOption.find((item) => {
                          return item.value == (autoFillModifySearch && autoFillModifySearch.class)
                        }).label : ""}
                      </span>
                    </span>
                  </OverlayTrigger>
                </div>
              </li>
            </ul>

            <ul className="custom-type flight-search-passengertype">
              <PassengerTypes
                responsive={responsive}
                Type={Type}
                onChangeRadioButtonPassenger={onChangeRadioButtonPassenger}
                passengertype={passengertype}
              />

            </ul>
            <div className="from-flight-btn text-right">
              <Button disabled={errorToFrom || passengerCount || fetchingResults || validateFilledDetails} onClick={modifySearch} variant="info" className="btn-white btn-green">
                {" "}
                Modify search
              </Button>
            </div>
          </Col>
        </Row >
        { 
        (openMulticity) ?
        <Modal className="multicity-modal" size="lg" show={openMulticity} onHide={() => this.handleMulticityModal(false)} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Modify Search</Modal.Title>
          </Modal.Header>

          <Modal.Body className='banner'>
            <div className="homepagesearch">
              <div className="form-booking">
                <p>Multi-city booking does not mean connecting flight. Customers must ensure sufficient connecting time between flights. PERFECT DEPARTURE.COM PVT. LTD. will not be responsible for missed connections due to any reason.</p>
                <Row>
                  <Multicity
                    multicity={iternary || []}
                    multicitydates={multicitydates}
                    loadOptions={loadOptions}
                    delcities={delcities}
                    blockedDates={blockedDates}
                    addcities={addcities}
                  />
                </Row>
              </div>
            </div>
          </Modal.Body>
          <div className="continue">
            <Button disabled={validateFilledDetails} variant="primary" className='my-2 btn-green' onClick={() => this.handleMulticityModal(false)}>
              Continue
            </Button>
          </div>
        </Modal>
        : null
  }
      </React.Fragment >
    );
  }
}

export default ModifySearch;