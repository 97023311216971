import Config from "./config";
export const prefix = "";
export const mountNode = "myApp";
export const assetsPrefix = Config.assetsPrefix;
export const host = Config.host;
export const port = Config.port;
export const commonHost = Config.commonHost;
export const TokenId = '9f61336f-2b7f-4315-918f-6c77b8d752e9';
export const apiUrl = Config.apiUrl;
export const apiKey = Config.apiKey;
export const hdfc_access_code =Config.hdfc_access_code;
export const hdfc_request_encrypt_code=Config.hdfc_request_encrypt_code;
// export const redirectLink = Config.redirectLink
export const redirectLink = window.location.origin
export const formTypes = {
  urlencoded: "application/x-www-form-urlencoded",
  json: "application/json",
  multipart: "multipart/form-data"
};
export const googleKey = "859603560203-i8bjjaf5mpfom5isvpb52ai8hbbiq7ob.apps.googleusercontent.com"

export const ThemeColor = {
  primary: "#ed3237",
  secondary: "#4b4b4d",
  primarygreen: "#34a853",
  textcolor: "#333333",
  bordercolor: "#4b4b4d",
  primarylight10: "lighten(#ed3237, 10%)",
  primarydark10: "darken(#ed3237, 10%)",
  secondarylight10: "#9E9E9E",
}


export const Nationality = [
  {
    "label": "Nationality",
    "value": ""
  },
  {
    "num_code": "4",
    "value": "AF",
    "alpha_3_code": "AFG",
    "en_short_name": "Afghanistan",
    "label": "Afghan"
  },
  {
    "num_code": "248",
    "value": "AX",
    "alpha_3_code": "ALA",
    "en_short_name": "\u00c5land Islands",
    "label": "\u00c5land Island"
  },
  {
    "num_code": "8",
    "value": "AL",
    "alpha_3_code": "ALB",
    "en_short_name": "Albania",
    "label": "Albanian"
  },
  {
    "num_code": "12",
    "value": "DZ",
    "alpha_3_code": "DZA",
    "en_short_name": "Algeria",
    "label": "Algerian"
  },
  {
    "num_code": "16",
    "value": "AS",
    "alpha_3_code": "ASM",
    "en_short_name": "American Samoa",
    "label": "American Samoan"
  },
  {
    "num_code": "20",
    "value": "AD",
    "alpha_3_code": "AND",
    "en_short_name": "Andorra",
    "label": "Andorran"
  },
  {
    "num_code": "24",
    "value": "AO",
    "alpha_3_code": "AGO",
    "en_short_name": "Angola",
    "label": "Angolan"
  },
  {
    "num_code": "660",
    "value": "AI",
    "alpha_3_code": "AIA",
    "en_short_name": "Anguilla",
    "label": "Anguillan"
  },
  {
    "num_code": "10",
    "value": "AQ",
    "alpha_3_code": "ATA",
    "en_short_name": "Antarctica",
    "label": "Antarctic"
  },
  {
    "num_code": "28",
    "value": "AG",
    "alpha_3_code": "ATG",
    "en_short_name": "Antigua and Barbuda",
    "label": "Antiguan or Barbudan"
  },
  {
    "num_code": "32",
    "value": "AR",
    "alpha_3_code": "ARG",
    "en_short_name": "Argentina",
    "label": "Argentine"
  },
  {
    "num_code": "51",
    "value": "AM",
    "alpha_3_code": "ARM",
    "en_short_name": "Armenia",
    "label": "Armenian"
  },
  {
    "num_code": "533",
    "value": "AW",
    "alpha_3_code": "ABW",
    "en_short_name": "Aruba",
    "label": "Aruban"
  },
  {
    "num_code": "36",
    "value": "AU",
    "alpha_3_code": "AUS",
    "en_short_name": "Australia",
    "label": "Australian"
  },
  {
    "num_code": "40",
    "value": "AT",
    "alpha_3_code": "AUT",
    "en_short_name": "Austria",
    "label": "Austrian"
  },
  {
    "num_code": "31",
    "value": "AZ",
    "alpha_3_code": "AZE",
    "en_short_name": "Azerbaijan",
    "label": "Azerbaijani, Azeri"
  },
  {
    "num_code": "44",
    "value": "BS",
    "alpha_3_code": "BHS",
    "en_short_name": "Bahamas",
    "label": "Bahamian"
  },
  {
    "num_code": "48",
    "value": "BH",
    "alpha_3_code": "BHR",
    "en_short_name": "Bahrain",
    "label": "Bahraini"
  },
  {
    "num_code": "50",
    "value": "BD",
    "alpha_3_code": "BGD",
    "en_short_name": "Bangladesh",
    "label": "Bangladeshi"
  },
  {
    "num_code": "52",
    "value": "BB",
    "alpha_3_code": "BRB",
    "en_short_name": "Barbados",
    "label": "Barbadian"
  },
  {
    "num_code": "112",
    "value": "BY",
    "alpha_3_code": "BLR",
    "en_short_name": "Belarus",
    "label": "Belarusian"
  },
  {
    "num_code": "56",
    "value": "BE",
    "alpha_3_code": "BEL",
    "en_short_name": "Belgium",
    "label": "Belgian"
  },
  {
    "num_code": "84",
    "value": "BZ",
    "alpha_3_code": "BLZ",
    "en_short_name": "Belize",
    "label": "Belizean"
  },
  {
    "num_code": "204",
    "value": "BJ",
    "alpha_3_code": "BEN",
    "en_short_name": "Benin",
    "label": "Beninese, Beninois"
  },
  {
    "num_code": "60",
    "value": "BM",
    "alpha_3_code": "BMU",
    "en_short_name": "Bermuda",
    "label": "Bermudian, Bermudan"
  },
  {
    "num_code": "64",
    "value": "BT",
    "alpha_3_code": "BTN",
    "en_short_name": "Bhutan",
    "label": "Bhutanese"
  },
  {
    "num_code": "68",
    "value": "BO",
    "alpha_3_code": "BOL",
    "en_short_name": "Bolivia (Plurinational State of)",
    "label": "Bolivian"
  },
  {
    "num_code": "535",
    "value": "BQ",
    "alpha_3_code": "BES",
    "en_short_name": "Bonaire, Sint Eustatius and Saba",
    "label": "Bonaire"
  },
  {
    "num_code": "70",
    "value": "BA",
    "alpha_3_code": "BIH",
    "en_short_name": "Bosnia and Herzegovina",
    "label": "Bosnian or Herzegovinian"
  },
  {
    "num_code": "72",
    "value": "BW",
    "alpha_3_code": "BWA",
    "en_short_name": "Botswana",
    "label": "Motswana, Botswanan"
  },
  {
    "num_code": "74",
    "value": "BV",
    "alpha_3_code": "BVT",
    "en_short_name": "Bouvet Island",
    "label": "Bouvet Island"
  },
  {
    "num_code": "76",
    "value": "BR",
    "alpha_3_code": "BRA",
    "en_short_name": "Brazil",
    "label": "Brazilian"
  },
  {
    "num_code": "86",
    "value": "IO",
    "alpha_3_code": "IOT",
    "en_short_name": "British Indian Ocean Territory",
    "label": "BIOT"
  },
  {
    "num_code": "96",
    "value": "BN",
    "alpha_3_code": "BRN",
    "en_short_name": "Brunei Darussalam",
    "label": "Bruneian"
  },
  {
    "num_code": "100",
    "value": "BG",
    "alpha_3_code": "BGR",
    "en_short_name": "Bulgaria",
    "label": "Bulgarian"
  },
  {
    "num_code": "854",
    "value": "BF",
    "alpha_3_code": "BFA",
    "en_short_name": "Burkina Faso",
    "label": "Burkinab\u00e9"
  },
  {
    "num_code": "108",
    "value": "BI",
    "alpha_3_code": "BDI",
    "en_short_name": "Burundi",
    "label": "Burundian"
  },
  {
    "num_code": "132",
    "value": "CV",
    "alpha_3_code": "CPV",
    "en_short_name": "Cabo Verde",
    "label": "Cabo Verdean"
  },
  {
    "num_code": "116",
    "value": "KH",
    "alpha_3_code": "KHM",
    "en_short_name": "Cambodia",
    "label": "Cambodian"
  },
  {
    "num_code": "120",
    "value": "CM",
    "alpha_3_code": "CMR",
    "en_short_name": "Cameroon",
    "label": "Cameroonian"
  },
  {
    "num_code": "124",
    "value": "CA",
    "alpha_3_code": "CAN",
    "en_short_name": "Canada",
    "label": "Canadian"
  },
  {
    "num_code": "136",
    "value": "KY",
    "alpha_3_code": "CYM",
    "en_short_name": "Cayman Islands",
    "label": "Caymanian"
  },
  {
    "num_code": "140",
    "value": "CF",
    "alpha_3_code": "CAF",
    "en_short_name": "Central African Republic",
    "label": "Central African"
  },
  {
    "num_code": "148",
    "value": "TD",
    "alpha_3_code": "TCD",
    "en_short_name": "Chad",
    "label": "Chadian"
  },
  {
    "num_code": "152",
    "value": "CL",
    "alpha_3_code": "CHL",
    "en_short_name": "Chile",
    "label": "Chilean"
  },
  {
    "num_code": "156",
    "value": "CN",
    "alpha_3_code": "CHN",
    "en_short_name": "China",
    "label": "Chinese"
  },
  {
    "num_code": "162",
    "value": "CX",
    "alpha_3_code": "CXR",
    "en_short_name": "Christmas Island",
    "label": "Christmas Island"
  },
  {
    "num_code": "166",
    "value": "CC",
    "alpha_3_code": "CCK",
    "en_short_name": "Cocos (Keeling) Islands",
    "label": "Cocos Island"
  },
  {
    "num_code": "170",
    "value": "CO",
    "alpha_3_code": "COL",
    "en_short_name": "Colombia",
    "label": "Colombian"
  },
  {
    "num_code": "174",
    "value": "KM",
    "alpha_3_code": "COM",
    "en_short_name": "Comoros",
    "label": "Comoran, Comorian"
  },
  {
    "num_code": "178",
    "value": "CG",
    "alpha_3_code": "COG",
    "en_short_name": "Congo (Republic of the)",
    "label": "Congolese"
  },
  {
    "num_code": "180",
    "value": "CD",
    "alpha_3_code": "COD",
    "en_short_name": "Congo (Democratic Republic of the)",
    "label": "Congolese"
  },
  {
    "num_code": "184",
    "value": "CK",
    "alpha_3_code": "COK",
    "en_short_name": "Cook Islands",
    "label": "Cook Island"
  },
  {
    "num_code": "188",
    "value": "CR",
    "alpha_3_code": "CRI",
    "en_short_name": "Costa Rica",
    "label": "Costa Rican"
  },
  {
    "num_code": "384",
    "value": "CI",
    "alpha_3_code": "CIV",
    "en_short_name": "C\u00f4te d'Ivoire",
    "label": "Ivorian"
  },
  {
    "num_code": "191",
    "value": "HR",
    "alpha_3_code": "HRV",
    "en_short_name": "Croatia",
    "label": "Croatian"
  },
  {
    "num_code": "192",
    "value": "CU",
    "alpha_3_code": "CUB",
    "en_short_name": "Cuba",
    "label": "Cuban"
  },
  {
    "num_code": "531",
    "value": "CW",
    "alpha_3_code": "CUW",
    "en_short_name": "Cura\u00e7ao",
    "label": "Cura\u00e7aoan"
  },
  {
    "num_code": "196",
    "value": "CY",
    "alpha_3_code": "CYP",
    "en_short_name": "Cyprus",
    "label": "Cypriot"
  },
  {
    "num_code": "203",
    "value": "CZ",
    "alpha_3_code": "CZE",
    "en_short_name": "Czech Republic",
    "label": "Czech"
  },
  {
    "num_code": "208",
    "value": "DK",
    "alpha_3_code": "DNK",
    "en_short_name": "Denmark",
    "label": "Danish"
  },
  {
    "num_code": "262",
    "value": "DJ",
    "alpha_3_code": "DJI",
    "en_short_name": "Djibouti",
    "label": "Djiboutian"
  },
  {
    "num_code": "212",
    "value": "DM",
    "alpha_3_code": "DMA",
    "en_short_name": "Dominica",
    "label": "Dominican"
  },
  {
    "num_code": "214",
    "value": "DO",
    "alpha_3_code": "DOM",
    "en_short_name": "Dominican Republic",
    "label": "Dominican"
  },
  {
    "num_code": "218",
    "value": "EC",
    "alpha_3_code": "ECU",
    "en_short_name": "Ecuador",
    "label": "Ecuadorian"
  },
  {
    "num_code": "818",
    "value": "EG",
    "alpha_3_code": "EGY",
    "en_short_name": "Egypt",
    "label": "Egyptian"
  },
  {
    "num_code": "222",
    "value": "SV",
    "alpha_3_code": "SLV",
    "en_short_name": "El Salvador",
    "label": "Salvadoran"
  },
  {
    "num_code": "226",
    "value": "GQ",
    "alpha_3_code": "GNQ",
    "en_short_name": "Equatorial Guinea",
    "label": "Equatorial Guinean, Equatoguinean"
  },
  {
    "num_code": "232",
    "value": "ER",
    "alpha_3_code": "ERI",
    "en_short_name": "Eritrea",
    "label": "Eritrean"
  },
  {
    "num_code": "233",
    "value": "EE",
    "alpha_3_code": "EST",
    "en_short_name": "Estonia",
    "label": "Estonian"
  },
  {
    "num_code": "231",
    "value": "ET",
    "alpha_3_code": "ETH",
    "en_short_name": "Ethiopia",
    "label": "Ethiopian"
  },
  {
    "num_code": "238",
    "value": "FK",
    "alpha_3_code": "FLK",
    "en_short_name": "Falkland Islands (Malvinas)",
    "label": "Falkland Island"
  },
  {
    "num_code": "234",
    "value": "FO",
    "alpha_3_code": "FRO",
    "en_short_name": "Faroe Islands",
    "label": "Faroese"
  },
  {
    "num_code": "242",
    "value": "FJ",
    "alpha_3_code": "FJI",
    "en_short_name": "Fiji",
    "label": "Fijian"
  },
  {
    "num_code": "246",
    "value": "FI",
    "alpha_3_code": "FIN",
    "en_short_name": "Finland",
    "label": "Finnish"
  },
  {
    "num_code": "250",
    "value": "FR",
    "alpha_3_code": "FRA",
    "en_short_name": "France",
    "label": "French"
  },
  {
    "num_code": "254",
    "value": "GF",
    "alpha_3_code": "GUF",
    "en_short_name": "French Guiana",
    "label": "French Guianese"
  },
  {
    "num_code": "258",
    "value": "PF",
    "alpha_3_code": "PYF",
    "en_short_name": "French Polynesia",
    "label": "French Polynesian"
  },
  {
    "num_code": "260",
    "value": "TF",
    "alpha_3_code": "ATF",
    "en_short_name": "French Southern Territories",
    "label": "French Southern Territories"
  },
  {
    "num_code": "266",
    "value": "GA",
    "alpha_3_code": "GAB",
    "en_short_name": "Gabon",
    "label": "Gabonese"
  },
  {
    "num_code": "270",
    "value": "GM",
    "alpha_3_code": "GMB",
    "en_short_name": "Gambia",
    "label": "Gambian"
  },
  {
    "num_code": "268",
    "value": "GE",
    "alpha_3_code": "GEO",
    "en_short_name": "Georgia",
    "label": "Georgian"
  },
  {
    "num_code": "276",
    "value": "DE",
    "alpha_3_code": "DEU",
    "en_short_name": "Germany",
    "label": "German"
  },
  {
    "num_code": "288",
    "value": "GH",
    "alpha_3_code": "GHA",
    "en_short_name": "Ghana",
    "label": "Ghanaian"
  },
  {
    "num_code": "292",
    "value": "GI",
    "alpha_3_code": "GIB",
    "en_short_name": "Gibraltar",
    "label": "Gibraltar"
  },
  {
    "num_code": "300",
    "value": "GR",
    "alpha_3_code": "GRC",
    "en_short_name": "Greece",
    "label": "Greek, Hellenic"
  },
  {
    "num_code": "304",
    "value": "GL",
    "alpha_3_code": "GRL",
    "en_short_name": "Greenland",
    "label": "Greenlandic"
  },
  {
    "num_code": "308",
    "value": "GD",
    "alpha_3_code": "GRD",
    "en_short_name": "Grenada",
    "label": "Grenadian"
  },
  {
    "num_code": "312",
    "value": "GP",
    "alpha_3_code": "GLP",
    "en_short_name": "Guadeloupe",
    "label": "Guadeloupe"
  },
  {
    "num_code": "316",
    "value": "GU",
    "alpha_3_code": "GUM",
    "en_short_name": "Guam",
    "label": "Guamanian, Guambat"
  },
  {
    "num_code": "320",
    "value": "GT",
    "alpha_3_code": "GTM",
    "en_short_name": "Guatemala",
    "label": "Guatemalan"
  },
  {
    "num_code": "831",
    "value": "GG",
    "alpha_3_code": "GGY",
    "en_short_name": "Guernsey",
    "label": "Channel Island"
  },
  {
    "num_code": "324",
    "value": "GN",
    "alpha_3_code": "GIN",
    "en_short_name": "Guinea",
    "label": "Guinean"
  },
  {
    "num_code": "624",
    "value": "GW",
    "alpha_3_code": "GNB",
    "en_short_name": "Guinea-Bissau",
    "label": "Bissau-Guinean"
  },
  {
    "num_code": "328",
    "value": "GY",
    "alpha_3_code": "GUY",
    "en_short_name": "Guyana",
    "label": "Guyanese"
  },
  {
    "num_code": "332",
    "value": "HT",
    "alpha_3_code": "HTI",
    "en_short_name": "Haiti",
    "label": "Haitian"
  },
  {
    "num_code": "334",
    "value": "HM",
    "alpha_3_code": "HMD",
    "en_short_name": "Heard Island and McDonald Islands",
    "label": "Heard Island or McDonald Islands"
  },
  {
    "num_code": "336",
    "value": "VA",
    "alpha_3_code": "VAT",
    "en_short_name": "Vatican City State",
    "label": "Vatican"
  },
  {
    "num_code": "340",
    "value": "HN",
    "alpha_3_code": "HND",
    "en_short_name": "Honduras",
    "label": "Honduran"
  },
  {
    "num_code": "344",
    "value": "HK",
    "alpha_3_code": "HKG",
    "en_short_name": "Hong Kong",
    "label": "Hong Kong, Hong Kongese"
  },
  {
    "num_code": "348",
    "value": "HU",
    "alpha_3_code": "HUN",
    "en_short_name": "Hungary",
    "label": "Hungarian, Magyar"
  },
  {
    "num_code": "352",
    "value": "IS",
    "alpha_3_code": "ISL",
    "en_short_name": "Iceland",
    "label": "Icelandic"
  },
  {
    "num_code": "356",
    "value": "IN",
    "alpha_3_code": "IND",
    "en_short_name": "India",
    "label": "Indian"
  },
  {
    "num_code": "360",
    "value": "ID",
    "alpha_3_code": "IDN",
    "en_short_name": "Indonesia",
    "label": "Indonesian"
  },
  {
    "num_code": "364",
    "value": "IR",
    "alpha_3_code": "IRN",
    "en_short_name": "Iran",
    "label": "Iranian, Persian"
  },
  {
    "num_code": "368",
    "value": "IQ",
    "alpha_3_code": "IRQ",
    "en_short_name": "Iraq",
    "label": "Iraqi"
  },
  {
    "num_code": "372",
    "value": "IE",
    "alpha_3_code": "IRL",
    "en_short_name": "Ireland",
    "label": "Irish"
  },
  {
    "num_code": "833",
    "value": "IM",
    "alpha_3_code": "IMN",
    "en_short_name": "Isle of Man",
    "label": "Manx"
  },
  {
    "num_code": "376",
    "value": "IL",
    "alpha_3_code": "ISR",
    "en_short_name": "Israel",
    "label": "Israeli"
  },
  {
    "num_code": "380",
    "value": "IT",
    "alpha_3_code": "ITA",
    "en_short_name": "Italy",
    "label": "Italian"
  },
  {
    "num_code": "388",
    "value": "JM",
    "alpha_3_code": "JAM",
    "en_short_name": "Jamaica",
    "label": "Jamaican"
  },
  {
    "num_code": "392",
    "value": "JP",
    "alpha_3_code": "JPN",
    "en_short_name": "Japan",
    "label": "Japanese"
  },
  {
    "num_code": "832",
    "value": "JE",
    "alpha_3_code": "JEY",
    "en_short_name": "Jersey",
    "label": "Channel Island"
  },
  {
    "num_code": "400",
    "value": "JO",
    "alpha_3_code": "JOR",
    "en_short_name": "Jordan",
    "label": "Jordanian"
  },
  {
    "num_code": "398",
    "value": "KZ",
    "alpha_3_code": "KAZ",
    "en_short_name": "Kazakhstan",
    "label": "Kazakhstani, Kazakh"
  },
  {
    "num_code": "404",
    "value": "KE",
    "alpha_3_code": "KEN",
    "en_short_name": "Kenya",
    "label": "Kenyan"
  },
  {
    "num_code": "296",
    "value": "KI",
    "alpha_3_code": "KIR",
    "en_short_name": "Kiribati",
    "label": "I-Kiribati"
  },
  {
    "num_code": "408",
    "value": "KP",
    "alpha_3_code": "PRK",
    "en_short_name": "Korea (Democratic People's Republic of)",
    "label": "North Korean"
  },
  {
    "num_code": "410",
    "value": "KR",
    "alpha_3_code": "KOR",
    "en_short_name": "Korea (Republic of)",
    "label": "South Korean"
  },
  {
    "num_code": "414",
    "value": "KW",
    "alpha_3_code": "KWT",
    "en_short_name": "Kuwait",
    "label": "Kuwaiti"
  },
  {
    "num_code": "417",
    "value": "KG",
    "alpha_3_code": "KGZ",
    "en_short_name": "Kyrgyzstan",
    "label": "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz"
  },
  {
    "num_code": "418",
    "value": "LA",
    "alpha_3_code": "LAO",
    "en_short_name": "Lao People's Democratic Republic",
    "label": "Lao, Laotian"
  },
  {
    "num_code": "428",
    "value": "LV",
    "alpha_3_code": "LVA",
    "en_short_name": "Latvia",
    "label": "Latvian"
  },
  {
    "num_code": "422",
    "value": "LB",
    "alpha_3_code": "LBN",
    "en_short_name": "Lebanon",
    "label": "Lebanese"
  },
  {
    "num_code": "426",
    "value": "LS",
    "alpha_3_code": "LSO",
    "en_short_name": "Lesotho",
    "label": "Basotho"
  },
  {
    "num_code": "430",
    "value": "LR",
    "alpha_3_code": "LBR",
    "en_short_name": "Liberia",
    "label": "Liberian"
  },
  {
    "num_code": "434",
    "value": "LY",
    "alpha_3_code": "LBY",
    "en_short_name": "Libya",
    "label": "Libyan"
  },
  {
    "num_code": "438",
    "value": "LI",
    "alpha_3_code": "LIE",
    "en_short_name": "Liechtenstein",
    "label": "Liechtenstein"
  },
  {
    "num_code": "440",
    "value": "LT",
    "alpha_3_code": "LTU",
    "en_short_name": "Lithuania",
    "label": "Lithuanian"
  },
  {
    "num_code": "442",
    "value": "LU",
    "alpha_3_code": "LUX",
    "en_short_name": "Luxembourg",
    "label": "Luxembourg, Luxembourgish"
  },
  {
    "num_code": "446",
    "value": "MO",
    "alpha_3_code": "MAC",
    "en_short_name": "Macao",
    "label": "Macanese, Chinese"
  },
  {
    "num_code": "807",
    "value": "MK",
    "alpha_3_code": "MKD",
    "en_short_name": "Macedonia (the former Yugoslav Republic of)",
    "label": "Macedonian"
  },
  {
    "num_code": "450",
    "value": "MG",
    "alpha_3_code": "MDG",
    "en_short_name": "Madagascar",
    "label": "Malagasy"
  },
  {
    "num_code": "454",
    "value": "MW",
    "alpha_3_code": "MWI",
    "en_short_name": "Malawi",
    "label": "Malawian"
  },
  {
    "num_code": "458",
    "value": "MY",
    "alpha_3_code": "MYS",
    "en_short_name": "Malaysia",
    "label": "Malaysian"
  },
  {
    "num_code": "462",
    "value": "MV",
    "alpha_3_code": "MDV",
    "en_short_name": "Maldives",
    "label": "Maldivian"
  },
  {
    "num_code": "466",
    "value": "ML",
    "alpha_3_code": "MLI",
    "en_short_name": "Mali",
    "label": "Malian, Malinese"
  },
  {
    "num_code": "470",
    "value": "MT",
    "alpha_3_code": "MLT",
    "en_short_name": "Malta",
    "label": "Maltese"
  },
  {
    "num_code": "584",
    "value": "MH",
    "alpha_3_code": "MHL",
    "en_short_name": "Marshall Islands",
    "label": "Marshallese"
  },
  {
    "num_code": "474",
    "value": "MQ",
    "alpha_3_code": "MTQ",
    "en_short_name": "Martinique",
    "label": "Martiniquais, Martinican"
  },
  {
    "num_code": "478",
    "value": "MR",
    "alpha_3_code": "MRT",
    "en_short_name": "Mauritania",
    "label": "Mauritanian"
  },
  {
    "num_code": "480",
    "value": "MU",
    "alpha_3_code": "MUS",
    "en_short_name": "Mauritius",
    "label": "Mauritian"
  },
  {
    "num_code": "175",
    "value": "YT",
    "alpha_3_code": "MYT",
    "en_short_name": "Mayotte",
    "label": "Mahoran"
  },
  {
    "num_code": "484",
    "value": "MX",
    "alpha_3_code": "MEX",
    "en_short_name": "Mexico",
    "label": "Mexican"
  },
  {
    "num_code": "583",
    "value": "FM",
    "alpha_3_code": "FSM",
    "en_short_name": "Micronesia (Federated States of)",
    "label": "Micronesian"
  },
  {
    "num_code": "498",
    "value": "MD",
    "alpha_3_code": "MDA",
    "en_short_name": "Moldova (Republic of)",
    "label": "Moldovan"
  },
  {
    "num_code": "492",
    "value": "MC",
    "alpha_3_code": "MCO",
    "en_short_name": "Monaco",
    "label": "Mon\u00e9gasque, Monacan"
  },
  {
    "num_code": "496",
    "value": "MN",
    "alpha_3_code": "MNG",
    "en_short_name": "Mongolia",
    "label": "Mongolian"
  },
  {
    "num_code": "499",
    "value": "ME",
    "alpha_3_code": "MNE",
    "en_short_name": "Montenegro",
    "label": "Montenegrin"
  },
  {
    "num_code": "500",
    "value": "MS",
    "alpha_3_code": "MSR",
    "en_short_name": "Montserrat",
    "label": "Montserratian"
  },
  {
    "num_code": "504",
    "value": "MA",
    "alpha_3_code": "MAR",
    "en_short_name": "Morocco",
    "label": "Moroccan"
  },
  {
    "num_code": "508",
    "value": "MZ",
    "alpha_3_code": "MOZ",
    "en_short_name": "Mozambique",
    "label": "Mozambican"
  },
  {
    "num_code": "104",
    "value": "MM",
    "alpha_3_code": "MMR",
    "en_short_name": "Myanmar",
    "label": "Burmese"
  },
  {
    "num_code": "516",
    "value": "NA",
    "alpha_3_code": "NAM",
    "en_short_name": "Namibia",
    "label": "Namibian"
  },
  {
    "num_code": "520",
    "value": "NR",
    "alpha_3_code": "NRU",
    "en_short_name": "Nauru",
    "label": "Nauruan"
  },
  {
    "num_code": "524",
    "value": "NP",
    "alpha_3_code": "NPL",
    "en_short_name": "Nepal",
    "label": "Nepali, Nepalese"
  },
  {
    "num_code": "528",
    "value": "NL",
    "alpha_3_code": "NLD",
    "en_short_name": "Netherlands",
    "label": "Dutch, Netherlandic"
  },
  {
    "num_code": "540",
    "value": "NC",
    "alpha_3_code": "NCL",
    "en_short_name": "New Caledonia",
    "label": "New Caledonian"
  },
  {
    "num_code": "554",
    "value": "NZ",
    "alpha_3_code": "NZL",
    "en_short_name": "New Zealand",
    "label": "New Zealand, NZ"
  },
  {
    "num_code": "558",
    "value": "NI",
    "alpha_3_code": "NIC",
    "en_short_name": "Nicaragua",
    "label": "Nicaraguan"
  },
  {
    "num_code": "562",
    "value": "NE",
    "alpha_3_code": "NER",
    "en_short_name": "Niger",
    "label": "Nigerien"
  },
  {
    "num_code": "566",
    "value": "NG",
    "alpha_3_code": "NGA",
    "en_short_name": "Nigeria",
    "label": "Nigerian"
  },
  {
    "num_code": "570",
    "value": "NU",
    "alpha_3_code": "NIU",
    "en_short_name": "Niue",
    "label": "Niuean"
  },
  {
    "num_code": "574",
    "value": "NF",
    "alpha_3_code": "NFK",
    "en_short_name": "Norfolk Island",
    "label": "Norfolk Island"
  },
  {
    "num_code": "580",
    "value": "MP",
    "alpha_3_code": "MNP",
    "en_short_name": "Northern Mariana Islands",
    "label": "Northern Marianan"
  },
  {
    "num_code": "578",
    "value": "NO",
    "alpha_3_code": "NOR",
    "en_short_name": "Norway",
    "label": "Norwegian"
  },
  {
    "num_code": "512",
    "value": "OM",
    "alpha_3_code": "OMN",
    "en_short_name": "Oman",
    "label": "Omani"
  },
  {
    "num_code": "586",
    "value": "PK",
    "alpha_3_code": "PAK",
    "en_short_name": "Pakistan",
    "label": "Pakistani"
  },
  {
    "num_code": "585",
    "value": "PW",
    "alpha_3_code": "PLW",
    "en_short_name": "Palau",
    "label": "Palauan"
  },
  {
    "num_code": "275",
    "value": "PS",
    "alpha_3_code": "PSE",
    "en_short_name": "Palestine, State of",
    "label": "Palestinian"
  },
  {
    "num_code": "591",
    "value": "PA",
    "alpha_3_code": "PAN",
    "en_short_name": "Panama",
    "label": "Panamanian"
  },
  {
    "num_code": "598",
    "value": "PG",
    "alpha_3_code": "PNG",
    "en_short_name": "Papua New Guinea",
    "label": "Papua New Guinean, Papuan"
  },
  {
    "num_code": "600",
    "value": "PY",
    "alpha_3_code": "PRY",
    "en_short_name": "Paraguay",
    "label": "Paraguayan"
  },
  {
    "num_code": "604",
    "value": "PE",
    "alpha_3_code": "PER",
    "en_short_name": "Peru",
    "label": "Peruvian"
  },
  {
    "num_code": "608",
    "value": "PH",
    "alpha_3_code": "PHL",
    "en_short_name": "Philippines",
    "label": "Philippine, Filipino"
  },
  {
    "num_code": "612",
    "value": "PN",
    "alpha_3_code": "PCN",
    "en_short_name": "Pitcairn",
    "label": "Pitcairn Island"
  },
  {
    "num_code": "616",
    "value": "PL",
    "alpha_3_code": "POL",
    "en_short_name": "Poland",
    "label": "Polish"
  },
  {
    "num_code": "620",
    "value": "PT",
    "alpha_3_code": "PRT",
    "en_short_name": "Portugal",
    "label": "Portuguese"
  },
  {
    "num_code": "630",
    "value": "PR",
    "alpha_3_code": "PRI",
    "en_short_name": "Puerto Rico",
    "label": "Puerto Rican"
  },
  {
    "num_code": "634",
    "value": "QA",
    "alpha_3_code": "QAT",
    "en_short_name": "Qatar",
    "label": "Qatari"
  },
  {
    "num_code": "638",
    "value": "RE",
    "alpha_3_code": "REU",
    "en_short_name": "R\u00e9union",
    "label": "R\u00e9unionese, R\u00e9unionnais"
  },
  {
    "num_code": "642",
    "value": "RO",
    "alpha_3_code": "ROU",
    "en_short_name": "Romania",
    "label": "Romanian"
  },
  {
    "num_code": "643",
    "value": "RU",
    "alpha_3_code": "RUS",
    "en_short_name": "Russian Federation",
    "label": "Russian"
  },
  {
    "num_code": "646",
    "value": "RW",
    "alpha_3_code": "RWA",
    "en_short_name": "Rwanda",
    "label": "Rwandan"
  },
  {
    "num_code": "652",
    "value": "BL",
    "alpha_3_code": "BLM",
    "en_short_name": "Saint Barth\u00e9lemy",
    "label": "Barth\u00e9lemois"
  },
  {
    "num_code": "654",
    "value": "SH",
    "alpha_3_code": "SHN",
    "en_short_name": "Saint Helena, Ascension and Tristan da Cunha",
    "label": "Saint Helenian"
  },
  {
    "num_code": "659",
    "value": "KN",
    "alpha_3_code": "KNA",
    "en_short_name": "Saint Kitts and Nevis",
    "label": "Kittitian or Nevisian"
  },
  {
    "num_code": "662",
    "value": "LC",
    "alpha_3_code": "LCA",
    "en_short_name": "Saint Lucia",
    "label": "Saint Lucian"
  },
  {
    "num_code": "663",
    "value": "MF",
    "alpha_3_code": "MAF",
    "en_short_name": "Saint Martin (French part)",
    "label": "Saint-Martinoise"
  },
  {
    "num_code": "666",
    "value": "PM",
    "alpha_3_code": "SPM",
    "en_short_name": "Saint Pierre and Miquelon",
    "label": "Saint-Pierrais or Miquelonnais"
  },
  {
    "num_code": "670",
    "value": "VC",
    "alpha_3_code": "VCT",
    "en_short_name": "Saint Vincent and the Grenadines",
    "label": "Saint Vincentian, Vincentian"
  },
  {
    "num_code": "882",
    "value": "WS",
    "alpha_3_code": "WSM",
    "en_short_name": "Samoa",
    "label": "Samoan"
  },
  {
    "num_code": "674",
    "value": "SM",
    "alpha_3_code": "SMR",
    "en_short_name": "San Marino",
    "label": "Sammarinese"
  },
  {
    "num_code": "678",
    "value": "ST",
    "alpha_3_code": "STP",
    "en_short_name": "Sao Tome and Principe",
    "label": "S\u00e3o Tom\u00e9an"
  },
  {
    "num_code": "682",
    "value": "SA",
    "alpha_3_code": "SAU",
    "en_short_name": "Saudi Arabia",
    "label": "Saudi, Saudi Arabian"
  },
  {
    "num_code": "686",
    "value": "SN",
    "alpha_3_code": "SEN",
    "en_short_name": "Senegal",
    "label": "Senegalese"
  },
  {
    "num_code": "688",
    "value": "RS",
    "alpha_3_code": "SRB",
    "en_short_name": "Serbia",
    "label": "Serbian"
  },
  {
    "num_code": "690",
    "value": "SC",
    "alpha_3_code": "SYC",
    "en_short_name": "Seychelles",
    "label": "Seychellois"
  },
  {
    "num_code": "694",
    "value": "SL",
    "alpha_3_code": "SLE",
    "en_short_name": "Sierra Leone",
    "label": "Sierra Leonean"
  },
  {
    "num_code": "702",
    "value": "SG",
    "alpha_3_code": "SGP",
    "en_short_name": "Singapore",
    "label": "Singaporean"
  },
  {
    "num_code": "534",
    "value": "SX",
    "alpha_3_code": "SXM",
    "en_short_name": "Sint Maarten (Dutch part)",
    "label": "Sint Maarten"
  },
  {
    "num_code": "703",
    "value": "SK",
    "alpha_3_code": "SVK",
    "en_short_name": "Slovakia",
    "label": "Slovak"
  },
  {
    "num_code": "705",
    "value": "SI",
    "alpha_3_code": "SVN",
    "en_short_name": "Slovenia",
    "label": "Slovenian, Slovene"
  },
  {
    "num_code": "90",
    "value": "SB",
    "alpha_3_code": "SLB",
    "en_short_name": "Solomon Islands",
    "label": "Solomon Island"
  },
  {
    "num_code": "706",
    "value": "SO",
    "alpha_3_code": "SOM",
    "en_short_name": "Somalia",
    "label": "Somali, Somalian"
  },
  {
    "num_code": "710",
    "value": "ZA",
    "alpha_3_code": "ZAF",
    "en_short_name": "South Africa",
    "label": "South African"
  },
  {
    "num_code": "239",
    "value": "GS",
    "alpha_3_code": "SGS",
    "en_short_name": "South Georgia and the South Sandwich Islands",
    "label": "South Georgia or South Sandwich Islands"
  },
  {
    "num_code": "728",
    "value": "SS",
    "alpha_3_code": "SSD",
    "en_short_name": "South Sudan",
    "label": "South Sudanese"
  },
  {
    "num_code": "724",
    "value": "ES",
    "alpha_3_code": "ESP",
    "en_short_name": "Spain",
    "label": "Spanish"
  },
  {
    "num_code": "144",
    "value": "LK",
    "alpha_3_code": "LKA",
    "en_short_name": "Sri Lanka",
    "label": "Sri Lankan"
  },
  {
    "num_code": "729",
    "value": "SD",
    "alpha_3_code": "SDN",
    "en_short_name": "Sudan",
    "label": "Sudanese"
  },
  {
    "num_code": "740",
    "value": "SR",
    "alpha_3_code": "SUR",
    "en_short_name": "Suriname",
    "label": "Surinamese"
  },
  {
    "num_code": "744",
    "value": "SJ",
    "alpha_3_code": "SJM",
    "en_short_name": "Svalbard and Jan Mayen",
    "label": "Svalbard"
  },
  {
    "num_code": "748",
    "value": "SZ",
    "alpha_3_code": "SWZ",
    "en_short_name": "Swaziland",
    "label": "Swazi"
  },
  {
    "num_code": "752",
    "value": "SE",
    "alpha_3_code": "SWE",
    "en_short_name": "Sweden",
    "label": "Swedish"
  },
  {
    "num_code": "756",
    "value": "CH",
    "alpha_3_code": "CHE",
    "en_short_name": "Switzerland",
    "label": "Swiss"
  },
  {
    "num_code": "760",
    "value": "SY",
    "alpha_3_code": "SYR",
    "en_short_name": "Syrian Arab Republic",
    "label": "Syrian"
  },
  {
    "num_code": "158",
    "value": "TW",
    "alpha_3_code": "TWN",
    "en_short_name": "Taiwan, Province of China",
    "label": "Chinese, Taiwanese"
  },
  {
    "num_code": "762",
    "value": "TJ",
    "alpha_3_code": "TJK",
    "en_short_name": "Tajikistan",
    "label": "Tajikistani"
  },
  {
    "num_code": "834",
    "value": "TZ",
    "alpha_3_code": "TZA",
    "en_short_name": "Tanzania, United Republic of",
    "label": "Tanzanian"
  },
  {
    "num_code": "764",
    "value": "TH",
    "alpha_3_code": "THA",
    "en_short_name": "Thailand",
    "label": "Thai"
  },
  {
    "num_code": "626",
    "value": "TL",
    "alpha_3_code": "TLS",
    "en_short_name": "Timor-Leste",
    "label": "Timorese"
  },
  {
    "num_code": "768",
    "value": "TG",
    "alpha_3_code": "TGO",
    "en_short_name": "Togo",
    "label": "Togolese"
  },
  {
    "num_code": "772",
    "value": "TK",
    "alpha_3_code": "TKL",
    "en_short_name": "Tokelau",
    "label": "Tokelauan"
  },
  {
    "num_code": "776",
    "value": "TO",
    "alpha_3_code": "TON",
    "en_short_name": "Tonga",
    "label": "Tongan"
  },
  {
    "num_code": "780",
    "value": "TT",
    "alpha_3_code": "TTO",
    "en_short_name": "Trinidad and Tobago",
    "label": "Trinidadian or Tobagonian"
  },
  {
    "num_code": "788",
    "value": "TN",
    "alpha_3_code": "TUN",
    "en_short_name": "Tunisia",
    "label": "Tunisian"
  },
  {
    "num_code": "792",
    "value": "TR",
    "alpha_3_code": "TUR",
    "en_short_name": "Turkey",
    "label": "Turkish"
  },
  {
    "num_code": "795",
    "value": "TM",
    "alpha_3_code": "TKM",
    "en_short_name": "Turkmenistan",
    "label": "Turkmen"
  },
  {
    "num_code": "796",
    "value": "TC",
    "alpha_3_code": "TCA",
    "en_short_name": "Turks and Caicos Islands",
    "label": "Turks and Caicos Island"
  },
  {
    "num_code": "798",
    "value": "TV",
    "alpha_3_code": "TUV",
    "en_short_name": "Tuvalu",
    "label": "Tuvaluan"
  },
  {
    "num_code": "800",
    "value": "UG",
    "alpha_3_code": "UGA",
    "en_short_name": "Uganda",
    "label": "Ugandan"
  },
  {
    "num_code": "804",
    "value": "UA",
    "alpha_3_code": "UKR",
    "en_short_name": "Ukraine",
    "label": "Ukrainian"
  },
  {
    "num_code": "784",
    "value": "AE",
    "alpha_3_code": "ARE",
    "en_short_name": "United Arab Emirates",
    "label": "Emirati, Emirian, Emiri"
  },
  {
    "num_code": "826",
    "value": "GB",
    "alpha_3_code": "GBR",
    "en_short_name": "United Kingdom of Great Britain and Northern Ireland",
    "label": "British, UK"
  },
  {
    "num_code": "581",
    "value": "UM",
    "alpha_3_code": "UMI",
    "en_short_name": "United States Minor Outlying Islands",
    "label": "American"
  },
  {
    "num_code": "840",
    "value": "US",
    "alpha_3_code": "USA",
    "en_short_name": "United States of America",
    "label": "American"
  },
  {
    "num_code": "858",
    "value": "UY",
    "alpha_3_code": "URY",
    "en_short_name": "Uruguay",
    "label": "Uruguayan"
  },
  {
    "num_code": "860",
    "value": "UZ",
    "alpha_3_code": "UZB",
    "en_short_name": "Uzbekistan",
    "label": "Uzbekistani, Uzbek"
  },
  {
    "num_code": "548",
    "value": "VU",
    "alpha_3_code": "VUT",
    "en_short_name": "Vanuatu",
    "label": "Ni-Vanuatu, Vanuatuan"
  },
  {
    "num_code": "862",
    "value": "VE",
    "alpha_3_code": "VEN",
    "en_short_name": "Venezuela (Bolivarian Republic of)",
    "label": "Venezuelan"
  },
  {
    "num_code": "704",
    "value": "VN",
    "alpha_3_code": "VNM",
    "en_short_name": "Vietnam",
    "label": "Vietnamese"
  },
  {
    "num_code": "92",
    "value": "VG",
    "alpha_3_code": "VGB",
    "en_short_name": "Virgin Islands (British)",
    "label": "British Virgin Island"
  },
  {
    "num_code": "850",
    "value": "VI",
    "alpha_3_code": "VIR",
    "en_short_name": "Virgin Islands (U.S.)",
    "label": "U.S. Virgin Island"
  },
  {
    "num_code": "876",
    "value": "WF",
    "alpha_3_code": "WLF",
    "en_short_name": "Wallis and Futuna",
    "label": "Wallis and Futuna, Wallisian or Futunan"
  },
  {
    "num_code": "732",
    "value": "EH",
    "alpha_3_code": "ESH",
    "en_short_name": "Western Sahara",
    "label": "Sahrawi, Sahrawian, Sahraouian"
  },
  {
    "num_code": "887",
    "value": "YE",
    "alpha_3_code": "YEM",
    "en_short_name": "Yemen",
    "label": "Yemeni"
  },
  {
    "num_code": "894",
    "value": "ZM",
    "alpha_3_code": "ZMB",
    "en_short_name": "Zambia",
    "label": "Zambian"
  },
  {
    "num_code": "716",
    "value": "ZW",
    "alpha_3_code": "ZWE",
    "en_short_name": "Zimbabwe",
    "label": "Zimbabwean"
  }
];


