import types from "../types";

const initialState = {
    fetching: false,
    details: {},
    flightBookingDetail: {},
    cancelBooking: false,
    upcoming:{},
    cancelled:{},
    completed:{},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.MANAGE_BOOKINGS:
            return { ...state, fetching: true };
            
              case types.CUSTOM_MANAGE_BOOKINGS:
            return { ...state ,fetching:true};
            
        case types.MANAGE_BOOKINGS_SUCCESS:
            return { ...state, fetching: false, details: action.payload.info };
            
            
        case types.MANAGE_BOOKINGS_FAILED:
            return { ...state, fetching: false };

        case types.GET_BOOKING_DETAIL:
            return { ...state, fetching: true };
        case types.GET_BOOKING_DETAIL_SUCCESS:
            return { ...state, fetching: false, data: action.payload.info || {} };
        case types.GET_BOOKING_DETAIL_FAILED:
            return { ...state, fetching: false };

        case types.UPDATE_ETICKET:
            return { ...state, data: action.payload }

        case types.CANCEL_BOOKING:
            return { ...state, cancelBooking: true };
        case types.CANCEL_BOOKING_SUCCESS:
            return { ...state, cancelBooking: false, };
        case types.CANCEL_BOOKING_FAILED:
            return { ...state, cancelBooking: false };

        default:
            return state;
    }
};
