import { apiPost, apiGet, getObject } from "../utils";
import { apiKey, TokenId } from "../constants";
import { isLoggedIn, getUser } from "../utils"

export function manageBookingsAPI(data) {
  let token = isLoggedIn();
  let start =(data.start) ? data.start : 0;
  let limit =(data.limit) ? data.limit :10;
  return apiGet(`/api/bookings?appKey=${apiKey}&token=${token}&start=${start}&limit=${limit}`);
}

// export function customManageBookingsAPI(param) {
//   let token = isLoggedIn();
//   return apiGet(`/api/bookings?appKey=${apiKey}&token=${token}&flight=${param}`);
// }


export function getBookingDetailAPI(data) {
  return apiPost(`/api/flightbookingdetails?appKey=${apiKey}`,
    {
      "TokenId": TokenId,
      "EndUserIp": getObject('ipaddress'),
      "token": getUser() && getUser().token ? getUser().token : "",
      ...data
    }
  )
}

export function cancelBookingAPI(data) {
  return apiPost(`/api/editbooking?appKey=${apiKey}`,
    {
      "TokenId": TokenId,
      "EndUserIp": getObject('ipaddress'),
      "token": getUser() && getUser().token ? getUser().token : "",
      ...data
    }
  )
}

export function getLatestPdfsAPI(data) {
  return apiPost(`/api/pdfregenrate?appKey=${apiKey}`, data)
}